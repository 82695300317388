import { MOBILE_WIDTH } from 'config';
import htmlClasses from 'html-classes';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { IUserWithRoles } from '../api/dto/GetUserByTeamDto';
import TextWithHighlight from '../components/TextWithHighlight';
import Checkbox from '../components/Checkbox';
import { dataStore } from 'stores/DataStore';

interface MemberCardProps {
  checked: boolean;
  onCheckedChange: (user: IUserWithRoles) => void;
  textToHighlight: string;
  user: IUserWithRoles;
}

export default observer(({ user, checked, onCheckedChange, textToHighlight }: MemberCardProps) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_WIDTH}px)` });

  textToHighlight = textToHighlight.replaceAll('+', '');

  const handleMemberToggle = () => onCheckedChange(user);

  const DesktopLayout = () => (
    <React.Fragment>
      <span className='member-card__name'>
        {user.name.toLowerCase().includes(textToHighlight.toLowerCase()) ?
         <TextWithHighlight text={user.name}
                            highlightPart={textToHighlight}
                            takeOnlyFirst
         /> : <>{user.name}</>}
      </span>
      <span className='member-card__phone'>
        {user.phone.toLowerCase().includes(textToHighlight.toLowerCase()) ?
         <TextWithHighlight text={user.phone}
                            highlightPart={textToHighlight}
                            takeOnlyFirst
         /> : <>{user.phone}</>}
      </span>
      <div className='member-card__roles'>
        {user.roles.map((role) => (
          <span>{dataStore.getRoleName(role)}</span>
        ))}
      </div>
      <Checkbox className='member-card__checkbox'
                checked={checked}
                onToggle={handleMemberToggle} />
    </React.Fragment>
  );

  const MobileLayout = () => (
    <React.Fragment>
      <div className='member-card__content'>
        <div className='member-card__header'>
        <span className='member-card__name'>
          {user.name.toLowerCase().includes(textToHighlight.toLowerCase()) ?
           <TextWithHighlight text={user.name}
                              highlightPart={textToHighlight}
                              takeOnlyFirst
           /> : <>{user.name}</>}
        </span>
        </div>
        <div className='member-card__footer'>
        <span className='member-card__phone'>
          {user.phone.toLowerCase().includes(textToHighlight.toLowerCase()) ?
           <TextWithHighlight text={user.phone}
                              highlightPart={textToHighlight}
                              takeOnlyFirst
           /> : <>{user.phone}</>}
        </span>
          <div className='member-card__roles'>
            {user.roles.map((role) => (
              <span key={role}>{dataStore.getRoleName(role)}</span>
            ))}
          </div>
        </div>
      </div>
      <Checkbox className='member-card__checkbox'
                checked={checked}
                onToggle={handleMemberToggle} />
    </React.Fragment>
  );

  return (
    <div className={htmlClasses('member-card', { _mobile: isMobile })}
         onClick={handleMemberToggle}>
      {isMobile ? <MobileLayout /> : <DesktopLayout />}
    </div>
  );
});

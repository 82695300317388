import React, { useState, useEffect } from 'react';
import htmlClasses from 'html-classes';
import { searchStore } from 'components/Search/store';
import { observer } from 'mobx-react-lite';
import { authStore } from 'stores/AuthStore';
import { Droppable } from 'react-beautiful-dnd';
import { CourierStatus } from '../api/types/Courier';
import Courier from './Courier';
import { mainStore } from 'stores/MainStore';
import { dataStore } from 'stores/DataStore';
import { DashboardTeamlessCourierDto } from '../api/dto/DashboardInfoDto';
import { Team } from '../api/types/Team';
import { useTranslation } from 'react-i18next';

type TeamProps = {
  team: Team;
  couriers: DashboardTeamlessCourierDto[];
};

const CouriersDuty = observer(({ couriers }: { couriers: DashboardTeamlessCourierDto[] }) => {
  const { t } = useTranslation();

  const isDropDisabled = (): boolean => {
    return (
      (mainStore.dragItem.group === 'team' || mainStore.dragItem.group === 'teamOffline'
      ) &&
      mainStore.dragItem.type === 'courier'
    );
  };

  return (
    <>
      {couriers.length > 0 ? (
        <Droppable droppableId='team' type='courier' isDropDisabled={isDropDisabled()}>
          {(provided) => (
            <div ref={provided.innerRef}>
              {couriers.map(({ courier, user }, index) => (
                <Courier
                  id={courier.id}
                  status={courier.status}
                  vehicleType={courier.vehicleType}
                  userName={user.user.name}
                  index={index}
                  key={courier.id}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      ) : (
         <Droppable droppableId='team' type='courier' isDropDisabled={isDropDisabled()}>
           {(provided, snapshot) => (
             <div
               className={htmlClasses('drop-here', {
                 _hover: snapshot.isDraggingOver,
               })}
               data-text={t('phrases:dropCourierHere')}
               ref={provided.innerRef}
             >
               {provided.placeholder}
             </div>
           )}
         </Droppable>
       )}
    </>
  );
});

const CouriersOffline = observer(({ couriers }: { couriers: DashboardTeamlessCourierDto[] }) => {
  const { t } = useTranslation();
  const [isExpandedOffline, setIsExpandedOffline] = useState(authStore.isExpandedOffline);
  const handleToggleExpanded = () => {
    setIsExpandedOffline(!isExpandedOffline);
    authStore.setIsExpandedOffline(!isExpandedOffline);
  };

  useEffect(() => {
    if (!searchStore.filteredOrderIds.length) return;

    let isExpanded = false;
    couriers.forEach(courier => {
      const jobs = dataStore.getCourierJobs(courier.courier.id);
      jobs.forEach(job => {
        const orderIds = job.orders.map(order => order.order.id);
        // whether filteredOrders contain some of courierOrders
        const contains = orderIds.some(id => searchStore.filteredOrderIds.includes(id));
        if (contains) isExpanded = true;
      });
    });

    setIsExpandedOffline(isExpanded);
    //eslint-disable-next-line
  }, [searchStore.filteredOrderIds.length]);

  return (
    <Droppable droppableId='teamOffline' type='courier' isDropDisabled={true}>
      {(provided) => (
        <div className='team-wrapper' ref={provided.innerRef}>
          <div
            className={htmlClasses('team-offline', { _expanded: isExpandedOffline })}
            onClick={handleToggleExpanded}
          >
            {t('common:offline')}
          </div>
          <div>
            {isExpandedOffline &&
             couriers.map(({ courier, user }, index) => (
               <Courier
                 id={courier.id}
                 status={courier.status}
                 vehicleType={courier.vehicleType}
                 userName={user.user.name}
                 index={index}
                 key={courier.id}
               />
             ))}
          </div>
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
});

export default observer(({ team, couriers }: TeamProps) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(team.id === authStore.activeTeamId);
  const couriersDuty: DashboardTeamlessCourierDto[] = [];
  const couriersOffline: DashboardTeamlessCourierDto[] = [];
  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
    if (!isExpanded) authStore.setActiveTeamId(team.id);
  };
  couriers.forEach((courier) => {
    courier.courier.status === CourierStatus.OFFLINE ? couriersOffline.push(courier) : couriersDuty.push(courier);
  });

  useEffect(() => {
    if (
      mainStore.selectedCourier.id &&
      team.id === authStore.activeTeamId &&
      dataStore.teamExistsLocally(authStore.activeTeamId)
    ) {
      setIsExpanded(true);
    }
    //eslint-disable-next-line
  }, [team.id, mainStore.selectedCourier, authStore.activeTeamId]);

  useEffect(() => {
    if (team.id === authStore.activeTeamId && dataStore.teamExistsLocally(authStore.activeTeamId)) {
      return;
    }
    mainStore.setActiveDynamicFrame(null);
    mainStore.setSelectedCourier(null);
    mainStore.setSelectedOrder(null);
    setIsExpanded(false);
    //eslint-disable-next-line
  }, [authStore.activeTeamId]);

  useEffect(() => {
    if (searchStore.filteredOrders.length) setIsExpanded(team.id === authStore.activeTeamId);
    //eslint-disable-next-line
  }, [searchStore.filteredOrders.length, team.id]);

  useEffect(() => {
    const job = dataStore.getJobListResponse?.find(j => j.job.id === mainStore.selectedOrder.jobId);
    if (job?.job.teamId === team.id) {
      setIsExpanded(true);
    }
    //eslint-disable-next-line
  }, [mainStore.selectedOrder]);

  return (
    <div className={htmlClasses('team', { _expanded: isExpanded })}>
      <div className='team-title' onClick={handleToggleExpand}>
        <div className={`team-title__icon icon icon-${isExpanded ? 'minus' : 'plus'}`} />
        <div className='team-title__name'>{team.name}</div>
        <div className='team-title__counts'>
          {t('phrases:ofOnDuty', {num1: couriersDuty.length, num2: couriers.length})}
        </div>
      </div>
      {isExpanded && (
        <>
          <CouriersDuty couriers={couriersDuty} />
          {couriersOffline.length > 0 && <CouriersOffline couriers={couriersOffline} />}
        </>
      )}
    </div>
  );
});

import MobileEmptyBlock from 'components/MobileEmptyBlock';
import SelectedMembers from 'components/SelectedMembers';
import { MOBILE_WIDTH } from 'config';
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useMediaQuery } from 'react-responsive';
import { dataStore } from 'stores/DataStore';
import { IUserWithRoles } from '../api/dto/GetUserByTeamDto';
import MemberCard from '../components/MemberCard';
import InputText from '../components/InputText';
import { mainStore } from 'stores/MainStore';
import Popup from '../components/Popup';
import { useTranslation } from 'react-i18next';

interface PopoverAddMemberProps {
  teamId: string;
  onComplete?: () => void;
}

export default observer(({ teamId, onComplete }: PopoverAddMemberProps) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_WIDTH}px)` });

  const [availableMembers, setAvailableMembers] = useState<IUserWithRoles[]>([]);
  const [selectedMembers, setSelectedMembers] = useState<IUserWithRoles[]>([]);
  const [searchStr, setSearchStr] = useState<string>('');
  const [textToHighlight, setTextToHighlight] = useState<string>('');

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchStr(e.currentTarget.value.trim().toLowerCase());
  const handlePopupDismiss = () => mainStore.setIsShowPopupAddMember(false);
  const handleResetClick = () => setSelectedMembers([]);
  const handleSubmitClick = async () => {
    addMembersToTeam().finally(() => {
      mainStore.setIsShowPopupAddMember(false);
      onComplete?.call(null);
    });
  };
  const handleFormSubmit = async (e: React.FormEvent<HTMLInputElement> | null) => {
    e?.preventDefault();
    await fetchAvailableMembers();
    setTextToHighlight(searchStr);
  };

  const isMemberSelected = (id: string) => selectedMembers.some((member) => member.id === id);
  const handleToggleMemberSelection = (member: IUserWithRoles) => {
    if (isMemberSelected(member.id)) {
      setSelectedMembers(selectedMembers.filter((m) => m.id !== member.id));
    } else {
      setSelectedMembers([...selectedMembers, member]);
    }
  };

  const addMembersToTeam = async () => {
    const addMembersRequests = selectedMembers.map((member) =>
      dataStore.addMemberToTeam(teamId, member.id),
    );
    await Promise.all(addMembersRequests);
  };

  const fetchAvailableMembers = async () => {
    const members = await dataStore.requestUsersBySearchString(searchStr);
    if (!members || !members.length) {
      mainStore.pushAlert('error', t('errorPhrases:notFound'));
      setAvailableMembers([]);
    } else {
      mainStore.clearAlerts();
      setAvailableMembers(members);
    }
  };

  useEffect(() => {
    if (mainStore.isShowPopupAddMember) {
      setSelectedMembers([]);
      setAvailableMembers([]);
      setSearchStr('');
    }
    //eslint-disable-next-line
  }, [mainStore.isShowPopupAddMember]);

  // force rerender component
  useEffect(() => void 0, [availableMembers]);

  return (
    <Popup
      className="popup-add-member"
      isShow={mainStore.isShowPopupAddMember}
      onBackdropDismiss={handlePopupDismiss}
      mobileFullScreen
      mobileHeaderProps={{ title: t('common:searchForMember') }}
    >
      <div className="popup-add-member__title">{t('common:searchForMember')}</div>
      <div className="popup-add-member__content">
        <InputText
          className="member-search"
          onSubmit={handleFormSubmit}
          iconButtonName="magnifier"
          placeholder="Enter name or phone"
          value={searchStr}
          onChange={handleChangeSearch}
        />
        {!!selectedMembers.length && (
          <SelectedMembers
            members={selectedMembers}
            onResetClick={handleResetClick}
            onToggleClick={handleToggleMemberSelection}
            type={isMobile ? 'wrap' : 'scroll'}
          />
        )}
        <div className="available-members">
          {availableMembers.map((member) => (
            <MemberCard
              key={member.id}
              user={member}
              textToHighlight={textToHighlight}
              checked={isMemberSelected(member.id)}
              onCheckedChange={handleToggleMemberSelection}
            />
          ))}
          <MobileEmptyBlock />
          <MobileEmptyBlock />
        </div>
      </div>
      <div className="popup-add-member__buttons">
        <button className="button _secondary _block" onClick={handlePopupDismiss}>
          {t('common:cancel')}
        </button>
        <button
          className="button _primary _block"
          onClick={handleSubmitClick}
          disabled={!selectedMembers.length}
        >
          {t('common:addMembers')}
        </button>
      </div>
    </Popup>
  );
});

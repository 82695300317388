import { JobState } from 'api/types/Job';
import { OrderStatus } from 'api/types/Order';
import { TaskState } from 'api/types/Task';
import { tasks } from 'proto/build/js/last-mile/dispatcher/public/admin/v2/jobs_get_list';

export const SEC = 1_000; // ms

export const SEC_IN_MIN = 60;
export const MIN_IN_HOUR = 60;
export const HOUR_IN_DAY = 24;
export const MS_IN_SEC = 1000;

export const DEFAULT_TIME_ZONE = 'Europe/London';

export const API_URL = process.env.REACT_APP_API_ROOT ? process.env.REACT_APP_API_ROOT : window.location.origin.replace(
  'frontend-delivery-', '').replace('.', '.api-dev.') + '/';
export const API_URL_2 = process.env.REACT_APP_API_ROOT_2 ? process.env.REACT_APP_API_ROOT_2 : window.location.origin.replace(
  'frontend-delivery', 'api2') + '/';

export const CMS_URL = process.env.REACT_APP_CMS_URL ? process.env.REACT_APP_CMS_URL : window.location.origin.replace('frontend-delivery', 'frontend-cms');
export const WMS_URL = process.env.REACT_APP_WMS_URL ? process.env.REACT_APP_WMS_URL : window.location.origin.replace('frontend-delivery', 'odoo');

export const ADMIN_API_PREFIX = 'dispatcher/admin/';
export const ADMIN_V1_API_PREFIX = 'dispatcher/v1/admin/';
export const WMS_CONNECTOR_API_PREFIX = 'wms-connector/v1/';

export const REQUEST_TIMEOUT = 10 * SEC; // ms
export const ALERT_DURATION = 10000 * SEC; // ms
export const REQUEST_SMS_TIMER = 180; // SEC
export const REQUEST_DATA_INTERVAL = 10 * SEC; // ms

export const JOB_STATE_FILTER_DEFAULT = [
  JobState.UNASSIGNED, JobState.ASSIGNED, JobState.IN_PROGRESS,
];
export const ORDER_BAD_STATUSES = [OrderStatus.CANCELED, OrderStatus.FAILED];
export const ORDER_COMPLETE_STATUSES = [
  OrderStatus.COMPLETED, OrderStatus.FAILED, OrderStatus.CANCELED,
];
export const TASK_COMPLETE_STATUSES = [
  tasks.State.COMPLETED, tasks.State.FAILED, tasks.State.CANCELED,
];
export const TASK_COMPLETE_STATUSES_V2 = [
  TaskState.COMPLETED, TaskState.FAILED, TaskState.CANCELED,
];

export const GOOGLE_API_KEY = 'AIzaSyD5ADZui6oADCpG7Qip3vd9V-hTZs-haFM';
export const MAP_CENTER = { lat: 51.49718216737194, lng: -0.1350984164644431 };
export const MAP_ZOOM = 14;

export const MOBILE_WIDTH = 420;
export const MOBILE_EMPTY_BLOCK_HEIGHT = 120;

export const MAX_REQUEST_PAGE_SIZE = 10000;

export const SENTRY_DSN = 'https://0f6e829b1cae411a8b17e46275dd0aa7@sentry.jiffy.hpdev.ru/17';

export const MENU_LINKS = {
  development: {
    ADMIN: 'https://admin.jiffy.hpdev.ru',
    WMS: 'https://odoo.jiffy.hpdev.ru',
    DELIVERY_ADMIN: '/',
  },
  staging: {
    ADMIN: 'https://admin.stage.jiffygrocery.co.uk',
    WMS: 'https://wms.stage.jiffygrocery.co.uk',
    DELIVERY_ADMIN: '/',
  },
  production: {
    ADMIN: 'https://admin.jiffygrocery.co.uk',
    WMS: 'https://wms.app.jiffygrocery.co.uk',
    DELIVERY_ADMIN: '/',
  },
};

export const SEARCH_URL_PARAM_NAME = 's';

export const MAP_AREA_COLORS = [
  '#179f72',
  '#fe3cf5',
  '#ff303b',
  '#7ede4d',
  '#18dab7',
  '#52a7ff',
  '#5342fd',
  '#f7b81d',
  '#fe6619',
  '#29e5ff',
  '#c99770',
];

export const MONTH_NAMES = {
  FULL: [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December',
  ],
  SHORT: [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
  ],
};

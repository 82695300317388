import { TaskWithRel } from 'api/response/GetJobListResponse';
import OrderInfoBar from 'components/Map/OrderInfoBar';
import { searchStore } from 'components/Search/store';
import htmlClasses from 'html-classes';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { dataStore } from 'stores/DataStore';
import { mainStore } from 'stores/MainStore';

export type TaskPinProps = {
  lat: number;
  lng: number;
  jobId: string;
  orderId: string;
  orderExternalId: string;
  task: TaskWithRel;
  showInfoBar?: boolean;
};

export const TaskPin = observer(({ orderExternalId, jobId, orderId, task, showInfoBar = true }: TaskPinProps) => {
  //eslint-disable-next-line
  const isActive = useMemo(() => mainStore.selectedOrder.taskId === task.task.id, [mainStore.selectedOrder.taskId]);
  const handleTaskIconClick = () => {
    if (dataStore.orderExistsLocally(orderId)) {
      searchStore.resetSearch();
      mainStore.setSelectedOrder(
        { externalId: orderExternalId, id: orderId, jobId, taskId: task.task.id, source: 'map' });
    }
    mainStore.setSelectedPointCoords({ lat: task.address.latitude, lng: task.address.longitude });
    mainStore.setActiveDynamicFrame('detail');
    mainStore.setIsMapDragged(false);
  };

  return (
    <div className={htmlClasses('map-pin _task', { _active: isActive })}>
      <img
        src={dataStore.getTaskStatusIconV2(task.task.state)}
        onClick={handleTaskIconClick}
        alt=''
      />
      {showInfoBar ? <OrderInfoBar externalId={orderExternalId} orderId={orderId} jobId={jobId} task={task} /> : null}
    </div>
  );
});

import htmlClasses from 'html-classes';
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import PopupImageView from '../components/PopupImageView';
import PopupSettings from '../components/PopupSettings';
import FrameTeams from '../components/FrameTeams';
import PopupForceComplete from '../components/PopupForceComplete';
import { REQUEST_DATA_INTERVAL } from '../config';
import { dataStore } from '../stores/DataStore';
import FrameMap from '../components/FrameMap';
import { mainStore } from '../stores/MainStore';
import PopupForceLogoff from '../components/PopupForceLogoff';
import { authStore } from '../stores/AuthStore';

export default observer(() => {
  useEffect(() => {
    const interval = setInterval(() => {
      if (mainStore.isDrag) return;
      dataStore.requestDashboardInfo().catch((error) => error && console.error(error));
      dataStore.requestJobList().catch((error) => error && console.error(error));
      process.env.REACT_APP_SLOTS &&
        dataStore.requestSlotJobList().catch((error) => error && console.error(error));
      dataStore.getWarehouseList().catch((error) => error && console.error(error));
    }, REQUEST_DATA_INTERVAL);
    return () => clearInterval(interval);
    //eslint-disable-next-line
  }, []);

  const handleFrameTeamsToggle = () => {
    authStore.setIsFrameTeamsHidden(!authStore.isFrameTeamsHidden);
  };

  return (
    <>
      <div className="content-layout">
        <FrameMap />
        <FrameTeams />
        <div
          className={htmlClasses('icon icon-arrow-left frame-teams-button', {
            _hidden: authStore.isFrameTeamsHidden,
          })}
          onClick={handleFrameTeamsToggle}
        />
      </div>
      <PopupImageView />
      <PopupForceComplete />
      <PopupForceLogoff />
      <PopupSettings />
    </>
  );
});

import { Parcel, ParcelContentKind, ParcelStatus } from 'api/types/Parcel';
import { PizzaIcon } from 'icons';
import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';
import ExpandableItem from '../ExpandableItem';
import { useTranslation } from 'react-i18next';

interface ParcelsProps {
  parcels: Parcel[];
}

interface ParcelIconsProps {
  parcel: Parcel;
}

const ParcelIcons = observer(({ parcel }: ParcelIconsProps) => {
  if (
    !(
      parcel.ageRestriction > 0 ||
      [
        ParcelContentKind.FROZEN,
        ParcelContentKind.OWN_KITCHEN,
        ParcelContentKind.PHARMA,
        ParcelContentKind.CHEMICAL,
      ].includes(ParcelContentKind[parcel.contentKind])
    )
  ) {
    return <></>;
  }

  return (
    <div className="parcel-icons">
      {parcel.ageRestriction > 0 && <div className="icon icon-passport" title="age_restriction" />}
      {ParcelContentKind[parcel.contentKind] === ParcelContentKind.FROZEN && (
        <div className="icon icon-snowflake" title="frozen" />
      )}
      {ParcelContentKind[parcel.contentKind] === ParcelContentKind.OWN_KITCHEN && (
        <div className="icon icon-pizza" title="own_kitchen">
          <PizzaIcon />
        </div>
      )}
      {[ParcelContentKind.PHARMA, ParcelContentKind.CHEMICAL].includes(
        ParcelContentKind[parcel.contentKind],
      ) && (
        <div
          className="icon icon-glass"
          title={ParcelContentKind[parcel.contentKind]?.toLowerCase()}
        />
      )}
    </div>
  );
});

export default observer(({ parcels }: ParcelsProps) => {
  const { t } = useTranslation();
  const titleRef = useRef<HTMLDivElement>(null);

  const numOfParcelsLabel = parcels.length > 1 ? `parcels` : 'parcel';
  return (
    <ExpandableItem expanded className="_parcels" titleRef={titleRef}>
      <div className="item__title title _centered _with-icons" ref={titleRef}>
        <span className="title__main">
          {parcels.length} {numOfParcelsLabel}
        </span>
        <div className="title__icons">
          {parcels.map((parcel) => (
            <ParcelIcons key={parcel.id} parcel={parcel} />
          ))}
        </div>
        <div className="title__expand-icon icon icon-chevron-up" />
      </div>
      <div className="item__content content">
        {parcels.map((parcel, index) => (
          <div className="parcel-bag" key={parcel.id}>
            {parcel.status !== ParcelStatus.ABSTRACT && (
              <div
                className={`icon icon-${ParcelStatus[parcel.status]?.toLowerCase()}`}
                title={ParcelStatus[parcel.status]?.toLowerCase()}
              />
            )}
            <div className="parcel-bag__title">
              {t('common:parcel')}&nbsp;{index + 1}
            </div>
            <div className="parcel-bag__text" title={parcel.barcode}>
              {parcel.barcode}
            </div>
            <ParcelIcons parcel={parcel} />
          </div>
        ))}
      </div>
    </ExpandableItem>
  );
});

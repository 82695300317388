import htmlClasses from 'html-classes';
import { observer } from 'mobx-react-lite';
import React from 'react';

interface TextWithHighlightProps {
  text: string;
  highlightPart: string;
  className?: string;
  takeOnlyFirst?: boolean;
  highlightColor?: 'green' | 'default';
}

export default observer(
  ({ text, highlightPart, className, takeOnlyFirst, highlightColor }: TextWithHighlightProps) => {
    let isFirst = true;
    const parts = text.split(new RegExp(`(${highlightPart})`, 'gi')).map(part => {
      let isActive = part.toLowerCase() === highlightPart.toLowerCase();

      if (takeOnlyFirst && isFirst) {
        if (isActive) isFirst = false;
      } else if (takeOnlyFirst) {
        isActive = false;
      }

      return {
        value: part,
        isActive,
      };
    });

    return (
      <span className={htmlClasses('text-with-highlight', className)}>
    {parts.map((part, index) =>
      <span key={index}
            className={htmlClasses(
              'text-with-highlight__part',
              { _active: part.isActive, _green: highlightColor === 'green' },
            )}>
            {part.value}
      </span>)}
    </span>
    );
  });

import { TaskKind, TaskState } from 'api/types/Task';
import EditCourierForm from 'components/Forms/EditCourierForm';
import htmlClasses from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { dataStore } from 'stores/DataStore';
import { mainStore } from 'stores/MainStore';
import { DashboardTeamlessCourierDto } from '../api/dto/DashboardInfoDto';
import { CourierStatus, VehicleType } from '../api/types/Courier';
import MobileEmptyBlock from './MobileEmptyBlock';
import { useTranslation } from 'react-i18next';

export default observer(() => {
  const { t } = useTranslation();
  const [courier, setCourier] = useState<DashboardTeamlessCourierDto | null>(null);
  const [statusName, setStatusName] = useState('');
  const [vehicle, setVehicle] = useState('');
  const [courierName, setCourierName] = useState('');
  const [courierPhone, setCourierPhone] = useState('');
  const [courierTeam, setCourierTeam] = useState('');
  const [isEditFormExpanded, setIsEditFormExpanded] = useState<boolean>(false);
  const [isDisabledForceLogoff, setIsDisabledForceLogoff] = useState(true);
  const handleHideFrame = () => mainStore.setActiveDynamicFrame(null);
  const handleCopy = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (error) {
      error && console.error(error);
      mainStore.pushAlert('error', t('errorPhrases:failedCopyText'));
    }
  };
  const checkInProgressTasks = (): boolean => {
    const courierJobs = dataStore.getCourierJobs(mainStore.selectedCourier.id);
    if (!courierJobs.length) return false;
    return courierJobs.some(
      ({ orders }) => orders.some(
        ({ tasks }) => tasks.some(({ task }) =>
          task.kind === TaskKind.DROP_OFF && [TaskState.ON_THE_WAY, TaskState.ON_POINT].includes(
            task.state))));
  };

  const handleToggleEditForm = () => setIsEditFormExpanded(prev => !prev);
  const handleForceLogoff = () => mainStore.setIsShowPopupLogoff(true);

  useEffect(() => {
    if (!mainStore.selectedCourier.id) return;
    const courier = dataStore.getCourierById(mainStore.selectedCourier.id);
    if (!courier) {
      setCourier(null);
      mainStore.setActiveDynamicFrame(null);
      return;
    }
    setCourier(courier);
    setCourierName(courier.user.user.name);
    setCourierPhone(courier.user.user.phone);
    setCourierTeam((courier.user.teams.map(({ team }) => team.name)
    ).join('<br>'));
    setStatusName(CourierStatus[courier.courier.status]?.toLowerCase() || '');
    setVehicle(VehicleType[courier.courier.vehicleType]?.toLowerCase() || '');
    if (courier.courier.status === CourierStatus.OFFLINE) {
      setIsDisabledForceLogoff(true);
    } else {
      setIsDisabledForceLogoff(checkInProgressTasks());
    }
    //eslint-disable-next-line
  }, [mainStore.selectedCourier.id, dataStore.activeTeam]);

  if (!courier) return <></>;

  return (
    <div className="frame-dynamic-courier">
      <div className="frame-dynamic-header">
        <div className="frame-dynamic-title">
          <div className="frame-dynamic-title__name">{courierName}</div>
          <div className={htmlClasses(
            'frame-dynamic-title__edit-btn icon icon-pen', { _active: isEditFormExpanded })}
               onClick={handleToggleEditForm} />
          <div className="frame-dynamic-title__close icon icon-close" onClick={handleHideFrame} />
        </div>
        <div className="frame-dynamic-title__status">
          <div className={`icon icon-${vehicle} courier-icon _${statusName}`}>
          </div>
          <div className="frame-detail-subtitle__status">
            {statusName && t(`courierStatus:${statusName}`)}
            {/* {statusName && t(`orderStatus:${statusName}`)} */}
          </div>
        </div>
      </div>
      {courier.courier.status !== CourierStatus.OFFLINE && (
        <div className="frame-dynamic-footer">
          <div className="frame-dynamic-footer__buttons">
            <button
              className="button _primary"
              onClick={handleForceLogoff}
              disabled={isDisabledForceLogoff}
            >
              {t('phrases:forceLogOff')}
            </button>
          </div>
        </div>
      )}
      <div className="frame-dynamic-body">
        <div className="frame-detail__body">
          <div className="frame-detail__customer">{t('common:phoneNumber')}</div>
          <div
            className="frame-detail__phone"
            onClick={() => handleCopy(courierPhone).catch(() => void 0)}
          >
            {mainStore.prettyPhoneNumber(courierPhone)}
            <div className="icon icon-copy" />
          </div>
        </div>
        <div className="frame-detail__body">
          <div className="frame-detail__customer">{t('common:team')}</div>
          <div className="frame-detail__text" dangerouslySetInnerHTML={{ __html: courierTeam || 'Teamless' }} />
        </div>
        <div className="frame-detail__body">
          <div className="frame-detail__customer">{t('common:averageRating')}</div>
          <div className="frame-detail__rating">
            <div className="icon icon-star _fill" />
            <div className="icon icon-star _fill" />
            <div className="icon icon-star _fill" />
            <div className="icon icon-star _fill" />
            <div className="icon icon-star _fill" />
            <div className="frame-detail__rating-rate">
              5/<span>5</span>
            </div>
          </div>
        </div>
        {isEditFormExpanded && (
          <EditCourierForm
            currentCapacity={courier.courier.capacityPackages || 0}
            currentVehicleType={courier.courier.vehicleType}
            currentAgency={courier.courier.agency}
            courierId={courier.courier.id}
          />
        )}
        <MobileEmptyBlock />
      </div>
    </div>
  );
});

import { Order, OrderStatus } from 'api/types/Order';
import { searchStore } from 'components/Search/store';
import htmlClasses from 'html-classes';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { mainStore } from 'stores/MainStore';
import { TaskWithRel } from '../api/response/GetJobListResponse';
import { TaskKind /*TaskState*/ } from '../api/types/Task';
import OrderBase from '../components/OrderBase';
import useOrderProgress from '../hooks/useOrderProgress.hook';

type OrderProps = {
  jobId: string;
  order: Order;
  isExpanded: boolean;
  tasks: TaskWithRel[];
  handleToggleExpanding: (flag: boolean) => void;
  isAlone: boolean;
  isAssigned?: boolean;
  index: number;
  isSlot?: boolean;
};

export default observer(
  ({
    jobId,
    order,
    tasks,
    handleToggleExpanding,
    isAssigned,
    isExpanded,
    isAlone,
    index,
    isSlot,
  }: OrderProps) => {
    const orderRef = useRef<HTMLDivElement | null>(null);
    const orderTasks = tasks
      .slice()
      .sort(({ task: a }, { task: b }) => mainStore.sortAlphabet(a.kind, b.kind));
    const progress = useOrderProgress(order);
    // const isOrderComplete = [TaskState.COMPLETED, TaskState.FAILED, TaskState.CANCELED].includes(orderTasks[1]?.task.state);

    const handleShowDetail = () => {
      const task = orderTasks.find((task) =>
        [TaskKind.DROP_OFF, TaskKind.RETURN].includes(task.task.kind),
      );
      if (task) {
        searchStore.resetSearch();
        mainStore.setSelectedOrder({
          id: order.id,
          externalId: order.externalId,
          jobId,
          taskId: task.task.id,
          source: 'sidebar',
        });
        mainStore.setActiveDynamicFrame('detail');
        mainStore.setSelectedPointCoords({
          lat: task.address.latitude,
          lng: task.address.longitude,
        });
        mainStore.setIsMapDragged(false);
      }
    };
    const handleBlinkOrder = async () => {
      if (!orderRef || !orderRef.current) return;
      if (!isExpanded) {
        handleToggleExpanding(true);
        await new Promise((resolve) => setTimeout(resolve, 200));
      }
      orderRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    };

    useEffect(() => {
      if (
        !mainStore.selectedOrder.id ||
        mainStore.selectedOrder.id !== order.id ||
        mainStore.selectedOrder.source === 'sidebar' ||
        !orderRef ||
        !orderRef.current
      ) {
        return;
      }
      handleBlinkOrder().catch(() => void 0);
      //eslint-disable-next-line
    }, [mainStore.selectedOrder.id]);

    const isDragDisabled = () => {
      if (isSlot) return isSlot;
      if (isAlone) return true;
      return order.status !== OrderStatus.NEW;
    };

    return (
      <Draggable
        draggableId={`${jobId}_${order.id}`}
        isDragDisabled={isDragDisabled()}
        index={index}
      >
        {(provided) => (
          <OrderBase
            isAssigned={isAssigned}
            isSlot={isSlot}
            progress={progress}
            className={htmlClasses('order', {
              // Show for completed orders too (don't check for isOrderComplete)
              _expired: !progress, // Check for eta because we show the expired state only for orders that are already assigned to a courier
              _active: mainStore.selectedOrder.id === order.id,
              _blink:
                mainStore.selectedOrder.id === order.id &&
                mainStore.selectedOrder.source !== 'sidebar',
            })}
            onClick={handleShowDetail}
            order={order}
            tasks={tasks}
            provided={provided}
          />
        )}
      </Draggable>
    );
  },
);

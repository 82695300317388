import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import PopupDeleteMember from '../../../components/PopupDeleteMember';
import PopupAddMember from '../../../components/PopupAddMember';
import { mainStore } from 'stores/MainStore';
import Header from '../Header';
import { settingsStore } from './store';
import UserCard from './UserCard';
import MobileEmptyBlock from 'components/MobileEmptyBlock';
import { useTranslation } from 'react-i18next';

export default observer(() => {
  if (!settingsStore.selectedTeam) return null;

  const { t } = useTranslation();
  const [userIdToDelete, setUserIdToDelete] = useState<string>('');

  const handleBackClick = () => settingsStore.setActiveFrame('home');
  const handlePopupAddMemberShow = () => mainStore.setIsShowPopupAddMember(true);
  const handleUserDelete = (userId: string) => setUserIdToDelete(userId);
  const handleTeamMembersChange = () => {
    if (settingsStore.selectedTeam) {
      return settingsStore.fetchTeamWithUsers(settingsStore.selectedTeam.team);
    }
  };

  const offlineUsersLength = settingsStore.calculateOfflineCouriers(settingsStore.selectedTeam.users);
  const allUsersLength = settingsStore.selectedTeam.users.length;

  return (
    <div>
      <Header title={settingsStore.selectedTeam.team.name} onBackClick={handleBackClick} />
      <div className='settings-frame__body body'>
        <div className='body__item item'>
          <div className='item__top top'>
            <span
              className='top__label'>{allUsersLength - offlineUsersLength}/{allUsersLength} {t('phrases:onDuty')}</span>
            <div className='top__button button'>
              <div className='button__icon icon icon-plus' />
              <div className='button__text' onClick={handlePopupAddMemberShow}>{t('phrases:addNewMember')}</div>
            </div>
          </div>
          <div className='item__user-cards cards user-cards'>
            {settingsStore.selectedTeam.users.map(user => (
              <UserCard key={user.id} user={user} onUserDeleteClick={handleUserDelete} />
            ))}
            <MobileEmptyBlock/>
          </div>
        </div>
      </div>
      <PopupAddMember teamId={settingsStore.selectedTeam.team.id}
                      onComplete={handleTeamMembersChange} />
      <PopupDeleteMember userId={userIdToDelete} teamId={settingsStore.selectedTeam.team.id}
                         onComplete={handleTeamMembersChange} />
    </div>
  );
});

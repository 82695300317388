import { makeAutoObservable } from 'mobx';
import { IUserWithRoles } from '../../../api/dto/GetUserByTeamDto';
import { dataStore } from '../../../stores/DataStore';
import { teams } from '../../../proto/build/js/last-mile/dispatcher/teams';
import { WarehouseWithTeamsRel } from '../../../proto/build/js/last-mile/dispatcher/public/admin/warehouse_with_teams';

type WarehousesFrameName = 'home' | 'team' | null;
export type TeamWithUsers = {
  team: teams.Team;
  users: IUserWithRoles[];
}

class SettingsStore {
  activeFrame: WarehousesFrameName = 'home';
  selectedTeam: TeamWithUsers | null = null;
  selectedWarehouse: WarehouseWithTeamsRel | null = null;

  selectedWarehouseTeams: TeamWithUsers[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  // Setters
  setActiveFrame(val: WarehousesFrameName) {
    this.activeFrame = val;
  }

  setSelectedWarehouse(val: WarehouseWithTeamsRel | null) {
    this.selectedWarehouse = val;
  }

  setSelectedTeam(val: TeamWithUsers | null) {
    this.selectedTeam = val;
  }

  // Actions
  async fetchWarehouseWithTeams(warehouseId: string) {
    const warehouseWithTeams = await dataStore.requestWarehouseWithTeams(warehouseId);
    if (warehouseWithTeams) {
      settingsStore.setSelectedWarehouse(warehouseWithTeams);

      const teamsWithUsers: TeamWithUsers[] = [];
      for (const team of warehouseWithTeams.teams) {
        const users = await dataStore.requestTeamUsers(team.id) || [];
        teamsWithUsers.push({ team, users });
      }
      this.selectedWarehouseTeams = teamsWithUsers;
    } else {
      this.setSelectedWarehouse(null);
    }
  }

  async fetchTeamWithUsers(team: teams.Team) {
    const users = await dataStore.requestTeamUsers(team.id) || [];
    settingsStore.setSelectedTeam({ team, users });
    settingsStore.setActiveFrame('team');
  }

  calculateOfflineCouriers(users: IUserWithRoles[]): number {
    // TODO: replace string with enum
    return users.filter(user => user.status === 'OFFLINE').length;
  }
}

export const settingsStore = new SettingsStore();

import React from 'react';
import htmlClasses from 'html-classes';
import { mainStore } from 'stores/MainStore';
import { observer } from 'mobx-react-lite';
import Home from './Home';
import Team from './Team';
import { settingsStore } from './store';

export default observer(() => {
  return (
    <div
      className={htmlClasses('settings-frame', 'warehouses', {
        _active: mainStore.activeSettingsFrame === 'warehouses',
      })}
    >
      { settingsStore.activeFrame === 'home' && <Home/>}
      { settingsStore.activeFrame === 'team' && <Team/>}
    </div>
  );
});

import htmlClasses from 'html-classes';
import { observer } from 'mobx-react-lite';
import React, { ReactNode, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import { CSSTransition } from 'react-transition-group';
import { MOBILE_WIDTH } from 'config';
import { ArrowLeftIcon, CrossIcon } from 'icons';

export type PopupMobileHeaderProps = {
  title: string;
  onBackClick?: () => void;
  backButtonHidden?: boolean;
  closeButtonVisible?: boolean;
}

type PopupProps = {
  isShow: boolean;
  children: ReactNode;
  onBackdropDismiss: () => void;
  className?: string;
  noCloseIcon?: boolean;
  mobileCentered?: boolean;
  mobileFullScreen?: boolean;
  mobileHeaderProps?: PopupMobileHeaderProps;
};

export default observer(
  ({
    isShow,
    children,
    onBackdropDismiss,
    className,
    noCloseIcon,
    mobileFullScreen,
    mobileHeaderProps,
    mobileCentered,
  }: PopupProps) => {
    const refPopup = useRef<HTMLDivElement>(null);
    const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_WIDTH}px)` });

    return (
      <CSSTransition
        in={isShow}
        timeout={{ enter: 350, exit: 250 }}
        mountOnEnter
        unmountOnExit
        classNames='popup'
        nodeRef={refPopup}
      >
        <div className={htmlClasses('popup', className, {
          '_mobile-full-screen': isMobile && mobileFullScreen,
          '_mobile-centered': mobileCentered,
        })} ref={refPopup}>
          <div className='popup__bg' onClick={onBackdropDismiss} />
          <div className='popup__body'>
            {((!noCloseIcon && !isMobile) || (isMobile && mobileCentered)) && (
              <div className='popup__close icon icon-close' onClick={onBackdropDismiss} />
            )}
            {isMobile && mobileFullScreen && mobileHeaderProps && (
              <div className='popup__header'>
                {!mobileHeaderProps.backButtonHidden &&
                 <div className='popup__back-btn' onClick={mobileHeaderProps.onBackClick || onBackdropDismiss}>
                  <ArrowLeftIcon />
                </div>}
                {mobileHeaderProps.title && <div className='popup__title'>{mobileHeaderProps.title}</div>}
                {mobileHeaderProps.closeButtonVisible &&
                 <div className='popup__close-btn' onClick={onBackdropDismiss}>
                   <CrossIcon/>
                 </div>}
              </div>
            )}
            {children}
          </div>
        </div>
      </CSSTransition>
    );
  },
);

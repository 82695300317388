import * as Sentry from '@sentry/react';
import { capitalizeFirstLetter } from 'common/capitalize-word';
import GoogleMapReact from 'google-map-react';
import { makeAutoObservable } from 'mobx';
import { searchStore } from 'components/Search/store';
import { authStore } from './AuthStore';
import { AxiosError } from 'axios';
import { v4 as uuid_v4 } from 'uuid';
import { JiffyMessageType, JiffyMessageBodyType } from 'api/Requests';
import { JiffyMessage, JiffyMessageBody } from 'proto/build/js/message/message';

type AlertTypes = 'error' | 'warning' | 'success';

type Alert = {
  type: AlertTypes;
  message: string;
  id: string;
  onDismiss?: () => void;
  className?: string;
};

export type DynamicFrameName = 'teamless' | 'unassigned' | 'detail' | 'courier' | null;
export type SettingsFrameName = 'users' | 'warehouses' | 'managers' | 'teams' | null;
export type MobileScreenName = 'sidebar' | 'map' | 'teams' | 'teamless' | 'unassigned';

export type Direction = 'ltr' | 'rtl';

type SelectedCourier = { id: string; source: 'map' | 'sidebar' | 'search' | '' };

type SelectedOrder = SelectedCourier & { jobId: string; taskId: string; externalId: string };

type DragItem = { group: string; type: string };

type Environment = 'production' | 'staging' | 'development';

class MainStore {
  alerts: Alert[] = [];
  activeDynamicFrame: DynamicFrameName = null;
  activeSettingsFrame: SettingsFrameName = null;
  activeMobileScreen: MobileScreenName = 'map';
  isMapDragged = false;
  isShowPopoverDropdown = false;
  isShowPopoverAddCourier = false;
  isShowPopoverAddTeam = false;
  isShowPopoverFilter = false;
  isShowPopoverMenu = false;
  isShowPopupLogoff = false;
  isShowPopupComplete = false;
  isShowPopupAddMember = false;
  isShowPopupAddCourier = false;
  isShowPopupDeleteMember = false;
  isShowPopupEditCourier = false;
  isShowPopupSettings = false;
  isShowPopupFilter = false;
  isShowPopupAddTeam = false;
  isMobileSidebarOpened = false;
  isShowPopupImageView = false;
  isShowPopupAdvancedSearch = false;
  isDrag = false;
  draggableId = '';
  dragItem: DragItem = { group: '', type: '' };
  selectedCourier: SelectedCourier = { id: '', source: '' };
  selectedOrder: SelectedOrder = { id: '', jobId: '', taskId: '', source: '', externalId: '' };
  openedTaskCluster: number | null = null;
  openedCourierCluster: number | null = null;
  environment: Environment = (process.env.REACT_APP_ENV as Environment) || 'development';
  selectedImageUrl: string | null = null;
  selectedPointCoords: GoogleMapReact.Coords | null = null;
  lang = 'en';
  dir: Direction = 'ltr';

  constructor() {
    makeAutoObservable(this);
  }

  // Getters
  get isAllStoresSynchronized(): boolean {
    return authStore.isSynchronized && searchStore.isSynchronized;
  }

  // Setters

  setSelectedPointCoords(selectedPointCoords: GoogleMapReact.Coords | null): void {
    this.selectedPointCoords = selectedPointCoords;
  }

  pushAlert(type: AlertTypes, message: string, onDismiss?: () => void, className?: string) {
    if (this.alerts.length && this.alerts.slice(-1)[0].message === message) this.alerts.pop();
    this.alerts.push({
      type,
      message,
      onDismiss,
      className,
      id: uuid_v4(),
    });
  }

  popAlert(): Alert | null {
    return this.alerts.pop() || null;
  }

  setLang(lang: string) {
    this.lang = lang;
  }

  setDir(dir: Direction) {
    this.dir = dir;
  }

  setIsMapDragged(isDragged: boolean) {
    this.isMapDragged = isDragged;
  }

  setIsMobileSidebarOpened(flag: boolean) {
    this.isMobileSidebarOpened = flag;
  }

  setIsShowPopoverDropdown(flag: boolean) {
    if (flag) this.hideAllPopovers();
    this.isShowPopoverDropdown = flag;
  }

  setIsShowPopoverAddTeam(flag: boolean) {
    if (flag) this.hideAllPopovers();
    this.isShowPopoverAddTeam = flag;
  }

  clearAlerts(): void {
    this.setAlerts([]);
  }

  setAlerts(val: Alert[]) {
    this.alerts = val;
  }

  setIsShowPopoverAddCourier(flag: boolean) {
    if (flag) this.hideAllPopovers();
    this.isShowPopoverAddCourier = flag;
  }

  setIsShownPopoverMenu(flag: boolean) {
    if (flag) this.hideAllPopovers();
    this.isShowPopoverMenu = flag;
  }

  setIsShowPopupFilter(flag: boolean) {
    this.isShowPopupFilter = flag;
  }

  setIsShowPopupAddTeam(flag: boolean) {
    this.isShowPopupAddTeam = flag;
  }

  setIsShowPopoverFilter(flag: boolean) {
    if (flag) this.hideAllPopovers();
    this.isShowPopoverFilter = flag;
  }

  setIsShowPopupLogoff(flag: boolean) {
    if (flag && !this.selectedCourier.id) {
      this.isShowPopupLogoff = false;
      return;
    }
    this.isShowPopupLogoff = flag;
  }

  setIsShowPopupComplete(flag: boolean) {
    if (flag && !this.selectedOrder.id) {
      this.isShowPopupComplete = false;
      return;
    }
    this.isShowPopupComplete = flag;
  }

  setIsShowPopupAddCourier(flag: boolean) {
    this.isShowPopupAddCourier = flag;
  }

  setIsShowPopupSettings(flag: boolean) {
    if (!flag) this.setIsShowPopupAddMember(false);
    this.isShowPopupSettings = flag;
  }

  setIsShowPopupAdvancedSearch(flag: boolean) {
    this.isShowPopupAdvancedSearch = flag;
  }

  setIsShowPopupAddMember(flag: boolean) {
    this.isShowPopupAddMember = flag;
  }

  setIsShowPopupDeleteMember(flag: boolean) {
    this.isShowPopupDeleteMember = flag;
  }

  setIsShowPopupEditCourier(flag: boolean) {
    this.isShowPopupEditCourier = flag;
  }

  setIsShowPopupImageView(flag: boolean) {
    this.isShowPopupImageView = flag;
  }

  setSelectedImageUrl(url: string | null) {
    this.selectedImageUrl = url;
  }

  setActiveDynamicFrame(val: DynamicFrameName) {
    if (val === 'detail' && !this.selectedOrder.id) {
      this.activeDynamicFrame = null;
      return;
    }
    if (val === 'courier' && !this.selectedCourier.id) {
      this.activeDynamicFrame = null;
      return;
    }
    this.activeDynamicFrame = val;
  }

  setActiveSettingsFrame(val: SettingsFrameName) {
    this.activeSettingsFrame = val;
  }

  setActiveMobileScreen(val: MobileScreenName) {
    switch (val) {
      case 'sidebar':
        this.setIsMobileSidebarOpened(true);
        authStore.setIsFrameTeamsHidden(true);
        this.setActiveDynamicFrame(null);
        break;
      case 'map': {
        this.setIsMobileSidebarOpened(false);
        authStore.setIsFrameTeamsHidden(true);
        this.setActiveDynamicFrame(null);
        break;
      }
      case 'teams': {
        this.setIsMobileSidebarOpened(false);
        authStore.setIsFrameTeamsHidden(false);
        this.setActiveDynamicFrame(null);
        break;
      }
      case 'teamless': {
        this.setIsMobileSidebarOpened(false);
        authStore.setIsFrameTeamsHidden(true);
        this.setActiveDynamicFrame('teamless');
        break;
      }
      case 'unassigned':
        this.setIsMobileSidebarOpened(false);
        authStore.setIsFrameTeamsHidden(true);
        this.setActiveDynamicFrame('unassigned');
        break;
    }

    this.setIsShowPopupSettings(false);
    this.setIsShowPopupAddTeam(false);
    this.setIsShowPopupFilter(false);
    this.setIsShowPopupAddCourier(false);
    this.setIsShowPopupEditCourier(false);
    this.activeMobileScreen = val;
  }

  setIsDrag(flag: boolean) {
    this.isDrag = flag;
  }

  setDragItem(val: DragItem | null) {
    if (!val) {
      this.dragItem = { group: '', type: '' };
      return;
    }
    this.dragItem = val;
  }

  setDraggableId(id: string) {
    this.draggableId = id;
  }

  setSelectedCourier(val: SelectedCourier | null) {
    if (!val) {
      this.selectedCourier = { id: '', source: '' };
      return;
    }
    this.setSelectedOrder(null);
    this.selectedCourier = val;
  }

  setSelectedOrder(val: SelectedOrder | null) {
    if (!val) {
      this.selectedOrder = { id: '', jobId: '', taskId: '', source: '', externalId: '' };
      return;
    }
    this.setSelectedCourier(null);
    this.selectedOrder = val;
  }

  setOpenedTaskCluster(val: number | null) {
    this.openedTaskCluster = val;
  }

  setOpenedCourierCluster(val: number | null) {
    this.openedCourierCluster = val;
  }

  // Actions
  setSentryUser({ id, name, phone }: { id?: string; name?: string; phone?: string }) {
    const userData: Record<string, string> = {
      ip_address: '{{auto}}',
    };
    if (id) userData.id = id;
    if (name) userData.name = name;
    if (phone) {
      userData.phone = phone;
    } else if (authStore.personalData.phone) userData.phone = authStore.personalData.phone;
    Sentry.setUser(userData);
  }

  prettyPhoneNumber(phone: string): string {
    if (!phone.length) return '';
    if (phone.slice(0, 2) === '44') {
      return phone
        .replace(/\D/g, '')
        .slice(-10)
        .replace(/(\d{4})(\d{3})(\d{3})/, '+44 ($1) $2-$3');
    }
    return (
      '+' +
      phone.replace(/\D/g, '').replace(/(\d{1,2})(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 ($2) $3-$4-$5')
    );
  }

  hideAllPopovers() {
    this.isShowPopoverDropdown = false;
    this.isShowPopoverAddCourier = false;
    this.isShowPopoverAddTeam = false;
    this.isShowPopoverFilter = false;
    this.isShowPopoverMenu = false;
  }

  closePopupSettings() {
    this.setActiveSettingsFrame(null);
    this.setIsShowPopupSettings(false);
  }

  sortNumbers(a: number, b: number, sorting: 'asc' | 'desc' = 'asc'): number {
    const result = a < b ? -1 : a > b ? 1 : 0;
    if (!result || sorting === 'asc') return result;
    return result * -1;
  }

  sortAlphabet(a: string, b: string): number {
    if (!a || !b) return 0;
    return a.localeCompare(b);
  }

  getJiffyMessageType<T extends JiffyMessageBody.Type>(
    data: Uint8Array,
    type: T,
  ): JiffyMessageType<T> {
    return JiffyMessage.create({
      id: uuid_v4(),
      body: JiffyMessageBody.create({ type, data }) as unknown as JiffyMessageBodyType<T>,
    }) as unknown as JiffyMessageType<T>;
  }

  // Errors
  errorHandler = ({ response }: AxiosError<any>, context: string, customMessage?: string) => {
    let errorMessage =
      customMessage || response?.data?.message || response?.data?.error?.payload?.message || '';
    errorMessage = capitalizeFirstLetter(errorMessage);
    if (response?.data === '404 page not found' && !errorMessage && response?.config.url) {
      errorMessage = `Route ${response?.config.url} not found` || 'Unknown error';
    }

    if ((response?.config.url as string)?.includes('auth/v1/auth/sign-in/email')) {
      errorMessage = 'Authentication failed, please retry';
    }
    if (response?.status === 500) {
      errorMessage = 'Server error';
    }
    if (response?.status === 503) {
      errorMessage = 'Server unavailable';
    }
    if (!errorMessage) errorMessage = 'Unknown error';
    if (response?.status === 403) {
      if (['requestDashboardInfo', 'requestJobList'].includes(context)) {
        authStore.resetStore();
      }
    }
    if (errorMessage) this.pushAlert('error', errorMessage);
  };
}

export const mainStore = new MainStore();

import htmlClasses from 'html-classes';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { CourierLabel } from './CourierLabel';
import { CourierPoint } from '../../stores/DataStore';
import { mainStore } from '../../stores/MainStore';

type CourierClusterPinProps = {
  clusterId: number;
  couriers: CourierPoint[];
};

export const CourierClusterPin = observer(({ clusterId, couriers }: CourierClusterPinProps) => {
  const [isActive, setIsActive] = useState<boolean>(
    mainStore.openedCourierCluster === clusterId,
  );

  const handlePinClick = () => mainStore.setOpenedCourierCluster(clusterId);

  useEffect(() => {
    const courierIds = couriers.map((courier) => courier.properties.courierId);
    setIsActive(courierIds.includes(mainStore.selectedCourier.id));
    //eslint-disable-next-line
  }, [mainStore.selectedCourier.id]);

  useEffect(() => {
    setIsActive(mainStore.openedCourierCluster === clusterId);
    //eslint-disable-next-line
  }, [mainStore.openedCourierCluster]);

  return (
    <div
      className={htmlClasses('map-pin _courier-cluster', { _active: isActive })}
      onClick={handlePinClick}
    >
      <div className='count'>{couriers.length}</div>
      {couriers && (
        <div className='couriers'>
          {couriers.map((courier) => (
            <CourierLabel
              id={courier.properties.courierId}
              userName={courier.properties.courier.user.user.name}
              status={courier.properties.courier.courier.status}
              vehicleType={courier.properties.courier.courier.vehicleType}
              clickable
            />
          ))}
        </div>
      )}
    </div>
  );
});

import PopupAddTeam from 'components/PopupAddTeam';
import htmlClasses from 'html-classes';
import React, { useRef } from 'react';
import { dataStore } from 'stores/DataStore';
import PopupFilter from './PopupFilter';
import { observer } from 'mobx-react-lite';
import MobileSidebar from './MobileSidebar';
import { mainStore } from 'stores/MainStore';
import PopupAddCourier from './PopupAddCourier';
import { BurgerIcon, TeamlessIcon, UnassignedIcon, TeamsIcon, MapIcon } from 'icons';
import { useTranslation } from 'react-i18next';

interface MobileFooterItem {
  id: string;
  Icon: () => JSX.Element;
  text: string;
  onClick: () => void;
  active: boolean;
  count?: number;
}

export default observer(() => {
  const { t } = useTranslation();
  const sidebarRef = useRef<HTMLDivElement>(null);

  const MOBILE_FOOTER_ITEMS: MobileFooterItem[] = [
    {
      id: 'MENU',
      Icon: () => <BurgerIcon className='item__icon' />,
      text: t('common:menu'),
      active: mainStore.activeMobileScreen === 'sidebar',
      onClick: () => mainStore.setActiveMobileScreen('sidebar'),
    },
    {
      id: 'MAP',
      Icon: () => <MapIcon className='item__icon'/>,
      text: t('footerMenu:map'),
      active: mainStore.activeMobileScreen === 'map',
      onClick: () => mainStore.setActiveMobileScreen('map')
    },
    {
      id: 'MY_TEAMS',
      Icon: () => <TeamsIcon className='item__icon'/>,
      text: t('footerMenu:myTeams'),
      active: mainStore.activeMobileScreen === 'teams',
      onClick: () => mainStore.setActiveMobileScreen('teams'),
      count: dataStore.teamsCouriersCount
    },
    {
      id: 'TEAMLESS',
      Icon: () => <TeamlessIcon className='item__icon'/>,
      text: t('common:teamLess'),
      active: mainStore.activeMobileScreen === 'teamless',
      onClick: () => mainStore.setActiveMobileScreen('teamless'),
      count: dataStore.teamlessCouriersCount
    },
    {
      id: 'UNASSIGNED',
      Icon: () => <UnassignedIcon className='item__icon'/>,
      text: t('courierState:unassigned'),
      active: mainStore.activeMobileScreen === 'unassigned',
      onClick: () => mainStore.setActiveMobileScreen('unassigned')
    },
  ];

  return (
    <>
      <div className='mobile-footer'>
        {MOBILE_FOOTER_ITEMS.map((Item: MobileFooterItem) => (
          <div key={Item.id}
               onClick={Item.onClick}
               className={htmlClasses('mobile-footer__item item', {
            _active: Item.active
          })}>
            <div className='item__icon'>
              <Item.Icon />
              {Item.count !== undefined && <div className='item__count'>{Item.count}</div>}
            </div>
            <div className='item__text'>{Item.text}</div>
          </div>
        ))}
      </div>
      <MobileSidebar sidebarRef={sidebarRef} />
      <PopupAddTeam />
      <PopupAddCourier />
      <PopupFilter />
    </>
  );
});

/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
;(function (global, factory) {
    /* global define, require, module */

    /* AMD */ if (typeof define === 'function' && define.amd) define(['protobufjs/minimal'], factory)
    /* CommonJS */ else if (typeof require === 'function' && typeof module === 'object' && module && module.exports)
        module.exports = factory(require('protobufjs/minimal'))
})(this, function ($protobuf) {
    'use strict'

    // Common aliases
    var $Reader = $protobuf.Reader,
        $Writer = $protobuf.Writer,
        $util = $protobuf.util

    // Exported root namespace
    var $root = $protobuf.roots['default'] || ($protobuf.roots['default'] = {})

    $root.CourierCreateReqV2 = (function () {
        /**
         * Properties of a CourierCreateReqV2.
         * @exports ICourierCreateReqV2
         * @interface ICourierCreateReqV2
         * @property {string} name CourierCreateReqV2 name
         * @property {string} phone CourierCreateReqV2 phone
         */

        /**
         * Constructs a new CourierCreateReqV2.
         * @exports CourierCreateReqV2
         * @classdesc Represents a CourierCreateReqV2.
         * @implements ICourierCreateReqV2
         * @constructor
         * @param {ICourierCreateReqV2=} [properties] Properties to set
         */
        function CourierCreateReqV2(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
        }

        /**
         * CourierCreateReqV2 name.
         * @member {string} name
         * @memberof CourierCreateReqV2
         * @instance
         */
        CourierCreateReqV2.prototype.name = ''

        /**
         * CourierCreateReqV2 phone.
         * @member {string} phone
         * @memberof CourierCreateReqV2
         * @instance
         */
        CourierCreateReqV2.prototype.phone = ''

        /**
         * Creates a new CourierCreateReqV2 instance using the specified properties.
         * @function create
         * @memberof CourierCreateReqV2
         * @static
         * @param {ICourierCreateReqV2=} [properties] Properties to set
         * @returns {CourierCreateReqV2} CourierCreateReqV2 instance
         */
        CourierCreateReqV2.create = function create(properties) {
            return new CourierCreateReqV2(properties)
        }

        /**
         * Encodes the specified CourierCreateReqV2 message. Does not implicitly {@link CourierCreateReqV2.verify|verify} messages.
         * @function encode
         * @memberof CourierCreateReqV2
         * @static
         * @param {CourierCreateReqV2} message CourierCreateReqV2 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CourierCreateReqV2.encode = function encode(message, writer) {
            if (!writer) writer = $Writer.create()
            writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.name)
            writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.phone)
            return writer
        }

        /**
         * Encodes the specified CourierCreateReqV2 message, length delimited. Does not implicitly {@link CourierCreateReqV2.verify|verify} messages.
         * @function encodeDelimited
         * @memberof CourierCreateReqV2
         * @static
         * @param {CourierCreateReqV2} message CourierCreateReqV2 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CourierCreateReqV2.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim()
        }

        /**
         * Decodes a CourierCreateReqV2 message from the specified reader or buffer.
         * @function decode
         * @memberof CourierCreateReqV2
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {CourierCreateReqV2} CourierCreateReqV2
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CourierCreateReqV2.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
            var end = length === undefined ? reader.len : reader.pos + length,
                message = new $root.CourierCreateReqV2()
            while (reader.pos < end) {
                var tag = reader.uint32()
                switch (tag >>> 3) {
                    case 1: {
                        message.name = reader.string()
                        break
                    }
                    case 2: {
                        message.phone = reader.string()
                        break
                    }
                    default:
                        reader.skipType(tag & 7)
                        break
                }
            }
            if (!message.hasOwnProperty('name'))
                throw $util.ProtocolError("missing required 'name'", { instance: message })
            if (!message.hasOwnProperty('phone'))
                throw $util.ProtocolError("missing required 'phone'", { instance: message })
            return message
        }

        /**
         * Decodes a CourierCreateReqV2 message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof CourierCreateReqV2
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {CourierCreateReqV2} CourierCreateReqV2
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CourierCreateReqV2.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader)) reader = new $Reader(reader)
            return this.decode(reader, reader.uint32())
        }

        /**
         * Verifies a CourierCreateReqV2 message.
         * @function verify
         * @memberof CourierCreateReqV2
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CourierCreateReqV2.verify = function verify(message) {
            if (typeof message !== 'object' || message === null) return 'object expected'
            if (!$util.isString(message.name)) return 'name: string expected'
            if (!$util.isString(message.phone)) return 'phone: string expected'
            return null
        }

        /**
         * Creates a CourierCreateReqV2 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof CourierCreateReqV2
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {CourierCreateReqV2} CourierCreateReqV2
         */
        CourierCreateReqV2.fromObject = function fromObject(object) {
            if (object instanceof $root.CourierCreateReqV2) return object
            var message = new $root.CourierCreateReqV2()
            if (object.name != null) message.name = String(object.name)
            if (object.phone != null) message.phone = String(object.phone)
            return message
        }

        /**
         * Creates a plain object from a CourierCreateReqV2 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof CourierCreateReqV2
         * @static
         * @param {CourierCreateReqV2} message CourierCreateReqV2
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CourierCreateReqV2.toObject = function toObject(message, options) {
            if (!options) options = {}
            var object = {}
            if (options.defaults) {
                object.name = ''
                object.phone = ''
            }
            if (message.name != null && message.hasOwnProperty('name')) object.name = message.name
            if (message.phone != null && message.hasOwnProperty('phone')) object.phone = message.phone
            return object
        }

        /**
         * Converts this CourierCreateReqV2 to JSON.
         * @function toJSON
         * @memberof CourierCreateReqV2
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CourierCreateReqV2.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
        }

        /**
         * Gets the default type url for CourierCreateReqV2
         * @function getTypeUrl
         * @memberof CourierCreateReqV2
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CourierCreateReqV2.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = 'type.googleapis.com'
            }
            return typeUrlPrefix + '/CourierCreateReqV2'
        }

        return CourierCreateReqV2
    })()

    $root.couriers = (function () {
        /**
         * Namespace couriers.
         * @exports couriers
         * @namespace
         */
        var couriers = {}

        couriers.GetCouriersListResponse = (function () {
            /**
             * Properties of a GetCouriersListResponse.
             * @memberof couriers
             * @interface IGetCouriersListResponse
             * @property {Array.<couriers.Courier>|null} [couriers] GetCouriersListResponse couriers
             */

            /**
             * Constructs a new GetCouriersListResponse.
             * @memberof couriers
             * @classdesc Represents a GetCouriersListResponse.
             * @implements IGetCouriersListResponse
             * @constructor
             * @param {couriers.IGetCouriersListResponse=} [properties] Properties to set
             */
            function GetCouriersListResponse(properties) {
                this.couriers = []
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
            }

            /**
             * GetCouriersListResponse couriers.
             * @member {Array.<couriers.Courier>} couriers
             * @memberof couriers.GetCouriersListResponse
             * @instance
             */
            GetCouriersListResponse.prototype.couriers = $util.emptyArray

            /**
             * Creates a new GetCouriersListResponse instance using the specified properties.
             * @function create
             * @memberof couriers.GetCouriersListResponse
             * @static
             * @param {couriers.IGetCouriersListResponse=} [properties] Properties to set
             * @returns {couriers.GetCouriersListResponse} GetCouriersListResponse instance
             */
            GetCouriersListResponse.create = function create(properties) {
                return new GetCouriersListResponse(properties)
            }

            /**
             * Encodes the specified GetCouriersListResponse message. Does not implicitly {@link couriers.GetCouriersListResponse.verify|verify} messages.
             * @function encode
             * @memberof couriers.GetCouriersListResponse
             * @static
             * @param {couriers.GetCouriersListResponse} message GetCouriersListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCouriersListResponse.encode = function encode(message, writer) {
                if (!writer) writer = $Writer.create()
                if (message.couriers != null && message.couriers.length)
                    for (var i = 0; i < message.couriers.length; ++i)
                        $root.couriers.Courier.encode(
                            message.couriers[i],
                            writer.uint32(/* id 1, wireType 2 =*/ 10).fork()
                        ).ldelim()
                return writer
            }

            /**
             * Encodes the specified GetCouriersListResponse message, length delimited. Does not implicitly {@link couriers.GetCouriersListResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof couriers.GetCouriersListResponse
             * @static
             * @param {couriers.GetCouriersListResponse} message GetCouriersListResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCouriersListResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim()
            }

            /**
             * Decodes a GetCouriersListResponse message from the specified reader or buffer.
             * @function decode
             * @memberof couriers.GetCouriersListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {couriers.GetCouriersListResponse} GetCouriersListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCouriersListResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
                var end = length === undefined ? reader.len : reader.pos + length,
                    message = new $root.couriers.GetCouriersListResponse()
                while (reader.pos < end) {
                    var tag = reader.uint32()
                    switch (tag >>> 3) {
                        case 1: {
                            if (!(message.couriers && message.couriers.length)) message.couriers = []
                            message.couriers.push($root.couriers.Courier.decode(reader, reader.uint32()))
                            break
                        }
                        default:
                            reader.skipType(tag & 7)
                            break
                    }
                }
                return message
            }

            /**
             * Decodes a GetCouriersListResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof couriers.GetCouriersListResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {couriers.GetCouriersListResponse} GetCouriersListResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCouriersListResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader)) reader = new $Reader(reader)
                return this.decode(reader, reader.uint32())
            }

            /**
             * Verifies a GetCouriersListResponse message.
             * @function verify
             * @memberof couriers.GetCouriersListResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetCouriersListResponse.verify = function verify(message) {
                if (typeof message !== 'object' || message === null) return 'object expected'
                if (message.couriers != null && message.hasOwnProperty('couriers')) {
                    if (!Array.isArray(message.couriers)) return 'couriers: array expected'
                    for (var i = 0; i < message.couriers.length; ++i) {
                        var error = $root.couriers.Courier.verify(message.couriers[i])
                        if (error) return 'couriers.' + error
                    }
                }
                return null
            }

            /**
             * Creates a GetCouriersListResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof couriers.GetCouriersListResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {couriers.GetCouriersListResponse} GetCouriersListResponse
             */
            GetCouriersListResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.couriers.GetCouriersListResponse) return object
                var message = new $root.couriers.GetCouriersListResponse()
                if (object.couriers) {
                    if (!Array.isArray(object.couriers))
                        throw TypeError('.couriers.GetCouriersListResponse.couriers: array expected')
                    message.couriers = []
                    for (var i = 0; i < object.couriers.length; ++i) {
                        if (typeof object.couriers[i] !== 'object')
                            throw TypeError('.couriers.GetCouriersListResponse.couriers: object expected')
                        message.couriers[i] = $root.couriers.Courier.fromObject(object.couriers[i])
                    }
                }
                return message
            }

            /**
             * Creates a plain object from a GetCouriersListResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof couriers.GetCouriersListResponse
             * @static
             * @param {couriers.GetCouriersListResponse} message GetCouriersListResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCouriersListResponse.toObject = function toObject(message, options) {
                if (!options) options = {}
                var object = {}
                if (options.arrays || options.defaults) object.couriers = []
                if (message.couriers && message.couriers.length) {
                    object.couriers = []
                    for (var j = 0; j < message.couriers.length; ++j)
                        object.couriers[j] = $root.couriers.Courier.toObject(message.couriers[j], options)
                }
                return object
            }

            /**
             * Converts this GetCouriersListResponse to JSON.
             * @function toJSON
             * @memberof couriers.GetCouriersListResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCouriersListResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
            }

            /**
             * Gets the default type url for GetCouriersListResponse
             * @function getTypeUrl
             * @memberof couriers.GetCouriersListResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetCouriersListResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = 'type.googleapis.com'
                }
                return typeUrlPrefix + '/couriers.GetCouriersListResponse'
            }

            return GetCouriersListResponse
        })()

        couriers.BulkCouriersResponse = (function () {
            /**
             * Properties of a BulkCouriersResponse.
             * @memberof couriers
             * @interface IBulkCouriersResponse
             * @property {Array.<couriers.Courier>|null} [couriers] BulkCouriersResponse couriers
             */

            /**
             * Constructs a new BulkCouriersResponse.
             * @memberof couriers
             * @classdesc Represents a BulkCouriersResponse.
             * @implements IBulkCouriersResponse
             * @constructor
             * @param {couriers.IBulkCouriersResponse=} [properties] Properties to set
             */
            function BulkCouriersResponse(properties) {
                this.couriers = []
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
            }

            /**
             * BulkCouriersResponse couriers.
             * @member {Array.<couriers.Courier>} couriers
             * @memberof couriers.BulkCouriersResponse
             * @instance
             */
            BulkCouriersResponse.prototype.couriers = $util.emptyArray

            /**
             * Creates a new BulkCouriersResponse instance using the specified properties.
             * @function create
             * @memberof couriers.BulkCouriersResponse
             * @static
             * @param {couriers.IBulkCouriersResponse=} [properties] Properties to set
             * @returns {couriers.BulkCouriersResponse} BulkCouriersResponse instance
             */
            BulkCouriersResponse.create = function create(properties) {
                return new BulkCouriersResponse(properties)
            }

            /**
             * Encodes the specified BulkCouriersResponse message. Does not implicitly {@link couriers.BulkCouriersResponse.verify|verify} messages.
             * @function encode
             * @memberof couriers.BulkCouriersResponse
             * @static
             * @param {couriers.BulkCouriersResponse} message BulkCouriersResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BulkCouriersResponse.encode = function encode(message, writer) {
                if (!writer) writer = $Writer.create()
                if (message.couriers != null && message.couriers.length)
                    for (var i = 0; i < message.couriers.length; ++i)
                        $root.couriers.Courier.encode(
                            message.couriers[i],
                            writer.uint32(/* id 1, wireType 2 =*/ 10).fork()
                        ).ldelim()
                return writer
            }

            /**
             * Encodes the specified BulkCouriersResponse message, length delimited. Does not implicitly {@link couriers.BulkCouriersResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof couriers.BulkCouriersResponse
             * @static
             * @param {couriers.BulkCouriersResponse} message BulkCouriersResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BulkCouriersResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim()
            }

            /**
             * Decodes a BulkCouriersResponse message from the specified reader or buffer.
             * @function decode
             * @memberof couriers.BulkCouriersResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {couriers.BulkCouriersResponse} BulkCouriersResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BulkCouriersResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
                var end = length === undefined ? reader.len : reader.pos + length,
                    message = new $root.couriers.BulkCouriersResponse()
                while (reader.pos < end) {
                    var tag = reader.uint32()
                    switch (tag >>> 3) {
                        case 1: {
                            if (!(message.couriers && message.couriers.length)) message.couriers = []
                            message.couriers.push($root.couriers.Courier.decode(reader, reader.uint32()))
                            break
                        }
                        default:
                            reader.skipType(tag & 7)
                            break
                    }
                }
                return message
            }

            /**
             * Decodes a BulkCouriersResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof couriers.BulkCouriersResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {couriers.BulkCouriersResponse} BulkCouriersResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BulkCouriersResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader)) reader = new $Reader(reader)
                return this.decode(reader, reader.uint32())
            }

            /**
             * Verifies a BulkCouriersResponse message.
             * @function verify
             * @memberof couriers.BulkCouriersResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BulkCouriersResponse.verify = function verify(message) {
                if (typeof message !== 'object' || message === null) return 'object expected'
                if (message.couriers != null && message.hasOwnProperty('couriers')) {
                    if (!Array.isArray(message.couriers)) return 'couriers: array expected'
                    for (var i = 0; i < message.couriers.length; ++i) {
                        var error = $root.couriers.Courier.verify(message.couriers[i])
                        if (error) return 'couriers.' + error
                    }
                }
                return null
            }

            /**
             * Creates a BulkCouriersResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof couriers.BulkCouriersResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {couriers.BulkCouriersResponse} BulkCouriersResponse
             */
            BulkCouriersResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.couriers.BulkCouriersResponse) return object
                var message = new $root.couriers.BulkCouriersResponse()
                if (object.couriers) {
                    if (!Array.isArray(object.couriers))
                        throw TypeError('.couriers.BulkCouriersResponse.couriers: array expected')
                    message.couriers = []
                    for (var i = 0; i < object.couriers.length; ++i) {
                        if (typeof object.couriers[i] !== 'object')
                            throw TypeError('.couriers.BulkCouriersResponse.couriers: object expected')
                        message.couriers[i] = $root.couriers.Courier.fromObject(object.couriers[i])
                    }
                }
                return message
            }

            /**
             * Creates a plain object from a BulkCouriersResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof couriers.BulkCouriersResponse
             * @static
             * @param {couriers.BulkCouriersResponse} message BulkCouriersResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BulkCouriersResponse.toObject = function toObject(message, options) {
                if (!options) options = {}
                var object = {}
                if (options.arrays || options.defaults) object.couriers = []
                if (message.couriers && message.couriers.length) {
                    object.couriers = []
                    for (var j = 0; j < message.couriers.length; ++j)
                        object.couriers[j] = $root.couriers.Courier.toObject(message.couriers[j], options)
                }
                return object
            }

            /**
             * Converts this BulkCouriersResponse to JSON.
             * @function toJSON
             * @memberof couriers.BulkCouriersResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BulkCouriersResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
            }

            /**
             * Gets the default type url for BulkCouriersResponse
             * @function getTypeUrl
             * @memberof couriers.BulkCouriersResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BulkCouriersResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = 'type.googleapis.com'
                }
                return typeUrlPrefix + '/couriers.BulkCouriersResponse'
            }

            return BulkCouriersResponse
        })()

        /**
         * Status enum.
         * @name couriers.Status
         * @enum {number}
         * @property {number} OFFLINE=0 OFFLINE value
         * @property {number} ONLINE=1 ONLINE value
         * @property {number} IDLE=2 IDLE value
         * @property {number} ASSIGNED_TO_JOB=7 ASSIGNED_TO_JOB value
         * @property {number} ACCEPTED_JOB=8 ACCEPTED_JOB value
         * @property {number} PICKING_UP=3 PICKING_UP value
         * @property {number} DELIVERING=4 DELIVERING value
         * @property {number} HEADING_TO_BASE=5 HEADING_TO_BASE value
         * @property {number} NOT_RESPONDING=6 NOT_RESPONDING value
         */
        couriers.Status = (function () {
            var valuesById = {},
                values = Object.create(valuesById)
            values[(valuesById[0] = 'OFFLINE')] = 0
            values[(valuesById[1] = 'ONLINE')] = 1
            values[(valuesById[2] = 'IDLE')] = 2
            values[(valuesById[7] = 'ASSIGNED_TO_JOB')] = 7
            values[(valuesById[8] = 'ACCEPTED_JOB')] = 8
            values[(valuesById[3] = 'PICKING_UP')] = 3
            values[(valuesById[4] = 'DELIVERING')] = 4
            values[(valuesById[5] = 'HEADING_TO_BASE')] = 5
            values[(valuesById[6] = 'NOT_RESPONDING')] = 6
            return values
        })()

        /**
         * VehicleType enum.
         * @name couriers.VehicleType
         * @enum {number}
         * @property {number} BICYCLE=0 BICYCLE value
         * @property {number} CAR=1 CAR value
         * @property {number} FOOTER=2 FOOTER value
         * @property {number} MOTORBIKE=3 MOTORBIKE value
         */
        couriers.VehicleType = (function () {
            var valuesById = {},
                values = Object.create(valuesById)
            values[(valuesById[0] = 'BICYCLE')] = 0
            values[(valuesById[1] = 'CAR')] = 1
            values[(valuesById[2] = 'FOOTER')] = 2
            values[(valuesById[3] = 'MOTORBIKE')] = 3
            return values
        })()

        couriers.Courier = (function () {
            /**
             * Properties of a Courier.
             * @memberof couriers
             * @interface ICourier
             * @property {string} id Courier id
             * @property {string} userId Courier userId
             * @property {couriers.Status} status Courier status
             * @property {couriers.VehicleType} vehicleType Courier vehicleType
             * @property {number} latitude Courier latitude
             * @property {number} longitude Courier longitude
             * @property {number} createdAt Courier createdAt
             * @property {number|null} [updatedAt] Courier updatedAt
             * @property {number|null} [deletedAt] Courier deletedAt
             * @property {number|null} [capacity] Courier capacity
             * @property {boolean|null} [agency] Courier agency
             * @property {string|null} [name] Courier name
             */

            /**
             * Constructs a new Courier.
             * @memberof couriers
             * @classdesc Represents a Courier.
             * @implements ICourier
             * @constructor
             * @param {couriers.ICourier=} [properties] Properties to set
             */
            function Courier(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
            }

            /**
             * Courier id.
             * @member {string} id
             * @memberof couriers.Courier
             * @instance
             */
            Courier.prototype.id = ''

            /**
             * Courier userId.
             * @member {string} userId
             * @memberof couriers.Courier
             * @instance
             */
            Courier.prototype.userId = ''

            /**
             * Courier status.
             * @member {couriers.Status} status
             * @memberof couriers.Courier
             * @instance
             */
            Courier.prototype.status = 0

            /**
             * Courier vehicleType.
             * @member {couriers.VehicleType} vehicleType
             * @memberof couriers.Courier
             * @instance
             */
            Courier.prototype.vehicleType = 0

            /**
             * Courier latitude.
             * @member {number} latitude
             * @memberof couriers.Courier
             * @instance
             */
            Courier.prototype.latitude = 0

            /**
             * Courier longitude.
             * @member {number} longitude
             * @memberof couriers.Courier
             * @instance
             */
            Courier.prototype.longitude = 0

            /**
             * Courier createdAt.
             * @member {number} createdAt
             * @memberof couriers.Courier
             * @instance
             */
            Courier.prototype.createdAt = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Courier updatedAt.
             * @member {number} updatedAt
             * @memberof couriers.Courier
             * @instance
             */
            Courier.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Courier deletedAt.
             * @member {number} deletedAt
             * @memberof couriers.Courier
             * @instance
             */
            Courier.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Courier capacity.
             * @member {number} capacity
             * @memberof couriers.Courier
             * @instance
             */
            Courier.prototype.capacity = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Courier agency.
             * @member {boolean} agency
             * @memberof couriers.Courier
             * @instance
             */
            Courier.prototype.agency = false

            /**
             * Courier name.
             * @member {string} name
             * @memberof couriers.Courier
             * @instance
             */
            Courier.prototype.name = ''

            /**
             * Creates a new Courier instance using the specified properties.
             * @function create
             * @memberof couriers.Courier
             * @static
             * @param {couriers.ICourier=} [properties] Properties to set
             * @returns {couriers.Courier} Courier instance
             */
            Courier.create = function create(properties) {
                return new Courier(properties)
            }

            /**
             * Encodes the specified Courier message. Does not implicitly {@link couriers.Courier.verify|verify} messages.
             * @function encode
             * @memberof couriers.Courier
             * @static
             * @param {couriers.Courier} message Courier message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Courier.encode = function encode(message, writer) {
                if (!writer) writer = $Writer.create()
                writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.id)
                writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.userId)
                writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.status)
                writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.vehicleType)
                writer.uint32(/* id 5, wireType 1 =*/ 41).double(message.latitude)
                writer.uint32(/* id 6, wireType 1 =*/ 49).double(message.longitude)
                writer.uint32(/* id 7, wireType 0 =*/ 56).int64(message.createdAt)
                if (message.updatedAt != null && Object.hasOwnProperty.call(message, 'updatedAt'))
                    writer.uint32(/* id 8, wireType 0 =*/ 64).int64(message.updatedAt)
                if (message.deletedAt != null && Object.hasOwnProperty.call(message, 'deletedAt'))
                    writer.uint32(/* id 9, wireType 0 =*/ 72).int64(message.deletedAt)
                if (message.capacity != null && Object.hasOwnProperty.call(message, 'capacity'))
                    writer.uint32(/* id 10, wireType 0 =*/ 80).int64(message.capacity)
                if (message.agency != null && Object.hasOwnProperty.call(message, 'agency'))
                    writer.uint32(/* id 11, wireType 0 =*/ 88).bool(message.agency)
                if (message.name != null && Object.hasOwnProperty.call(message, 'name'))
                    writer.uint32(/* id 12, wireType 2 =*/ 98).string(message.name)
                return writer
            }

            /**
             * Encodes the specified Courier message, length delimited. Does not implicitly {@link couriers.Courier.verify|verify} messages.
             * @function encodeDelimited
             * @memberof couriers.Courier
             * @static
             * @param {couriers.Courier} message Courier message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Courier.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim()
            }

            /**
             * Decodes a Courier message from the specified reader or buffer.
             * @function decode
             * @memberof couriers.Courier
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {couriers.Courier} Courier
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Courier.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
                var end = length === undefined ? reader.len : reader.pos + length,
                    message = new $root.couriers.Courier()
                while (reader.pos < end) {
                    var tag = reader.uint32()
                    switch (tag >>> 3) {
                        case 1: {
                            message.id = reader.string()
                            break
                        }
                        case 2: {
                            message.userId = reader.string()
                            break
                        }
                        case 3: {
                            message.status = reader.int32()
                            break
                        }
                        case 4: {
                            message.vehicleType = reader.int32()
                            break
                        }
                        case 5: {
                            message.latitude = reader.double()
                            break
                        }
                        case 6: {
                            message.longitude = reader.double()
                            break
                        }
                        case 7: {
                            message.createdAt = reader.int64()
                            break
                        }
                        case 8: {
                            message.updatedAt = reader.int64()
                            break
                        }
                        case 9: {
                            message.deletedAt = reader.int64()
                            break
                        }
                        case 10: {
                            message.capacity = reader.int64()
                            break
                        }
                        case 11: {
                            message.agency = reader.bool()
                            break
                        }
                        case 12: {
                            message.name = reader.string()
                            break
                        }
                        default:
                            reader.skipType(tag & 7)
                            break
                    }
                }
                if (!message.hasOwnProperty('id'))
                    throw $util.ProtocolError("missing required 'id'", { instance: message })
                if (!message.hasOwnProperty('userId'))
                    throw $util.ProtocolError("missing required 'userId'", { instance: message })
                if (!message.hasOwnProperty('status'))
                    throw $util.ProtocolError("missing required 'status'", { instance: message })
                if (!message.hasOwnProperty('vehicleType'))
                    throw $util.ProtocolError("missing required 'vehicleType'", { instance: message })
                if (!message.hasOwnProperty('latitude'))
                    throw $util.ProtocolError("missing required 'latitude'", { instance: message })
                if (!message.hasOwnProperty('longitude'))
                    throw $util.ProtocolError("missing required 'longitude'", { instance: message })
                if (!message.hasOwnProperty('createdAt'))
                    throw $util.ProtocolError("missing required 'createdAt'", { instance: message })
                return message
            }

            /**
             * Decodes a Courier message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof couriers.Courier
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {couriers.Courier} Courier
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Courier.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader)) reader = new $Reader(reader)
                return this.decode(reader, reader.uint32())
            }

            /**
             * Verifies a Courier message.
             * @function verify
             * @memberof couriers.Courier
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Courier.verify = function verify(message) {
                if (typeof message !== 'object' || message === null) return 'object expected'
                if (!$util.isString(message.id)) return 'id: string expected'
                if (!$util.isString(message.userId)) return 'userId: string expected'
                switch (message.status) {
                    default:
                        return 'status: enum value expected'
                    case 0:
                    case 1:
                    case 2:
                    case 7:
                    case 8:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                        break
                }
                switch (message.vehicleType) {
                    default:
                        return 'vehicleType: enum value expected'
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break
                }
                if (typeof message.latitude !== 'number') return 'latitude: number expected'
                if (typeof message.longitude !== 'number') return 'longitude: number expected'
                if (
                    !$util.isInteger(message.createdAt) &&
                    !(
                        message.createdAt &&
                        $util.isInteger(message.createdAt.low) &&
                        $util.isInteger(message.createdAt.high)
                    )
                )
                    return 'createdAt: integer|Long expected'
                if (message.updatedAt != null && message.hasOwnProperty('updatedAt'))
                    if (
                        !$util.isInteger(message.updatedAt) &&
                        !(
                            message.updatedAt &&
                            $util.isInteger(message.updatedAt.low) &&
                            $util.isInteger(message.updatedAt.high)
                        )
                    )
                        return 'updatedAt: integer|Long expected'
                if (message.deletedAt != null && message.hasOwnProperty('deletedAt'))
                    if (
                        !$util.isInteger(message.deletedAt) &&
                        !(
                            message.deletedAt &&
                            $util.isInteger(message.deletedAt.low) &&
                            $util.isInteger(message.deletedAt.high)
                        )
                    )
                        return 'deletedAt: integer|Long expected'
                if (message.capacity != null && message.hasOwnProperty('capacity'))
                    if (
                        !$util.isInteger(message.capacity) &&
                        !(
                            message.capacity &&
                            $util.isInteger(message.capacity.low) &&
                            $util.isInteger(message.capacity.high)
                        )
                    )
                        return 'capacity: integer|Long expected'
                if (message.agency != null && message.hasOwnProperty('agency'))
                    if (typeof message.agency !== 'boolean') return 'agency: boolean expected'
                if (message.name != null && message.hasOwnProperty('name'))
                    if (!$util.isString(message.name)) return 'name: string expected'
                return null
            }

            /**
             * Creates a Courier message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof couriers.Courier
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {couriers.Courier} Courier
             */
            Courier.fromObject = function fromObject(object) {
                if (object instanceof $root.couriers.Courier) return object
                var message = new $root.couriers.Courier()
                if (object.id != null) message.id = String(object.id)
                if (object.userId != null) message.userId = String(object.userId)
                switch (object.status) {
                    case 'OFFLINE':
                    case 0:
                        message.status = 0
                        break
                    case 'ONLINE':
                    case 1:
                        message.status = 1
                        break
                    case 'IDLE':
                    case 2:
                        message.status = 2
                        break
                    case 'ASSIGNED_TO_JOB':
                    case 7:
                        message.status = 7
                        break
                    case 'ACCEPTED_JOB':
                    case 8:
                        message.status = 8
                        break
                    case 'PICKING_UP':
                    case 3:
                        message.status = 3
                        break
                    case 'DELIVERING':
                    case 4:
                        message.status = 4
                        break
                    case 'HEADING_TO_BASE':
                    case 5:
                        message.status = 5
                        break
                    case 'NOT_RESPONDING':
                    case 6:
                        message.status = 6
                        break
                }
                switch (object.vehicleType) {
                    case 'BICYCLE':
                    case 0:
                        message.vehicleType = 0
                        break
                    case 'CAR':
                    case 1:
                        message.vehicleType = 1
                        break
                    case 'FOOTER':
                    case 2:
                        message.vehicleType = 2
                        break
                    case 'MOTORBIKE':
                    case 3:
                        message.vehicleType = 3
                        break
                }
                if (object.latitude != null) message.latitude = Number(object.latitude)
                if (object.longitude != null) message.longitude = Number(object.longitude)
                if (object.createdAt != null)
                    if ($util.Long) (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false
                    else if (typeof object.createdAt === 'string') message.createdAt = parseInt(object.createdAt, 10)
                    else if (typeof object.createdAt === 'number') message.createdAt = object.createdAt
                    else if (typeof object.createdAt === 'object')
                        message.createdAt = new $util.LongBits(
                            object.createdAt.low >>> 0,
                            object.createdAt.high >>> 0
                        ).toNumber()
                if (object.updatedAt != null)
                    if ($util.Long) (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false
                    else if (typeof object.updatedAt === 'string') message.updatedAt = parseInt(object.updatedAt, 10)
                    else if (typeof object.updatedAt === 'number') message.updatedAt = object.updatedAt
                    else if (typeof object.updatedAt === 'object')
                        message.updatedAt = new $util.LongBits(
                            object.updatedAt.low >>> 0,
                            object.updatedAt.high >>> 0
                        ).toNumber()
                if (object.deletedAt != null)
                    if ($util.Long) (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false
                    else if (typeof object.deletedAt === 'string') message.deletedAt = parseInt(object.deletedAt, 10)
                    else if (typeof object.deletedAt === 'number') message.deletedAt = object.deletedAt
                    else if (typeof object.deletedAt === 'object')
                        message.deletedAt = new $util.LongBits(
                            object.deletedAt.low >>> 0,
                            object.deletedAt.high >>> 0
                        ).toNumber()
                if (object.capacity != null)
                    if ($util.Long) (message.capacity = $util.Long.fromValue(object.capacity)).unsigned = false
                    else if (typeof object.capacity === 'string') message.capacity = parseInt(object.capacity, 10)
                    else if (typeof object.capacity === 'number') message.capacity = object.capacity
                    else if (typeof object.capacity === 'object')
                        message.capacity = new $util.LongBits(
                            object.capacity.low >>> 0,
                            object.capacity.high >>> 0
                        ).toNumber()
                if (object.agency != null) message.agency = Boolean(object.agency)
                if (object.name != null) message.name = String(object.name)
                return message
            }

            /**
             * Creates a plain object from a Courier message. Also converts values to other types if specified.
             * @function toObject
             * @memberof couriers.Courier
             * @static
             * @param {couriers.Courier} message Courier
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Courier.toObject = function toObject(message, options) {
                if (!options) options = {}
                var object = {}
                if (options.defaults) {
                    object.id = ''
                    object.userId = ''
                    object.status = options.enums === String ? 'OFFLINE' : 0
                    object.vehicleType = options.enums === String ? 'BICYCLE' : 0
                    object.latitude = 0
                    object.longitude = 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.createdAt =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.createdAt = options.longs === String ? '0' : 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.updatedAt =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.updatedAt = options.longs === String ? '0' : 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.deletedAt =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.deletedAt = options.longs === String ? '0' : 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.capacity =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.capacity = options.longs === String ? '0' : 0
                    object.agency = false
                    object.name = ''
                }
                if (message.id != null && message.hasOwnProperty('id')) object.id = message.id
                if (message.userId != null && message.hasOwnProperty('userId')) object.userId = message.userId
                if (message.status != null && message.hasOwnProperty('status'))
                    object.status = options.enums === String ? $root.couriers.Status[message.status] : message.status
                if (message.vehicleType != null && message.hasOwnProperty('vehicleType'))
                    object.vehicleType =
                        options.enums === String ? $root.couriers.VehicleType[message.vehicleType] : message.vehicleType
                if (message.latitude != null && message.hasOwnProperty('latitude'))
                    object.latitude =
                        options.json && !isFinite(message.latitude) ? String(message.latitude) : message.latitude
                if (message.longitude != null && message.hasOwnProperty('longitude'))
                    object.longitude =
                        options.json && !isFinite(message.longitude) ? String(message.longitude) : message.longitude
                if (message.createdAt != null && message.hasOwnProperty('createdAt'))
                    if (typeof message.createdAt === 'number')
                        object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt
                    else
                        object.createdAt =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.createdAt)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.createdAt.low >>> 0,
                                      message.createdAt.high >>> 0
                                  ).toNumber()
                                : message.createdAt
                if (message.updatedAt != null && message.hasOwnProperty('updatedAt'))
                    if (typeof message.updatedAt === 'number')
                        object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt
                    else
                        object.updatedAt =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.updatedAt)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.updatedAt.low >>> 0,
                                      message.updatedAt.high >>> 0
                                  ).toNumber()
                                : message.updatedAt
                if (message.deletedAt != null && message.hasOwnProperty('deletedAt'))
                    if (typeof message.deletedAt === 'number')
                        object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt
                    else
                        object.deletedAt =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.deletedAt)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.deletedAt.low >>> 0,
                                      message.deletedAt.high >>> 0
                                  ).toNumber()
                                : message.deletedAt
                if (message.capacity != null && message.hasOwnProperty('capacity'))
                    if (typeof message.capacity === 'number')
                        object.capacity = options.longs === String ? String(message.capacity) : message.capacity
                    else
                        object.capacity =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.capacity)
                                : options.longs === Number
                                ? new $util.LongBits(message.capacity.low >>> 0, message.capacity.high >>> 0).toNumber()
                                : message.capacity
                if (message.agency != null && message.hasOwnProperty('agency')) object.agency = message.agency
                if (message.name != null && message.hasOwnProperty('name')) object.name = message.name
                return object
            }

            /**
             * Converts this Courier to JSON.
             * @function toJSON
             * @memberof couriers.Courier
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Courier.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
            }

            /**
             * Gets the default type url for Courier
             * @function getTypeUrl
             * @memberof couriers.Courier
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Courier.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = 'type.googleapis.com'
                }
                return typeUrlPrefix + '/couriers.Courier'
            }

            return Courier
        })()

        couriers.CourierWithRel = (function () {
            /**
             * Properties of a CourierWithRel.
             * @memberof couriers
             * @interface ICourierWithRel
             * @property {couriers.Courier} courier CourierWithRel courier
             * @property {users.UserWithRel} user CourierWithRel user
             */

            /**
             * Constructs a new CourierWithRel.
             * @memberof couriers
             * @classdesc Represents a CourierWithRel.
             * @implements ICourierWithRel
             * @constructor
             * @param {couriers.ICourierWithRel=} [properties] Properties to set
             */
            function CourierWithRel(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
            }

            /**
             * CourierWithRel courier.
             * @member {couriers.Courier} courier
             * @memberof couriers.CourierWithRel
             * @instance
             */
            CourierWithRel.prototype.courier = null

            /**
             * CourierWithRel user.
             * @member {users.UserWithRel} user
             * @memberof couriers.CourierWithRel
             * @instance
             */
            CourierWithRel.prototype.user = null

            /**
             * Creates a new CourierWithRel instance using the specified properties.
             * @function create
             * @memberof couriers.CourierWithRel
             * @static
             * @param {couriers.ICourierWithRel=} [properties] Properties to set
             * @returns {couriers.CourierWithRel} CourierWithRel instance
             */
            CourierWithRel.create = function create(properties) {
                return new CourierWithRel(properties)
            }

            /**
             * Encodes the specified CourierWithRel message. Does not implicitly {@link couriers.CourierWithRel.verify|verify} messages.
             * @function encode
             * @memberof couriers.CourierWithRel
             * @static
             * @param {couriers.CourierWithRel} message CourierWithRel message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CourierWithRel.encode = function encode(message, writer) {
                if (!writer) writer = $Writer.create()
                $root.couriers.Courier.encode(
                    message.courier,
                    writer.uint32(/* id 1, wireType 2 =*/ 10).fork()
                ).ldelim()
                $root.users.UserWithRel.encode(message.user, writer.uint32(/* id 2, wireType 2 =*/ 18).fork()).ldelim()
                return writer
            }

            /**
             * Encodes the specified CourierWithRel message, length delimited. Does not implicitly {@link couriers.CourierWithRel.verify|verify} messages.
             * @function encodeDelimited
             * @memberof couriers.CourierWithRel
             * @static
             * @param {couriers.CourierWithRel} message CourierWithRel message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CourierWithRel.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim()
            }

            /**
             * Decodes a CourierWithRel message from the specified reader or buffer.
             * @function decode
             * @memberof couriers.CourierWithRel
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {couriers.CourierWithRel} CourierWithRel
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CourierWithRel.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
                var end = length === undefined ? reader.len : reader.pos + length,
                    message = new $root.couriers.CourierWithRel()
                while (reader.pos < end) {
                    var tag = reader.uint32()
                    switch (tag >>> 3) {
                        case 1: {
                            message.courier = $root.couriers.Courier.decode(reader, reader.uint32())
                            break
                        }
                        case 2: {
                            message.user = $root.users.UserWithRel.decode(reader, reader.uint32())
                            break
                        }
                        default:
                            reader.skipType(tag & 7)
                            break
                    }
                }
                if (!message.hasOwnProperty('courier'))
                    throw $util.ProtocolError("missing required 'courier'", { instance: message })
                if (!message.hasOwnProperty('user'))
                    throw $util.ProtocolError("missing required 'user'", { instance: message })
                return message
            }

            /**
             * Decodes a CourierWithRel message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof couriers.CourierWithRel
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {couriers.CourierWithRel} CourierWithRel
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CourierWithRel.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader)) reader = new $Reader(reader)
                return this.decode(reader, reader.uint32())
            }

            /**
             * Verifies a CourierWithRel message.
             * @function verify
             * @memberof couriers.CourierWithRel
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CourierWithRel.verify = function verify(message) {
                if (typeof message !== 'object' || message === null) return 'object expected'
                {
                    var error = $root.couriers.Courier.verify(message.courier)
                    if (error) return 'courier.' + error
                }
                {
                    var error = $root.users.UserWithRel.verify(message.user)
                    if (error) return 'user.' + error
                }
                return null
            }

            /**
             * Creates a CourierWithRel message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof couriers.CourierWithRel
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {couriers.CourierWithRel} CourierWithRel
             */
            CourierWithRel.fromObject = function fromObject(object) {
                if (object instanceof $root.couriers.CourierWithRel) return object
                var message = new $root.couriers.CourierWithRel()
                if (object.courier != null) {
                    if (typeof object.courier !== 'object')
                        throw TypeError('.couriers.CourierWithRel.courier: object expected')
                    message.courier = $root.couriers.Courier.fromObject(object.courier)
                }
                if (object.user != null) {
                    if (typeof object.user !== 'object')
                        throw TypeError('.couriers.CourierWithRel.user: object expected')
                    message.user = $root.users.UserWithRel.fromObject(object.user)
                }
                return message
            }

            /**
             * Creates a plain object from a CourierWithRel message. Also converts values to other types if specified.
             * @function toObject
             * @memberof couriers.CourierWithRel
             * @static
             * @param {couriers.CourierWithRel} message CourierWithRel
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CourierWithRel.toObject = function toObject(message, options) {
                if (!options) options = {}
                var object = {}
                if (options.defaults) {
                    object.courier = null
                    object.user = null
                }
                if (message.courier != null && message.hasOwnProperty('courier'))
                    object.courier = $root.couriers.Courier.toObject(message.courier, options)
                if (message.user != null && message.hasOwnProperty('user'))
                    object.user = $root.users.UserWithRel.toObject(message.user, options)
                return object
            }

            /**
             * Converts this CourierWithRel to JSON.
             * @function toJSON
             * @memberof couriers.CourierWithRel
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CourierWithRel.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
            }

            /**
             * Gets the default type url for CourierWithRel
             * @function getTypeUrl
             * @memberof couriers.CourierWithRel
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CourierWithRel.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = 'type.googleapis.com'
                }
                return typeUrlPrefix + '/couriers.CourierWithRel'
            }

            return CourierWithRel
        })()

        return couriers
    })()

    $root.users = (function () {
        /**
         * Namespace users.
         * @exports users
         * @namespace
         */
        var users = {}

        /**
         * Role enum.
         * @name users.Role
         * @enum {number}
         * @property {number} DISPATCHER=0 DISPATCHER value
         * @property {number} COURIER=1 COURIER value
         */
        users.Role = (function () {
            var valuesById = {},
                values = Object.create(valuesById)
            values[(valuesById[0] = 'DISPATCHER')] = 0
            values[(valuesById[1] = 'COURIER')] = 1
            return values
        })()

        users.User = (function () {
            /**
             * Properties of a User.
             * @memberof users
             * @interface IUser
             * @property {string} id User id
             * @property {string} externalId User externalId
             * @property {string} contactId User contactId
             * @property {number} createdAt User createdAt
             * @property {number|null} [updatedAt] User updatedAt
             * @property {number|null} [deletedAt] User deletedAt
             */

            /**
             * Constructs a new User.
             * @memberof users
             * @classdesc Represents a User.
             * @implements IUser
             * @constructor
             * @param {users.IUser=} [properties] Properties to set
             */
            function User(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
            }

            /**
             * User id.
             * @member {string} id
             * @memberof users.User
             * @instance
             */
            User.prototype.id = ''

            /**
             * User externalId.
             * @member {string} externalId
             * @memberof users.User
             * @instance
             */
            User.prototype.externalId = ''

            /**
             * User contactId.
             * @member {string} contactId
             * @memberof users.User
             * @instance
             */
            User.prototype.contactId = ''

            /**
             * User createdAt.
             * @member {number} createdAt
             * @memberof users.User
             * @instance
             */
            User.prototype.createdAt = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * User updatedAt.
             * @member {number} updatedAt
             * @memberof users.User
             * @instance
             */
            User.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * User deletedAt.
             * @member {number} deletedAt
             * @memberof users.User
             * @instance
             */
            User.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Creates a new User instance using the specified properties.
             * @function create
             * @memberof users.User
             * @static
             * @param {users.IUser=} [properties] Properties to set
             * @returns {users.User} User instance
             */
            User.create = function create(properties) {
                return new User(properties)
            }

            /**
             * Encodes the specified User message. Does not implicitly {@link users.User.verify|verify} messages.
             * @function encode
             * @memberof users.User
             * @static
             * @param {users.User} message User message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            User.encode = function encode(message, writer) {
                if (!writer) writer = $Writer.create()
                writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.id)
                writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.externalId)
                writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.contactId)
                writer.uint32(/* id 4, wireType 0 =*/ 32).int64(message.createdAt)
                if (message.updatedAt != null && Object.hasOwnProperty.call(message, 'updatedAt'))
                    writer.uint32(/* id 5, wireType 0 =*/ 40).int64(message.updatedAt)
                if (message.deletedAt != null && Object.hasOwnProperty.call(message, 'deletedAt'))
                    writer.uint32(/* id 6, wireType 0 =*/ 48).int64(message.deletedAt)
                return writer
            }

            /**
             * Encodes the specified User message, length delimited. Does not implicitly {@link users.User.verify|verify} messages.
             * @function encodeDelimited
             * @memberof users.User
             * @static
             * @param {users.User} message User message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            User.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim()
            }

            /**
             * Decodes a User message from the specified reader or buffer.
             * @function decode
             * @memberof users.User
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {users.User} User
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            User.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
                var end = length === undefined ? reader.len : reader.pos + length,
                    message = new $root.users.User()
                while (reader.pos < end) {
                    var tag = reader.uint32()
                    switch (tag >>> 3) {
                        case 1: {
                            message.id = reader.string()
                            break
                        }
                        case 2: {
                            message.externalId = reader.string()
                            break
                        }
                        case 3: {
                            message.contactId = reader.string()
                            break
                        }
                        case 4: {
                            message.createdAt = reader.int64()
                            break
                        }
                        case 5: {
                            message.updatedAt = reader.int64()
                            break
                        }
                        case 6: {
                            message.deletedAt = reader.int64()
                            break
                        }
                        default:
                            reader.skipType(tag & 7)
                            break
                    }
                }
                if (!message.hasOwnProperty('id'))
                    throw $util.ProtocolError("missing required 'id'", { instance: message })
                if (!message.hasOwnProperty('externalId'))
                    throw $util.ProtocolError("missing required 'externalId'", { instance: message })
                if (!message.hasOwnProperty('contactId'))
                    throw $util.ProtocolError("missing required 'contactId'", { instance: message })
                if (!message.hasOwnProperty('createdAt'))
                    throw $util.ProtocolError("missing required 'createdAt'", { instance: message })
                return message
            }

            /**
             * Decodes a User message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof users.User
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {users.User} User
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            User.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader)) reader = new $Reader(reader)
                return this.decode(reader, reader.uint32())
            }

            /**
             * Verifies a User message.
             * @function verify
             * @memberof users.User
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            User.verify = function verify(message) {
                if (typeof message !== 'object' || message === null) return 'object expected'
                if (!$util.isString(message.id)) return 'id: string expected'
                if (!$util.isString(message.externalId)) return 'externalId: string expected'
                if (!$util.isString(message.contactId)) return 'contactId: string expected'
                if (
                    !$util.isInteger(message.createdAt) &&
                    !(
                        message.createdAt &&
                        $util.isInteger(message.createdAt.low) &&
                        $util.isInteger(message.createdAt.high)
                    )
                )
                    return 'createdAt: integer|Long expected'
                if (message.updatedAt != null && message.hasOwnProperty('updatedAt'))
                    if (
                        !$util.isInteger(message.updatedAt) &&
                        !(
                            message.updatedAt &&
                            $util.isInteger(message.updatedAt.low) &&
                            $util.isInteger(message.updatedAt.high)
                        )
                    )
                        return 'updatedAt: integer|Long expected'
                if (message.deletedAt != null && message.hasOwnProperty('deletedAt'))
                    if (
                        !$util.isInteger(message.deletedAt) &&
                        !(
                            message.deletedAt &&
                            $util.isInteger(message.deletedAt.low) &&
                            $util.isInteger(message.deletedAt.high)
                        )
                    )
                        return 'deletedAt: integer|Long expected'
                return null
            }

            /**
             * Creates a User message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof users.User
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {users.User} User
             */
            User.fromObject = function fromObject(object) {
                if (object instanceof $root.users.User) return object
                var message = new $root.users.User()
                if (object.id != null) message.id = String(object.id)
                if (object.externalId != null) message.externalId = String(object.externalId)
                if (object.contactId != null) message.contactId = String(object.contactId)
                if (object.createdAt != null)
                    if ($util.Long) (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false
                    else if (typeof object.createdAt === 'string') message.createdAt = parseInt(object.createdAt, 10)
                    else if (typeof object.createdAt === 'number') message.createdAt = object.createdAt
                    else if (typeof object.createdAt === 'object')
                        message.createdAt = new $util.LongBits(
                            object.createdAt.low >>> 0,
                            object.createdAt.high >>> 0
                        ).toNumber()
                if (object.updatedAt != null)
                    if ($util.Long) (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false
                    else if (typeof object.updatedAt === 'string') message.updatedAt = parseInt(object.updatedAt, 10)
                    else if (typeof object.updatedAt === 'number') message.updatedAt = object.updatedAt
                    else if (typeof object.updatedAt === 'object')
                        message.updatedAt = new $util.LongBits(
                            object.updatedAt.low >>> 0,
                            object.updatedAt.high >>> 0
                        ).toNumber()
                if (object.deletedAt != null)
                    if ($util.Long) (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false
                    else if (typeof object.deletedAt === 'string') message.deletedAt = parseInt(object.deletedAt, 10)
                    else if (typeof object.deletedAt === 'number') message.deletedAt = object.deletedAt
                    else if (typeof object.deletedAt === 'object')
                        message.deletedAt = new $util.LongBits(
                            object.deletedAt.low >>> 0,
                            object.deletedAt.high >>> 0
                        ).toNumber()
                return message
            }

            /**
             * Creates a plain object from a User message. Also converts values to other types if specified.
             * @function toObject
             * @memberof users.User
             * @static
             * @param {users.User} message User
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            User.toObject = function toObject(message, options) {
                if (!options) options = {}
                var object = {}
                if (options.defaults) {
                    object.id = ''
                    object.externalId = ''
                    object.contactId = ''
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.createdAt =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.createdAt = options.longs === String ? '0' : 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.updatedAt =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.updatedAt = options.longs === String ? '0' : 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.deletedAt =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.deletedAt = options.longs === String ? '0' : 0
                }
                if (message.id != null && message.hasOwnProperty('id')) object.id = message.id
                if (message.externalId != null && message.hasOwnProperty('externalId'))
                    object.externalId = message.externalId
                if (message.contactId != null && message.hasOwnProperty('contactId'))
                    object.contactId = message.contactId
                if (message.createdAt != null && message.hasOwnProperty('createdAt'))
                    if (typeof message.createdAt === 'number')
                        object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt
                    else
                        object.createdAt =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.createdAt)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.createdAt.low >>> 0,
                                      message.createdAt.high >>> 0
                                  ).toNumber()
                                : message.createdAt
                if (message.updatedAt != null && message.hasOwnProperty('updatedAt'))
                    if (typeof message.updatedAt === 'number')
                        object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt
                    else
                        object.updatedAt =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.updatedAt)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.updatedAt.low >>> 0,
                                      message.updatedAt.high >>> 0
                                  ).toNumber()
                                : message.updatedAt
                if (message.deletedAt != null && message.hasOwnProperty('deletedAt'))
                    if (typeof message.deletedAt === 'number')
                        object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt
                    else
                        object.deletedAt =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.deletedAt)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.deletedAt.low >>> 0,
                                      message.deletedAt.high >>> 0
                                  ).toNumber()
                                : message.deletedAt
                return object
            }

            /**
             * Converts this User to JSON.
             * @function toJSON
             * @memberof users.User
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            User.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
            }

            /**
             * Gets the default type url for User
             * @function getTypeUrl
             * @memberof users.User
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            User.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = 'type.googleapis.com'
                }
                return typeUrlPrefix + '/users.User'
            }

            return User
        })()

        users.UserWithRel = (function () {
            /**
             * Properties of a UserWithRel.
             * @memberof users
             * @interface IUserWithRel
             * @property {users.User} user UserWithRel user
             * @property {contacts.Contact} contact UserWithRel contact
             * @property {Array.<teams.TeamWithRel>|null} [teams] UserWithRel teams
             */

            /**
             * Constructs a new UserWithRel.
             * @memberof users
             * @classdesc Represents a UserWithRel.
             * @implements IUserWithRel
             * @constructor
             * @param {users.IUserWithRel=} [properties] Properties to set
             */
            function UserWithRel(properties) {
                this.teams = []
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
            }

            /**
             * UserWithRel user.
             * @member {users.User} user
             * @memberof users.UserWithRel
             * @instance
             */
            UserWithRel.prototype.user = null

            /**
             * UserWithRel contact.
             * @member {contacts.Contact} contact
             * @memberof users.UserWithRel
             * @instance
             */
            UserWithRel.prototype.contact = null

            /**
             * UserWithRel teams.
             * @member {Array.<teams.TeamWithRel>} teams
             * @memberof users.UserWithRel
             * @instance
             */
            UserWithRel.prototype.teams = $util.emptyArray

            /**
             * Creates a new UserWithRel instance using the specified properties.
             * @function create
             * @memberof users.UserWithRel
             * @static
             * @param {users.IUserWithRel=} [properties] Properties to set
             * @returns {users.UserWithRel} UserWithRel instance
             */
            UserWithRel.create = function create(properties) {
                return new UserWithRel(properties)
            }

            /**
             * Encodes the specified UserWithRel message. Does not implicitly {@link users.UserWithRel.verify|verify} messages.
             * @function encode
             * @memberof users.UserWithRel
             * @static
             * @param {users.UserWithRel} message UserWithRel message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UserWithRel.encode = function encode(message, writer) {
                if (!writer) writer = $Writer.create()
                $root.users.User.encode(message.user, writer.uint32(/* id 1, wireType 2 =*/ 10).fork()).ldelim()
                $root.contacts.Contact.encode(
                    message.contact,
                    writer.uint32(/* id 2, wireType 2 =*/ 18).fork()
                ).ldelim()
                if (message.teams != null && message.teams.length)
                    for (var i = 0; i < message.teams.length; ++i)
                        $root.teams.TeamWithRel.encode(
                            message.teams[i],
                            writer.uint32(/* id 3, wireType 2 =*/ 26).fork()
                        ).ldelim()
                return writer
            }

            /**
             * Encodes the specified UserWithRel message, length delimited. Does not implicitly {@link users.UserWithRel.verify|verify} messages.
             * @function encodeDelimited
             * @memberof users.UserWithRel
             * @static
             * @param {users.UserWithRel} message UserWithRel message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UserWithRel.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim()
            }

            /**
             * Decodes a UserWithRel message from the specified reader or buffer.
             * @function decode
             * @memberof users.UserWithRel
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {users.UserWithRel} UserWithRel
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UserWithRel.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
                var end = length === undefined ? reader.len : reader.pos + length,
                    message = new $root.users.UserWithRel()
                while (reader.pos < end) {
                    var tag = reader.uint32()
                    switch (tag >>> 3) {
                        case 1: {
                            message.user = $root.users.User.decode(reader, reader.uint32())
                            break
                        }
                        case 2: {
                            message.contact = $root.contacts.Contact.decode(reader, reader.uint32())
                            break
                        }
                        case 3: {
                            if (!(message.teams && message.teams.length)) message.teams = []
                            message.teams.push($root.teams.TeamWithRel.decode(reader, reader.uint32()))
                            break
                        }
                        default:
                            reader.skipType(tag & 7)
                            break
                    }
                }
                if (!message.hasOwnProperty('user'))
                    throw $util.ProtocolError("missing required 'user'", { instance: message })
                if (!message.hasOwnProperty('contact'))
                    throw $util.ProtocolError("missing required 'contact'", { instance: message })
                return message
            }

            /**
             * Decodes a UserWithRel message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof users.UserWithRel
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {users.UserWithRel} UserWithRel
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UserWithRel.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader)) reader = new $Reader(reader)
                return this.decode(reader, reader.uint32())
            }

            /**
             * Verifies a UserWithRel message.
             * @function verify
             * @memberof users.UserWithRel
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UserWithRel.verify = function verify(message) {
                if (typeof message !== 'object' || message === null) return 'object expected'
                {
                    var error = $root.users.User.verify(message.user)
                    if (error) return 'user.' + error
                }
                {
                    var error = $root.contacts.Contact.verify(message.contact)
                    if (error) return 'contact.' + error
                }
                if (message.teams != null && message.hasOwnProperty('teams')) {
                    if (!Array.isArray(message.teams)) return 'teams: array expected'
                    for (var i = 0; i < message.teams.length; ++i) {
                        var error = $root.teams.TeamWithRel.verify(message.teams[i])
                        if (error) return 'teams.' + error
                    }
                }
                return null
            }

            /**
             * Creates a UserWithRel message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof users.UserWithRel
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {users.UserWithRel} UserWithRel
             */
            UserWithRel.fromObject = function fromObject(object) {
                if (object instanceof $root.users.UserWithRel) return object
                var message = new $root.users.UserWithRel()
                if (object.user != null) {
                    if (typeof object.user !== 'object') throw TypeError('.users.UserWithRel.user: object expected')
                    message.user = $root.users.User.fromObject(object.user)
                }
                if (object.contact != null) {
                    if (typeof object.contact !== 'object')
                        throw TypeError('.users.UserWithRel.contact: object expected')
                    message.contact = $root.contacts.Contact.fromObject(object.contact)
                }
                if (object.teams) {
                    if (!Array.isArray(object.teams)) throw TypeError('.users.UserWithRel.teams: array expected')
                    message.teams = []
                    for (var i = 0; i < object.teams.length; ++i) {
                        if (typeof object.teams[i] !== 'object')
                            throw TypeError('.users.UserWithRel.teams: object expected')
                        message.teams[i] = $root.teams.TeamWithRel.fromObject(object.teams[i])
                    }
                }
                return message
            }

            /**
             * Creates a plain object from a UserWithRel message. Also converts values to other types if specified.
             * @function toObject
             * @memberof users.UserWithRel
             * @static
             * @param {users.UserWithRel} message UserWithRel
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UserWithRel.toObject = function toObject(message, options) {
                if (!options) options = {}
                var object = {}
                if (options.arrays || options.defaults) object.teams = []
                if (options.defaults) {
                    object.user = null
                    object.contact = null
                }
                if (message.user != null && message.hasOwnProperty('user'))
                    object.user = $root.users.User.toObject(message.user, options)
                if (message.contact != null && message.hasOwnProperty('contact'))
                    object.contact = $root.contacts.Contact.toObject(message.contact, options)
                if (message.teams && message.teams.length) {
                    object.teams = []
                    for (var j = 0; j < message.teams.length; ++j)
                        object.teams[j] = $root.teams.TeamWithRel.toObject(message.teams[j], options)
                }
                return object
            }

            /**
             * Converts this UserWithRel to JSON.
             * @function toJSON
             * @memberof users.UserWithRel
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UserWithRel.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
            }

            /**
             * Gets the default type url for UserWithRel
             * @function getTypeUrl
             * @memberof users.UserWithRel
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UserWithRel.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = 'type.googleapis.com'
                }
                return typeUrlPrefix + '/users.UserWithRel'
            }

            return UserWithRel
        })()

        users.UserWithRoles = (function () {
            /**
             * Properties of a UserWithRoles.
             * @memberof users
             * @interface IUserWithRoles
             * @property {string} id UserWithRoles id
             * @property {string} name UserWithRoles name
             * @property {string} phone UserWithRoles phone
             * @property {Array.<users.Role>|null} [roles] UserWithRoles roles
             * @property {string|null} [status] UserWithRoles status
             */

            /**
             * Constructs a new UserWithRoles.
             * @memberof users
             * @classdesc Represents a UserWithRoles.
             * @implements IUserWithRoles
             * @constructor
             * @param {users.IUserWithRoles=} [properties] Properties to set
             */
            function UserWithRoles(properties) {
                this.roles = []
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
            }

            /**
             * UserWithRoles id.
             * @member {string} id
             * @memberof users.UserWithRoles
             * @instance
             */
            UserWithRoles.prototype.id = ''

            /**
             * UserWithRoles name.
             * @member {string} name
             * @memberof users.UserWithRoles
             * @instance
             */
            UserWithRoles.prototype.name = ''

            /**
             * UserWithRoles phone.
             * @member {string} phone
             * @memberof users.UserWithRoles
             * @instance
             */
            UserWithRoles.prototype.phone = ''

            /**
             * UserWithRoles roles.
             * @member {Array.<users.Role>} roles
             * @memberof users.UserWithRoles
             * @instance
             */
            UserWithRoles.prototype.roles = $util.emptyArray

            /**
             * UserWithRoles status.
             * @member {string} status
             * @memberof users.UserWithRoles
             * @instance
             */
            UserWithRoles.prototype.status = ''

            /**
             * Creates a new UserWithRoles instance using the specified properties.
             * @function create
             * @memberof users.UserWithRoles
             * @static
             * @param {users.IUserWithRoles=} [properties] Properties to set
             * @returns {users.UserWithRoles} UserWithRoles instance
             */
            UserWithRoles.create = function create(properties) {
                return new UserWithRoles(properties)
            }

            /**
             * Encodes the specified UserWithRoles message. Does not implicitly {@link users.UserWithRoles.verify|verify} messages.
             * @function encode
             * @memberof users.UserWithRoles
             * @static
             * @param {users.UserWithRoles} message UserWithRoles message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UserWithRoles.encode = function encode(message, writer) {
                if (!writer) writer = $Writer.create()
                writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.id)
                writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.name)
                writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.phone)
                if (message.roles != null && message.roles.length)
                    for (var i = 0; i < message.roles.length; ++i)
                        writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.roles[i])
                if (message.status != null && Object.hasOwnProperty.call(message, 'status'))
                    writer.uint32(/* id 5, wireType 2 =*/ 42).string(message.status)
                return writer
            }

            /**
             * Encodes the specified UserWithRoles message, length delimited. Does not implicitly {@link users.UserWithRoles.verify|verify} messages.
             * @function encodeDelimited
             * @memberof users.UserWithRoles
             * @static
             * @param {users.UserWithRoles} message UserWithRoles message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UserWithRoles.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim()
            }

            /**
             * Decodes a UserWithRoles message from the specified reader or buffer.
             * @function decode
             * @memberof users.UserWithRoles
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {users.UserWithRoles} UserWithRoles
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UserWithRoles.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
                var end = length === undefined ? reader.len : reader.pos + length,
                    message = new $root.users.UserWithRoles()
                while (reader.pos < end) {
                    var tag = reader.uint32()
                    switch (tag >>> 3) {
                        case 1: {
                            message.id = reader.string()
                            break
                        }
                        case 2: {
                            message.name = reader.string()
                            break
                        }
                        case 3: {
                            message.phone = reader.string()
                            break
                        }
                        case 4: {
                            if (!(message.roles && message.roles.length)) message.roles = []
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos
                                while (reader.pos < end2) message.roles.push(reader.int32())
                            } else message.roles.push(reader.int32())
                            break
                        }
                        case 5: {
                            message.status = reader.string()
                            break
                        }
                        default:
                            reader.skipType(tag & 7)
                            break
                    }
                }
                if (!message.hasOwnProperty('id'))
                    throw $util.ProtocolError("missing required 'id'", { instance: message })
                if (!message.hasOwnProperty('name'))
                    throw $util.ProtocolError("missing required 'name'", { instance: message })
                if (!message.hasOwnProperty('phone'))
                    throw $util.ProtocolError("missing required 'phone'", { instance: message })
                return message
            }

            /**
             * Decodes a UserWithRoles message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof users.UserWithRoles
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {users.UserWithRoles} UserWithRoles
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UserWithRoles.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader)) reader = new $Reader(reader)
                return this.decode(reader, reader.uint32())
            }

            /**
             * Verifies a UserWithRoles message.
             * @function verify
             * @memberof users.UserWithRoles
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UserWithRoles.verify = function verify(message) {
                if (typeof message !== 'object' || message === null) return 'object expected'
                if (!$util.isString(message.id)) return 'id: string expected'
                if (!$util.isString(message.name)) return 'name: string expected'
                if (!$util.isString(message.phone)) return 'phone: string expected'
                if (message.roles != null && message.hasOwnProperty('roles')) {
                    if (!Array.isArray(message.roles)) return 'roles: array expected'
                    for (var i = 0; i < message.roles.length; ++i)
                        switch (message.roles[i]) {
                            default:
                                return 'roles: enum value[] expected'
                            case 0:
                            case 1:
                                break
                        }
                }
                if (message.status != null && message.hasOwnProperty('status'))
                    if (!$util.isString(message.status)) return 'status: string expected'
                return null
            }

            /**
             * Creates a UserWithRoles message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof users.UserWithRoles
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {users.UserWithRoles} UserWithRoles
             */
            UserWithRoles.fromObject = function fromObject(object) {
                if (object instanceof $root.users.UserWithRoles) return object
                var message = new $root.users.UserWithRoles()
                if (object.id != null) message.id = String(object.id)
                if (object.name != null) message.name = String(object.name)
                if (object.phone != null) message.phone = String(object.phone)
                if (object.roles) {
                    if (!Array.isArray(object.roles)) throw TypeError('.users.UserWithRoles.roles: array expected')
                    message.roles = []
                    for (var i = 0; i < object.roles.length; ++i)
                        switch (object.roles[i]) {
                            default:
                            case 'DISPATCHER':
                            case 0:
                                message.roles[i] = 0
                                break
                            case 'COURIER':
                            case 1:
                                message.roles[i] = 1
                                break
                        }
                }
                if (object.status != null) message.status = String(object.status)
                return message
            }

            /**
             * Creates a plain object from a UserWithRoles message. Also converts values to other types if specified.
             * @function toObject
             * @memberof users.UserWithRoles
             * @static
             * @param {users.UserWithRoles} message UserWithRoles
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UserWithRoles.toObject = function toObject(message, options) {
                if (!options) options = {}
                var object = {}
                if (options.arrays || options.defaults) object.roles = []
                if (options.defaults) {
                    object.id = ''
                    object.name = ''
                    object.phone = ''
                    object.status = ''
                }
                if (message.id != null && message.hasOwnProperty('id')) object.id = message.id
                if (message.name != null && message.hasOwnProperty('name')) object.name = message.name
                if (message.phone != null && message.hasOwnProperty('phone')) object.phone = message.phone
                if (message.roles && message.roles.length) {
                    object.roles = []
                    for (var j = 0; j < message.roles.length; ++j)
                        object.roles[j] =
                            options.enums === String ? $root.users.Role[message.roles[j]] : message.roles[j]
                }
                if (message.status != null && message.hasOwnProperty('status')) object.status = message.status
                return object
            }

            /**
             * Converts this UserWithRoles to JSON.
             * @function toJSON
             * @memberof users.UserWithRoles
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UserWithRoles.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
            }

            /**
             * Gets the default type url for UserWithRoles
             * @function getTypeUrl
             * @memberof users.UserWithRoles
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UserWithRoles.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = 'type.googleapis.com'
                }
                return typeUrlPrefix + '/users.UserWithRoles'
            }

            return UserWithRoles
        })()

        return users
    })()

    $root.contacts = (function () {
        /**
         * Namespace contacts.
         * @exports contacts
         * @namespace
         */
        var contacts = {}

        contacts.Contact = (function () {
            /**
             * Properties of a Contact.
             * @memberof contacts
             * @interface IContact
             * @property {string} id Contact id
             * @property {string} name Contact name
             * @property {string} phone Contact phone
             * @property {string|null} [externalId] Contact externalId
             * @property {number} createdAt Contact createdAt
             * @property {number|null} [updatedAt] Contact updatedAt
             * @property {number|null} [deletedAt] Contact deletedAt
             */

            /**
             * Constructs a new Contact.
             * @memberof contacts
             * @classdesc Represents a Contact.
             * @implements IContact
             * @constructor
             * @param {contacts.IContact=} [properties] Properties to set
             */
            function Contact(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
            }

            /**
             * Contact id.
             * @member {string} id
             * @memberof contacts.Contact
             * @instance
             */
            Contact.prototype.id = ''

            /**
             * Contact name.
             * @member {string} name
             * @memberof contacts.Contact
             * @instance
             */
            Contact.prototype.name = ''

            /**
             * Contact phone.
             * @member {string} phone
             * @memberof contacts.Contact
             * @instance
             */
            Contact.prototype.phone = ''

            /**
             * Contact externalId.
             * @member {string} externalId
             * @memberof contacts.Contact
             * @instance
             */
            Contact.prototype.externalId = ''

            /**
             * Contact createdAt.
             * @member {number} createdAt
             * @memberof contacts.Contact
             * @instance
             */
            Contact.prototype.createdAt = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Contact updatedAt.
             * @member {number} updatedAt
             * @memberof contacts.Contact
             * @instance
             */
            Contact.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Contact deletedAt.
             * @member {number} deletedAt
             * @memberof contacts.Contact
             * @instance
             */
            Contact.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Creates a new Contact instance using the specified properties.
             * @function create
             * @memberof contacts.Contact
             * @static
             * @param {contacts.IContact=} [properties] Properties to set
             * @returns {contacts.Contact} Contact instance
             */
            Contact.create = function create(properties) {
                return new Contact(properties)
            }

            /**
             * Encodes the specified Contact message. Does not implicitly {@link contacts.Contact.verify|verify} messages.
             * @function encode
             * @memberof contacts.Contact
             * @static
             * @param {contacts.Contact} message Contact message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Contact.encode = function encode(message, writer) {
                if (!writer) writer = $Writer.create()
                writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.id)
                writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.name)
                writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.phone)
                if (message.externalId != null && Object.hasOwnProperty.call(message, 'externalId'))
                    writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.externalId)
                writer.uint32(/* id 5, wireType 0 =*/ 40).int64(message.createdAt)
                if (message.updatedAt != null && Object.hasOwnProperty.call(message, 'updatedAt'))
                    writer.uint32(/* id 6, wireType 0 =*/ 48).int64(message.updatedAt)
                if (message.deletedAt != null && Object.hasOwnProperty.call(message, 'deletedAt'))
                    writer.uint32(/* id 7, wireType 0 =*/ 56).int64(message.deletedAt)
                return writer
            }

            /**
             * Encodes the specified Contact message, length delimited. Does not implicitly {@link contacts.Contact.verify|verify} messages.
             * @function encodeDelimited
             * @memberof contacts.Contact
             * @static
             * @param {contacts.Contact} message Contact message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Contact.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim()
            }

            /**
             * Decodes a Contact message from the specified reader or buffer.
             * @function decode
             * @memberof contacts.Contact
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {contacts.Contact} Contact
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Contact.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
                var end = length === undefined ? reader.len : reader.pos + length,
                    message = new $root.contacts.Contact()
                while (reader.pos < end) {
                    var tag = reader.uint32()
                    switch (tag >>> 3) {
                        case 1: {
                            message.id = reader.string()
                            break
                        }
                        case 2: {
                            message.name = reader.string()
                            break
                        }
                        case 3: {
                            message.phone = reader.string()
                            break
                        }
                        case 4: {
                            message.externalId = reader.string()
                            break
                        }
                        case 5: {
                            message.createdAt = reader.int64()
                            break
                        }
                        case 6: {
                            message.updatedAt = reader.int64()
                            break
                        }
                        case 7: {
                            message.deletedAt = reader.int64()
                            break
                        }
                        default:
                            reader.skipType(tag & 7)
                            break
                    }
                }
                if (!message.hasOwnProperty('id'))
                    throw $util.ProtocolError("missing required 'id'", { instance: message })
                if (!message.hasOwnProperty('name'))
                    throw $util.ProtocolError("missing required 'name'", { instance: message })
                if (!message.hasOwnProperty('phone'))
                    throw $util.ProtocolError("missing required 'phone'", { instance: message })
                if (!message.hasOwnProperty('createdAt'))
                    throw $util.ProtocolError("missing required 'createdAt'", { instance: message })
                return message
            }

            /**
             * Decodes a Contact message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof contacts.Contact
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {contacts.Contact} Contact
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Contact.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader)) reader = new $Reader(reader)
                return this.decode(reader, reader.uint32())
            }

            /**
             * Verifies a Contact message.
             * @function verify
             * @memberof contacts.Contact
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Contact.verify = function verify(message) {
                if (typeof message !== 'object' || message === null) return 'object expected'
                if (!$util.isString(message.id)) return 'id: string expected'
                if (!$util.isString(message.name)) return 'name: string expected'
                if (!$util.isString(message.phone)) return 'phone: string expected'
                if (message.externalId != null && message.hasOwnProperty('externalId'))
                    if (!$util.isString(message.externalId)) return 'externalId: string expected'
                if (
                    !$util.isInteger(message.createdAt) &&
                    !(
                        message.createdAt &&
                        $util.isInteger(message.createdAt.low) &&
                        $util.isInteger(message.createdAt.high)
                    )
                )
                    return 'createdAt: integer|Long expected'
                if (message.updatedAt != null && message.hasOwnProperty('updatedAt'))
                    if (
                        !$util.isInteger(message.updatedAt) &&
                        !(
                            message.updatedAt &&
                            $util.isInteger(message.updatedAt.low) &&
                            $util.isInteger(message.updatedAt.high)
                        )
                    )
                        return 'updatedAt: integer|Long expected'
                if (message.deletedAt != null && message.hasOwnProperty('deletedAt'))
                    if (
                        !$util.isInteger(message.deletedAt) &&
                        !(
                            message.deletedAt &&
                            $util.isInteger(message.deletedAt.low) &&
                            $util.isInteger(message.deletedAt.high)
                        )
                    )
                        return 'deletedAt: integer|Long expected'
                return null
            }

            /**
             * Creates a Contact message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof contacts.Contact
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {contacts.Contact} Contact
             */
            Contact.fromObject = function fromObject(object) {
                if (object instanceof $root.contacts.Contact) return object
                var message = new $root.contacts.Contact()
                if (object.id != null) message.id = String(object.id)
                if (object.name != null) message.name = String(object.name)
                if (object.phone != null) message.phone = String(object.phone)
                if (object.externalId != null) message.externalId = String(object.externalId)
                if (object.createdAt != null)
                    if ($util.Long) (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false
                    else if (typeof object.createdAt === 'string') message.createdAt = parseInt(object.createdAt, 10)
                    else if (typeof object.createdAt === 'number') message.createdAt = object.createdAt
                    else if (typeof object.createdAt === 'object')
                        message.createdAt = new $util.LongBits(
                            object.createdAt.low >>> 0,
                            object.createdAt.high >>> 0
                        ).toNumber()
                if (object.updatedAt != null)
                    if ($util.Long) (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false
                    else if (typeof object.updatedAt === 'string') message.updatedAt = parseInt(object.updatedAt, 10)
                    else if (typeof object.updatedAt === 'number') message.updatedAt = object.updatedAt
                    else if (typeof object.updatedAt === 'object')
                        message.updatedAt = new $util.LongBits(
                            object.updatedAt.low >>> 0,
                            object.updatedAt.high >>> 0
                        ).toNumber()
                if (object.deletedAt != null)
                    if ($util.Long) (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false
                    else if (typeof object.deletedAt === 'string') message.deletedAt = parseInt(object.deletedAt, 10)
                    else if (typeof object.deletedAt === 'number') message.deletedAt = object.deletedAt
                    else if (typeof object.deletedAt === 'object')
                        message.deletedAt = new $util.LongBits(
                            object.deletedAt.low >>> 0,
                            object.deletedAt.high >>> 0
                        ).toNumber()
                return message
            }

            /**
             * Creates a plain object from a Contact message. Also converts values to other types if specified.
             * @function toObject
             * @memberof contacts.Contact
             * @static
             * @param {contacts.Contact} message Contact
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Contact.toObject = function toObject(message, options) {
                if (!options) options = {}
                var object = {}
                if (options.defaults) {
                    object.id = ''
                    object.name = ''
                    object.phone = ''
                    object.externalId = ''
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.createdAt =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.createdAt = options.longs === String ? '0' : 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.updatedAt =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.updatedAt = options.longs === String ? '0' : 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.deletedAt =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.deletedAt = options.longs === String ? '0' : 0
                }
                if (message.id != null && message.hasOwnProperty('id')) object.id = message.id
                if (message.name != null && message.hasOwnProperty('name')) object.name = message.name
                if (message.phone != null && message.hasOwnProperty('phone')) object.phone = message.phone
                if (message.externalId != null && message.hasOwnProperty('externalId'))
                    object.externalId = message.externalId
                if (message.createdAt != null && message.hasOwnProperty('createdAt'))
                    if (typeof message.createdAt === 'number')
                        object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt
                    else
                        object.createdAt =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.createdAt)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.createdAt.low >>> 0,
                                      message.createdAt.high >>> 0
                                  ).toNumber()
                                : message.createdAt
                if (message.updatedAt != null && message.hasOwnProperty('updatedAt'))
                    if (typeof message.updatedAt === 'number')
                        object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt
                    else
                        object.updatedAt =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.updatedAt)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.updatedAt.low >>> 0,
                                      message.updatedAt.high >>> 0
                                  ).toNumber()
                                : message.updatedAt
                if (message.deletedAt != null && message.hasOwnProperty('deletedAt'))
                    if (typeof message.deletedAt === 'number')
                        object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt
                    else
                        object.deletedAt =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.deletedAt)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.deletedAt.low >>> 0,
                                      message.deletedAt.high >>> 0
                                  ).toNumber()
                                : message.deletedAt
                return object
            }

            /**
             * Converts this Contact to JSON.
             * @function toJSON
             * @memberof contacts.Contact
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Contact.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
            }

            /**
             * Gets the default type url for Contact
             * @function getTypeUrl
             * @memberof contacts.Contact
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Contact.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = 'type.googleapis.com'
                }
                return typeUrlPrefix + '/contacts.Contact'
            }

            return Contact
        })()

        contacts.UpsertContact = (function () {
            /**
             * Properties of an UpsertContact.
             * @memberof contacts
             * @interface IUpsertContact
             * @property {string} name UpsertContact name
             * @property {string} phone UpsertContact phone
             * @property {string} externalId UpsertContact externalId
             */

            /**
             * Constructs a new UpsertContact.
             * @memberof contacts
             * @classdesc Represents an UpsertContact.
             * @implements IUpsertContact
             * @constructor
             * @param {contacts.IUpsertContact=} [properties] Properties to set
             */
            function UpsertContact(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
            }

            /**
             * UpsertContact name.
             * @member {string} name
             * @memberof contacts.UpsertContact
             * @instance
             */
            UpsertContact.prototype.name = ''

            /**
             * UpsertContact phone.
             * @member {string} phone
             * @memberof contacts.UpsertContact
             * @instance
             */
            UpsertContact.prototype.phone = ''

            /**
             * UpsertContact externalId.
             * @member {string} externalId
             * @memberof contacts.UpsertContact
             * @instance
             */
            UpsertContact.prototype.externalId = ''

            /**
             * Creates a new UpsertContact instance using the specified properties.
             * @function create
             * @memberof contacts.UpsertContact
             * @static
             * @param {contacts.IUpsertContact=} [properties] Properties to set
             * @returns {contacts.UpsertContact} UpsertContact instance
             */
            UpsertContact.create = function create(properties) {
                return new UpsertContact(properties)
            }

            /**
             * Encodes the specified UpsertContact message. Does not implicitly {@link contacts.UpsertContact.verify|verify} messages.
             * @function encode
             * @memberof contacts.UpsertContact
             * @static
             * @param {contacts.UpsertContact} message UpsertContact message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpsertContact.encode = function encode(message, writer) {
                if (!writer) writer = $Writer.create()
                writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.name)
                writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.phone)
                writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.externalId)
                return writer
            }

            /**
             * Encodes the specified UpsertContact message, length delimited. Does not implicitly {@link contacts.UpsertContact.verify|verify} messages.
             * @function encodeDelimited
             * @memberof contacts.UpsertContact
             * @static
             * @param {contacts.UpsertContact} message UpsertContact message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpsertContact.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim()
            }

            /**
             * Decodes an UpsertContact message from the specified reader or buffer.
             * @function decode
             * @memberof contacts.UpsertContact
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {contacts.UpsertContact} UpsertContact
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpsertContact.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
                var end = length === undefined ? reader.len : reader.pos + length,
                    message = new $root.contacts.UpsertContact()
                while (reader.pos < end) {
                    var tag = reader.uint32()
                    switch (tag >>> 3) {
                        case 1: {
                            message.name = reader.string()
                            break
                        }
                        case 2: {
                            message.phone = reader.string()
                            break
                        }
                        case 3: {
                            message.externalId = reader.string()
                            break
                        }
                        default:
                            reader.skipType(tag & 7)
                            break
                    }
                }
                if (!message.hasOwnProperty('name'))
                    throw $util.ProtocolError("missing required 'name'", { instance: message })
                if (!message.hasOwnProperty('phone'))
                    throw $util.ProtocolError("missing required 'phone'", { instance: message })
                if (!message.hasOwnProperty('externalId'))
                    throw $util.ProtocolError("missing required 'externalId'", { instance: message })
                return message
            }

            /**
             * Decodes an UpsertContact message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof contacts.UpsertContact
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {contacts.UpsertContact} UpsertContact
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpsertContact.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader)) reader = new $Reader(reader)
                return this.decode(reader, reader.uint32())
            }

            /**
             * Verifies an UpsertContact message.
             * @function verify
             * @memberof contacts.UpsertContact
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpsertContact.verify = function verify(message) {
                if (typeof message !== 'object' || message === null) return 'object expected'
                if (!$util.isString(message.name)) return 'name: string expected'
                if (!$util.isString(message.phone)) return 'phone: string expected'
                if (!$util.isString(message.externalId)) return 'externalId: string expected'
                return null
            }

            /**
             * Creates an UpsertContact message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof contacts.UpsertContact
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {contacts.UpsertContact} UpsertContact
             */
            UpsertContact.fromObject = function fromObject(object) {
                if (object instanceof $root.contacts.UpsertContact) return object
                var message = new $root.contacts.UpsertContact()
                if (object.name != null) message.name = String(object.name)
                if (object.phone != null) message.phone = String(object.phone)
                if (object.externalId != null) message.externalId = String(object.externalId)
                return message
            }

            /**
             * Creates a plain object from an UpsertContact message. Also converts values to other types if specified.
             * @function toObject
             * @memberof contacts.UpsertContact
             * @static
             * @param {contacts.UpsertContact} message UpsertContact
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpsertContact.toObject = function toObject(message, options) {
                if (!options) options = {}
                var object = {}
                if (options.defaults) {
                    object.name = ''
                    object.phone = ''
                    object.externalId = ''
                }
                if (message.name != null && message.hasOwnProperty('name')) object.name = message.name
                if (message.phone != null && message.hasOwnProperty('phone')) object.phone = message.phone
                if (message.externalId != null && message.hasOwnProperty('externalId'))
                    object.externalId = message.externalId
                return object
            }

            /**
             * Converts this UpsertContact to JSON.
             * @function toJSON
             * @memberof contacts.UpsertContact
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpsertContact.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
            }

            /**
             * Gets the default type url for UpsertContact
             * @function getTypeUrl
             * @memberof contacts.UpsertContact
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpsertContact.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = 'type.googleapis.com'
                }
                return typeUrlPrefix + '/contacts.UpsertContact'
            }

            return UpsertContact
        })()

        return contacts
    })()

    $root.teams = (function () {
        /**
         * Namespace teams.
         * @exports teams
         * @namespace
         */
        var teams = {}

        teams.Team = (function () {
            /**
             * Properties of a Team.
             * @memberof teams
             * @interface ITeam
             * @property {string} id Team id
             * @property {string} name Team name
             * @property {string|null} [externalId] Team externalId
             * @property {number} createdAt Team createdAt
             * @property {number|null} [updatedAt] Team updatedAt
             * @property {number|null} [deletedAt] Team deletedAt
             */

            /**
             * Constructs a new Team.
             * @memberof teams
             * @classdesc Represents a Team.
             * @implements ITeam
             * @constructor
             * @param {teams.ITeam=} [properties] Properties to set
             */
            function Team(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
            }

            /**
             * Team id.
             * @member {string} id
             * @memberof teams.Team
             * @instance
             */
            Team.prototype.id = ''

            /**
             * Team name.
             * @member {string} name
             * @memberof teams.Team
             * @instance
             */
            Team.prototype.name = ''

            /**
             * Team externalId.
             * @member {string} externalId
             * @memberof teams.Team
             * @instance
             */
            Team.prototype.externalId = ''

            /**
             * Team createdAt.
             * @member {number} createdAt
             * @memberof teams.Team
             * @instance
             */
            Team.prototype.createdAt = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Team updatedAt.
             * @member {number} updatedAt
             * @memberof teams.Team
             * @instance
             */
            Team.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Team deletedAt.
             * @member {number} deletedAt
             * @memberof teams.Team
             * @instance
             */
            Team.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Creates a new Team instance using the specified properties.
             * @function create
             * @memberof teams.Team
             * @static
             * @param {teams.ITeam=} [properties] Properties to set
             * @returns {teams.Team} Team instance
             */
            Team.create = function create(properties) {
                return new Team(properties)
            }

            /**
             * Encodes the specified Team message. Does not implicitly {@link teams.Team.verify|verify} messages.
             * @function encode
             * @memberof teams.Team
             * @static
             * @param {teams.Team} message Team message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Team.encode = function encode(message, writer) {
                if (!writer) writer = $Writer.create()
                writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.id)
                writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.name)
                if (message.externalId != null && Object.hasOwnProperty.call(message, 'externalId'))
                    writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.externalId)
                writer.uint32(/* id 4, wireType 0 =*/ 32).int64(message.createdAt)
                if (message.updatedAt != null && Object.hasOwnProperty.call(message, 'updatedAt'))
                    writer.uint32(/* id 5, wireType 0 =*/ 40).int64(message.updatedAt)
                if (message.deletedAt != null && Object.hasOwnProperty.call(message, 'deletedAt'))
                    writer.uint32(/* id 6, wireType 0 =*/ 48).int64(message.deletedAt)
                return writer
            }

            /**
             * Encodes the specified Team message, length delimited. Does not implicitly {@link teams.Team.verify|verify} messages.
             * @function encodeDelimited
             * @memberof teams.Team
             * @static
             * @param {teams.Team} message Team message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Team.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim()
            }

            /**
             * Decodes a Team message from the specified reader or buffer.
             * @function decode
             * @memberof teams.Team
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {teams.Team} Team
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Team.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
                var end = length === undefined ? reader.len : reader.pos + length,
                    message = new $root.teams.Team()
                while (reader.pos < end) {
                    var tag = reader.uint32()
                    switch (tag >>> 3) {
                        case 1: {
                            message.id = reader.string()
                            break
                        }
                        case 2: {
                            message.name = reader.string()
                            break
                        }
                        case 3: {
                            message.externalId = reader.string()
                            break
                        }
                        case 4: {
                            message.createdAt = reader.int64()
                            break
                        }
                        case 5: {
                            message.updatedAt = reader.int64()
                            break
                        }
                        case 6: {
                            message.deletedAt = reader.int64()
                            break
                        }
                        default:
                            reader.skipType(tag & 7)
                            break
                    }
                }
                if (!message.hasOwnProperty('id'))
                    throw $util.ProtocolError("missing required 'id'", { instance: message })
                if (!message.hasOwnProperty('name'))
                    throw $util.ProtocolError("missing required 'name'", { instance: message })
                if (!message.hasOwnProperty('createdAt'))
                    throw $util.ProtocolError("missing required 'createdAt'", { instance: message })
                return message
            }

            /**
             * Decodes a Team message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof teams.Team
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {teams.Team} Team
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Team.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader)) reader = new $Reader(reader)
                return this.decode(reader, reader.uint32())
            }

            /**
             * Verifies a Team message.
             * @function verify
             * @memberof teams.Team
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Team.verify = function verify(message) {
                if (typeof message !== 'object' || message === null) return 'object expected'
                if (!$util.isString(message.id)) return 'id: string expected'
                if (!$util.isString(message.name)) return 'name: string expected'
                if (message.externalId != null && message.hasOwnProperty('externalId'))
                    if (!$util.isString(message.externalId)) return 'externalId: string expected'
                if (
                    !$util.isInteger(message.createdAt) &&
                    !(
                        message.createdAt &&
                        $util.isInteger(message.createdAt.low) &&
                        $util.isInteger(message.createdAt.high)
                    )
                )
                    return 'createdAt: integer|Long expected'
                if (message.updatedAt != null && message.hasOwnProperty('updatedAt'))
                    if (
                        !$util.isInteger(message.updatedAt) &&
                        !(
                            message.updatedAt &&
                            $util.isInteger(message.updatedAt.low) &&
                            $util.isInteger(message.updatedAt.high)
                        )
                    )
                        return 'updatedAt: integer|Long expected'
                if (message.deletedAt != null && message.hasOwnProperty('deletedAt'))
                    if (
                        !$util.isInteger(message.deletedAt) &&
                        !(
                            message.deletedAt &&
                            $util.isInteger(message.deletedAt.low) &&
                            $util.isInteger(message.deletedAt.high)
                        )
                    )
                        return 'deletedAt: integer|Long expected'
                return null
            }

            /**
             * Creates a Team message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof teams.Team
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {teams.Team} Team
             */
            Team.fromObject = function fromObject(object) {
                if (object instanceof $root.teams.Team) return object
                var message = new $root.teams.Team()
                if (object.id != null) message.id = String(object.id)
                if (object.name != null) message.name = String(object.name)
                if (object.externalId != null) message.externalId = String(object.externalId)
                if (object.createdAt != null)
                    if ($util.Long) (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false
                    else if (typeof object.createdAt === 'string') message.createdAt = parseInt(object.createdAt, 10)
                    else if (typeof object.createdAt === 'number') message.createdAt = object.createdAt
                    else if (typeof object.createdAt === 'object')
                        message.createdAt = new $util.LongBits(
                            object.createdAt.low >>> 0,
                            object.createdAt.high >>> 0
                        ).toNumber()
                if (object.updatedAt != null)
                    if ($util.Long) (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false
                    else if (typeof object.updatedAt === 'string') message.updatedAt = parseInt(object.updatedAt, 10)
                    else if (typeof object.updatedAt === 'number') message.updatedAt = object.updatedAt
                    else if (typeof object.updatedAt === 'object')
                        message.updatedAt = new $util.LongBits(
                            object.updatedAt.low >>> 0,
                            object.updatedAt.high >>> 0
                        ).toNumber()
                if (object.deletedAt != null)
                    if ($util.Long) (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false
                    else if (typeof object.deletedAt === 'string') message.deletedAt = parseInt(object.deletedAt, 10)
                    else if (typeof object.deletedAt === 'number') message.deletedAt = object.deletedAt
                    else if (typeof object.deletedAt === 'object')
                        message.deletedAt = new $util.LongBits(
                            object.deletedAt.low >>> 0,
                            object.deletedAt.high >>> 0
                        ).toNumber()
                return message
            }

            /**
             * Creates a plain object from a Team message. Also converts values to other types if specified.
             * @function toObject
             * @memberof teams.Team
             * @static
             * @param {teams.Team} message Team
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Team.toObject = function toObject(message, options) {
                if (!options) options = {}
                var object = {}
                if (options.defaults) {
                    object.id = ''
                    object.name = ''
                    object.externalId = ''
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.createdAt =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.createdAt = options.longs === String ? '0' : 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.updatedAt =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.updatedAt = options.longs === String ? '0' : 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.deletedAt =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.deletedAt = options.longs === String ? '0' : 0
                }
                if (message.id != null && message.hasOwnProperty('id')) object.id = message.id
                if (message.name != null && message.hasOwnProperty('name')) object.name = message.name
                if (message.externalId != null && message.hasOwnProperty('externalId'))
                    object.externalId = message.externalId
                if (message.createdAt != null && message.hasOwnProperty('createdAt'))
                    if (typeof message.createdAt === 'number')
                        object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt
                    else
                        object.createdAt =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.createdAt)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.createdAt.low >>> 0,
                                      message.createdAt.high >>> 0
                                  ).toNumber()
                                : message.createdAt
                if (message.updatedAt != null && message.hasOwnProperty('updatedAt'))
                    if (typeof message.updatedAt === 'number')
                        object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt
                    else
                        object.updatedAt =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.updatedAt)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.updatedAt.low >>> 0,
                                      message.updatedAt.high >>> 0
                                  ).toNumber()
                                : message.updatedAt
                if (message.deletedAt != null && message.hasOwnProperty('deletedAt'))
                    if (typeof message.deletedAt === 'number')
                        object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt
                    else
                        object.deletedAt =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.deletedAt)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.deletedAt.low >>> 0,
                                      message.deletedAt.high >>> 0
                                  ).toNumber()
                                : message.deletedAt
                return object
            }

            /**
             * Converts this Team to JSON.
             * @function toJSON
             * @memberof teams.Team
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Team.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
            }

            /**
             * Gets the default type url for Team
             * @function getTypeUrl
             * @memberof teams.Team
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Team.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = 'type.googleapis.com'
                }
                return typeUrlPrefix + '/teams.Team'
            }

            return Team
        })()

        teams.TeamWithRel = (function () {
            /**
             * Properties of a TeamWithRel.
             * @memberof teams
             * @interface ITeamWithRel
             * @property {teams.Team} team TeamWithRel team
             * @property {Array.<warehouses.Warehouse>|null} [warehouses] TeamWithRel warehouses
             */

            /**
             * Constructs a new TeamWithRel.
             * @memberof teams
             * @classdesc Represents a TeamWithRel.
             * @implements ITeamWithRel
             * @constructor
             * @param {teams.ITeamWithRel=} [properties] Properties to set
             */
            function TeamWithRel(properties) {
                this.warehouses = []
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
            }

            /**
             * TeamWithRel team.
             * @member {teams.Team} team
             * @memberof teams.TeamWithRel
             * @instance
             */
            TeamWithRel.prototype.team = null

            /**
             * TeamWithRel warehouses.
             * @member {Array.<warehouses.Warehouse>} warehouses
             * @memberof teams.TeamWithRel
             * @instance
             */
            TeamWithRel.prototype.warehouses = $util.emptyArray

            /**
             * Creates a new TeamWithRel instance using the specified properties.
             * @function create
             * @memberof teams.TeamWithRel
             * @static
             * @param {teams.ITeamWithRel=} [properties] Properties to set
             * @returns {teams.TeamWithRel} TeamWithRel instance
             */
            TeamWithRel.create = function create(properties) {
                return new TeamWithRel(properties)
            }

            /**
             * Encodes the specified TeamWithRel message. Does not implicitly {@link teams.TeamWithRel.verify|verify} messages.
             * @function encode
             * @memberof teams.TeamWithRel
             * @static
             * @param {teams.TeamWithRel} message TeamWithRel message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TeamWithRel.encode = function encode(message, writer) {
                if (!writer) writer = $Writer.create()
                $root.teams.Team.encode(message.team, writer.uint32(/* id 1, wireType 2 =*/ 10).fork()).ldelim()
                if (message.warehouses != null && message.warehouses.length)
                    for (var i = 0; i < message.warehouses.length; ++i)
                        $root.warehouses.Warehouse.encode(
                            message.warehouses[i],
                            writer.uint32(/* id 2, wireType 2 =*/ 18).fork()
                        ).ldelim()
                return writer
            }

            /**
             * Encodes the specified TeamWithRel message, length delimited. Does not implicitly {@link teams.TeamWithRel.verify|verify} messages.
             * @function encodeDelimited
             * @memberof teams.TeamWithRel
             * @static
             * @param {teams.TeamWithRel} message TeamWithRel message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TeamWithRel.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim()
            }

            /**
             * Decodes a TeamWithRel message from the specified reader or buffer.
             * @function decode
             * @memberof teams.TeamWithRel
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {teams.TeamWithRel} TeamWithRel
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TeamWithRel.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
                var end = length === undefined ? reader.len : reader.pos + length,
                    message = new $root.teams.TeamWithRel()
                while (reader.pos < end) {
                    var tag = reader.uint32()
                    switch (tag >>> 3) {
                        case 1: {
                            message.team = $root.teams.Team.decode(reader, reader.uint32())
                            break
                        }
                        case 2: {
                            if (!(message.warehouses && message.warehouses.length)) message.warehouses = []
                            message.warehouses.push($root.warehouses.Warehouse.decode(reader, reader.uint32()))
                            break
                        }
                        default:
                            reader.skipType(tag & 7)
                            break
                    }
                }
                if (!message.hasOwnProperty('team'))
                    throw $util.ProtocolError("missing required 'team'", { instance: message })
                return message
            }

            /**
             * Decodes a TeamWithRel message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof teams.TeamWithRel
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {teams.TeamWithRel} TeamWithRel
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TeamWithRel.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader)) reader = new $Reader(reader)
                return this.decode(reader, reader.uint32())
            }

            /**
             * Verifies a TeamWithRel message.
             * @function verify
             * @memberof teams.TeamWithRel
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TeamWithRel.verify = function verify(message) {
                if (typeof message !== 'object' || message === null) return 'object expected'
                {
                    var error = $root.teams.Team.verify(message.team)
                    if (error) return 'team.' + error
                }
                if (message.warehouses != null && message.hasOwnProperty('warehouses')) {
                    if (!Array.isArray(message.warehouses)) return 'warehouses: array expected'
                    for (var i = 0; i < message.warehouses.length; ++i) {
                        var error = $root.warehouses.Warehouse.verify(message.warehouses[i])
                        if (error) return 'warehouses.' + error
                    }
                }
                return null
            }

            /**
             * Creates a TeamWithRel message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof teams.TeamWithRel
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {teams.TeamWithRel} TeamWithRel
             */
            TeamWithRel.fromObject = function fromObject(object) {
                if (object instanceof $root.teams.TeamWithRel) return object
                var message = new $root.teams.TeamWithRel()
                if (object.team != null) {
                    if (typeof object.team !== 'object') throw TypeError('.teams.TeamWithRel.team: object expected')
                    message.team = $root.teams.Team.fromObject(object.team)
                }
                if (object.warehouses) {
                    if (!Array.isArray(object.warehouses))
                        throw TypeError('.teams.TeamWithRel.warehouses: array expected')
                    message.warehouses = []
                    for (var i = 0; i < object.warehouses.length; ++i) {
                        if (typeof object.warehouses[i] !== 'object')
                            throw TypeError('.teams.TeamWithRel.warehouses: object expected')
                        message.warehouses[i] = $root.warehouses.Warehouse.fromObject(object.warehouses[i])
                    }
                }
                return message
            }

            /**
             * Creates a plain object from a TeamWithRel message. Also converts values to other types if specified.
             * @function toObject
             * @memberof teams.TeamWithRel
             * @static
             * @param {teams.TeamWithRel} message TeamWithRel
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TeamWithRel.toObject = function toObject(message, options) {
                if (!options) options = {}
                var object = {}
                if (options.arrays || options.defaults) object.warehouses = []
                if (options.defaults) object.team = null
                if (message.team != null && message.hasOwnProperty('team'))
                    object.team = $root.teams.Team.toObject(message.team, options)
                if (message.warehouses && message.warehouses.length) {
                    object.warehouses = []
                    for (var j = 0; j < message.warehouses.length; ++j)
                        object.warehouses[j] = $root.warehouses.Warehouse.toObject(message.warehouses[j], options)
                }
                return object
            }

            /**
             * Converts this TeamWithRel to JSON.
             * @function toJSON
             * @memberof teams.TeamWithRel
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TeamWithRel.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
            }

            /**
             * Gets the default type url for TeamWithRel
             * @function getTypeUrl
             * @memberof teams.TeamWithRel
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TeamWithRel.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = 'type.googleapis.com'
                }
                return typeUrlPrefix + '/teams.TeamWithRel'
            }

            return TeamWithRel
        })()

        return teams
    })()

    $root.warehouses = (function () {
        /**
         * Namespace warehouses.
         * @exports warehouses
         * @namespace
         */
        var warehouses = {}

        warehouses.Warehouse = (function () {
            /**
             * Properties of a Warehouse.
             * @memberof warehouses
             * @interface IWarehouse
             * @property {string} id Warehouse id
             * @property {string} name Warehouse name
             * @property {string} barcode Warehouse barcode
             * @property {string} externalId Warehouse externalId
             * @property {string} addressId Warehouse addressId
             * @property {string} contactId Warehouse contactId
             * @property {number} createdAt Warehouse createdAt
             * @property {number|null} [updatedAt] Warehouse updatedAt
             * @property {number|null} [deletedAt] Warehouse deletedAt
             */

            /**
             * Constructs a new Warehouse.
             * @memberof warehouses
             * @classdesc Represents a Warehouse.
             * @implements IWarehouse
             * @constructor
             * @param {warehouses.IWarehouse=} [properties] Properties to set
             */
            function Warehouse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
            }

            /**
             * Warehouse id.
             * @member {string} id
             * @memberof warehouses.Warehouse
             * @instance
             */
            Warehouse.prototype.id = ''

            /**
             * Warehouse name.
             * @member {string} name
             * @memberof warehouses.Warehouse
             * @instance
             */
            Warehouse.prototype.name = ''

            /**
             * Warehouse barcode.
             * @member {string} barcode
             * @memberof warehouses.Warehouse
             * @instance
             */
            Warehouse.prototype.barcode = ''

            /**
             * Warehouse externalId.
             * @member {string} externalId
             * @memberof warehouses.Warehouse
             * @instance
             */
            Warehouse.prototype.externalId = ''

            /**
             * Warehouse addressId.
             * @member {string} addressId
             * @memberof warehouses.Warehouse
             * @instance
             */
            Warehouse.prototype.addressId = ''

            /**
             * Warehouse contactId.
             * @member {string} contactId
             * @memberof warehouses.Warehouse
             * @instance
             */
            Warehouse.prototype.contactId = ''

            /**
             * Warehouse createdAt.
             * @member {number} createdAt
             * @memberof warehouses.Warehouse
             * @instance
             */
            Warehouse.prototype.createdAt = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Warehouse updatedAt.
             * @member {number} updatedAt
             * @memberof warehouses.Warehouse
             * @instance
             */
            Warehouse.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Warehouse deletedAt.
             * @member {number} deletedAt
             * @memberof warehouses.Warehouse
             * @instance
             */
            Warehouse.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Creates a new Warehouse instance using the specified properties.
             * @function create
             * @memberof warehouses.Warehouse
             * @static
             * @param {warehouses.IWarehouse=} [properties] Properties to set
             * @returns {warehouses.Warehouse} Warehouse instance
             */
            Warehouse.create = function create(properties) {
                return new Warehouse(properties)
            }

            /**
             * Encodes the specified Warehouse message. Does not implicitly {@link warehouses.Warehouse.verify|verify} messages.
             * @function encode
             * @memberof warehouses.Warehouse
             * @static
             * @param {warehouses.Warehouse} message Warehouse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Warehouse.encode = function encode(message, writer) {
                if (!writer) writer = $Writer.create()
                writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.id)
                writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.name)
                writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.barcode)
                writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.externalId)
                writer.uint32(/* id 5, wireType 2 =*/ 42).string(message.addressId)
                writer.uint32(/* id 6, wireType 2 =*/ 50).string(message.contactId)
                writer.uint32(/* id 7, wireType 0 =*/ 56).int64(message.createdAt)
                if (message.updatedAt != null && Object.hasOwnProperty.call(message, 'updatedAt'))
                    writer.uint32(/* id 8, wireType 0 =*/ 64).int64(message.updatedAt)
                if (message.deletedAt != null && Object.hasOwnProperty.call(message, 'deletedAt'))
                    writer.uint32(/* id 9, wireType 0 =*/ 72).int64(message.deletedAt)
                return writer
            }

            /**
             * Encodes the specified Warehouse message, length delimited. Does not implicitly {@link warehouses.Warehouse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof warehouses.Warehouse
             * @static
             * @param {warehouses.Warehouse} message Warehouse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Warehouse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim()
            }

            /**
             * Decodes a Warehouse message from the specified reader or buffer.
             * @function decode
             * @memberof warehouses.Warehouse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {warehouses.Warehouse} Warehouse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Warehouse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
                var end = length === undefined ? reader.len : reader.pos + length,
                    message = new $root.warehouses.Warehouse()
                while (reader.pos < end) {
                    var tag = reader.uint32()
                    switch (tag >>> 3) {
                        case 1: {
                            message.id = reader.string()
                            break
                        }
                        case 2: {
                            message.name = reader.string()
                            break
                        }
                        case 3: {
                            message.barcode = reader.string()
                            break
                        }
                        case 4: {
                            message.externalId = reader.string()
                            break
                        }
                        case 5: {
                            message.addressId = reader.string()
                            break
                        }
                        case 6: {
                            message.contactId = reader.string()
                            break
                        }
                        case 7: {
                            message.createdAt = reader.int64()
                            break
                        }
                        case 8: {
                            message.updatedAt = reader.int64()
                            break
                        }
                        case 9: {
                            message.deletedAt = reader.int64()
                            break
                        }
                        default:
                            reader.skipType(tag & 7)
                            break
                    }
                }
                if (!message.hasOwnProperty('id'))
                    throw $util.ProtocolError("missing required 'id'", { instance: message })
                if (!message.hasOwnProperty('name'))
                    throw $util.ProtocolError("missing required 'name'", { instance: message })
                if (!message.hasOwnProperty('barcode'))
                    throw $util.ProtocolError("missing required 'barcode'", { instance: message })
                if (!message.hasOwnProperty('externalId'))
                    throw $util.ProtocolError("missing required 'externalId'", { instance: message })
                if (!message.hasOwnProperty('addressId'))
                    throw $util.ProtocolError("missing required 'addressId'", { instance: message })
                if (!message.hasOwnProperty('contactId'))
                    throw $util.ProtocolError("missing required 'contactId'", { instance: message })
                if (!message.hasOwnProperty('createdAt'))
                    throw $util.ProtocolError("missing required 'createdAt'", { instance: message })
                return message
            }

            /**
             * Decodes a Warehouse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof warehouses.Warehouse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {warehouses.Warehouse} Warehouse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Warehouse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader)) reader = new $Reader(reader)
                return this.decode(reader, reader.uint32())
            }

            /**
             * Verifies a Warehouse message.
             * @function verify
             * @memberof warehouses.Warehouse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Warehouse.verify = function verify(message) {
                if (typeof message !== 'object' || message === null) return 'object expected'
                if (!$util.isString(message.id)) return 'id: string expected'
                if (!$util.isString(message.name)) return 'name: string expected'
                if (!$util.isString(message.barcode)) return 'barcode: string expected'
                if (!$util.isString(message.externalId)) return 'externalId: string expected'
                if (!$util.isString(message.addressId)) return 'addressId: string expected'
                if (!$util.isString(message.contactId)) return 'contactId: string expected'
                if (
                    !$util.isInteger(message.createdAt) &&
                    !(
                        message.createdAt &&
                        $util.isInteger(message.createdAt.low) &&
                        $util.isInteger(message.createdAt.high)
                    )
                )
                    return 'createdAt: integer|Long expected'
                if (message.updatedAt != null && message.hasOwnProperty('updatedAt'))
                    if (
                        !$util.isInteger(message.updatedAt) &&
                        !(
                            message.updatedAt &&
                            $util.isInteger(message.updatedAt.low) &&
                            $util.isInteger(message.updatedAt.high)
                        )
                    )
                        return 'updatedAt: integer|Long expected'
                if (message.deletedAt != null && message.hasOwnProperty('deletedAt'))
                    if (
                        !$util.isInteger(message.deletedAt) &&
                        !(
                            message.deletedAt &&
                            $util.isInteger(message.deletedAt.low) &&
                            $util.isInteger(message.deletedAt.high)
                        )
                    )
                        return 'deletedAt: integer|Long expected'
                return null
            }

            /**
             * Creates a Warehouse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof warehouses.Warehouse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {warehouses.Warehouse} Warehouse
             */
            Warehouse.fromObject = function fromObject(object) {
                if (object instanceof $root.warehouses.Warehouse) return object
                var message = new $root.warehouses.Warehouse()
                if (object.id != null) message.id = String(object.id)
                if (object.name != null) message.name = String(object.name)
                if (object.barcode != null) message.barcode = String(object.barcode)
                if (object.externalId != null) message.externalId = String(object.externalId)
                if (object.addressId != null) message.addressId = String(object.addressId)
                if (object.contactId != null) message.contactId = String(object.contactId)
                if (object.createdAt != null)
                    if ($util.Long) (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false
                    else if (typeof object.createdAt === 'string') message.createdAt = parseInt(object.createdAt, 10)
                    else if (typeof object.createdAt === 'number') message.createdAt = object.createdAt
                    else if (typeof object.createdAt === 'object')
                        message.createdAt = new $util.LongBits(
                            object.createdAt.low >>> 0,
                            object.createdAt.high >>> 0
                        ).toNumber()
                if (object.updatedAt != null)
                    if ($util.Long) (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false
                    else if (typeof object.updatedAt === 'string') message.updatedAt = parseInt(object.updatedAt, 10)
                    else if (typeof object.updatedAt === 'number') message.updatedAt = object.updatedAt
                    else if (typeof object.updatedAt === 'object')
                        message.updatedAt = new $util.LongBits(
                            object.updatedAt.low >>> 0,
                            object.updatedAt.high >>> 0
                        ).toNumber()
                if (object.deletedAt != null)
                    if ($util.Long) (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false
                    else if (typeof object.deletedAt === 'string') message.deletedAt = parseInt(object.deletedAt, 10)
                    else if (typeof object.deletedAt === 'number') message.deletedAt = object.deletedAt
                    else if (typeof object.deletedAt === 'object')
                        message.deletedAt = new $util.LongBits(
                            object.deletedAt.low >>> 0,
                            object.deletedAt.high >>> 0
                        ).toNumber()
                return message
            }

            /**
             * Creates a plain object from a Warehouse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof warehouses.Warehouse
             * @static
             * @param {warehouses.Warehouse} message Warehouse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Warehouse.toObject = function toObject(message, options) {
                if (!options) options = {}
                var object = {}
                if (options.defaults) {
                    object.id = ''
                    object.name = ''
                    object.barcode = ''
                    object.externalId = ''
                    object.addressId = ''
                    object.contactId = ''
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.createdAt =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.createdAt = options.longs === String ? '0' : 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.updatedAt =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.updatedAt = options.longs === String ? '0' : 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.deletedAt =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.deletedAt = options.longs === String ? '0' : 0
                }
                if (message.id != null && message.hasOwnProperty('id')) object.id = message.id
                if (message.name != null && message.hasOwnProperty('name')) object.name = message.name
                if (message.barcode != null && message.hasOwnProperty('barcode')) object.barcode = message.barcode
                if (message.externalId != null && message.hasOwnProperty('externalId'))
                    object.externalId = message.externalId
                if (message.addressId != null && message.hasOwnProperty('addressId'))
                    object.addressId = message.addressId
                if (message.contactId != null && message.hasOwnProperty('contactId'))
                    object.contactId = message.contactId
                if (message.createdAt != null && message.hasOwnProperty('createdAt'))
                    if (typeof message.createdAt === 'number')
                        object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt
                    else
                        object.createdAt =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.createdAt)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.createdAt.low >>> 0,
                                      message.createdAt.high >>> 0
                                  ).toNumber()
                                : message.createdAt
                if (message.updatedAt != null && message.hasOwnProperty('updatedAt'))
                    if (typeof message.updatedAt === 'number')
                        object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt
                    else
                        object.updatedAt =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.updatedAt)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.updatedAt.low >>> 0,
                                      message.updatedAt.high >>> 0
                                  ).toNumber()
                                : message.updatedAt
                if (message.deletedAt != null && message.hasOwnProperty('deletedAt'))
                    if (typeof message.deletedAt === 'number')
                        object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt
                    else
                        object.deletedAt =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.deletedAt)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.deletedAt.low >>> 0,
                                      message.deletedAt.high >>> 0
                                  ).toNumber()
                                : message.deletedAt
                return object
            }

            /**
             * Converts this Warehouse to JSON.
             * @function toJSON
             * @memberof warehouses.Warehouse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Warehouse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
            }

            /**
             * Gets the default type url for Warehouse
             * @function getTypeUrl
             * @memberof warehouses.Warehouse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Warehouse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = 'type.googleapis.com'
                }
                return typeUrlPrefix + '/warehouses.Warehouse'
            }

            return Warehouse
        })()

        warehouses.WarehouseWithRel = (function () {
            /**
             * Properties of a WarehouseWithRel.
             * @memberof warehouses
             * @interface IWarehouseWithRel
             * @property {warehouses.Warehouse} warehouse WarehouseWithRel warehouse
             * @property {addresses.Address} address WarehouseWithRel address
             * @property {contacts.Contact} contact WarehouseWithRel contact
             */

            /**
             * Constructs a new WarehouseWithRel.
             * @memberof warehouses
             * @classdesc Represents a WarehouseWithRel.
             * @implements IWarehouseWithRel
             * @constructor
             * @param {warehouses.IWarehouseWithRel=} [properties] Properties to set
             */
            function WarehouseWithRel(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
            }

            /**
             * WarehouseWithRel warehouse.
             * @member {warehouses.Warehouse} warehouse
             * @memberof warehouses.WarehouseWithRel
             * @instance
             */
            WarehouseWithRel.prototype.warehouse = null

            /**
             * WarehouseWithRel address.
             * @member {addresses.Address} address
             * @memberof warehouses.WarehouseWithRel
             * @instance
             */
            WarehouseWithRel.prototype.address = null

            /**
             * WarehouseWithRel contact.
             * @member {contacts.Contact} contact
             * @memberof warehouses.WarehouseWithRel
             * @instance
             */
            WarehouseWithRel.prototype.contact = null

            /**
             * Creates a new WarehouseWithRel instance using the specified properties.
             * @function create
             * @memberof warehouses.WarehouseWithRel
             * @static
             * @param {warehouses.IWarehouseWithRel=} [properties] Properties to set
             * @returns {warehouses.WarehouseWithRel} WarehouseWithRel instance
             */
            WarehouseWithRel.create = function create(properties) {
                return new WarehouseWithRel(properties)
            }

            /**
             * Encodes the specified WarehouseWithRel message. Does not implicitly {@link warehouses.WarehouseWithRel.verify|verify} messages.
             * @function encode
             * @memberof warehouses.WarehouseWithRel
             * @static
             * @param {warehouses.WarehouseWithRel} message WarehouseWithRel message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WarehouseWithRel.encode = function encode(message, writer) {
                if (!writer) writer = $Writer.create()
                $root.warehouses.Warehouse.encode(
                    message.warehouse,
                    writer.uint32(/* id 1, wireType 2 =*/ 10).fork()
                ).ldelim()
                $root.addresses.Address.encode(
                    message.address,
                    writer.uint32(/* id 4, wireType 2 =*/ 34).fork()
                ).ldelim()
                $root.contacts.Contact.encode(
                    message.contact,
                    writer.uint32(/* id 5, wireType 2 =*/ 42).fork()
                ).ldelim()
                return writer
            }

            /**
             * Encodes the specified WarehouseWithRel message, length delimited. Does not implicitly {@link warehouses.WarehouseWithRel.verify|verify} messages.
             * @function encodeDelimited
             * @memberof warehouses.WarehouseWithRel
             * @static
             * @param {warehouses.WarehouseWithRel} message WarehouseWithRel message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WarehouseWithRel.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim()
            }

            /**
             * Decodes a WarehouseWithRel message from the specified reader or buffer.
             * @function decode
             * @memberof warehouses.WarehouseWithRel
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {warehouses.WarehouseWithRel} WarehouseWithRel
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WarehouseWithRel.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
                var end = length === undefined ? reader.len : reader.pos + length,
                    message = new $root.warehouses.WarehouseWithRel()
                while (reader.pos < end) {
                    var tag = reader.uint32()
                    switch (tag >>> 3) {
                        case 1: {
                            message.warehouse = $root.warehouses.Warehouse.decode(reader, reader.uint32())
                            break
                        }
                        case 4: {
                            message.address = $root.addresses.Address.decode(reader, reader.uint32())
                            break
                        }
                        case 5: {
                            message.contact = $root.contacts.Contact.decode(reader, reader.uint32())
                            break
                        }
                        default:
                            reader.skipType(tag & 7)
                            break
                    }
                }
                if (!message.hasOwnProperty('warehouse'))
                    throw $util.ProtocolError("missing required 'warehouse'", { instance: message })
                if (!message.hasOwnProperty('address'))
                    throw $util.ProtocolError("missing required 'address'", { instance: message })
                if (!message.hasOwnProperty('contact'))
                    throw $util.ProtocolError("missing required 'contact'", { instance: message })
                return message
            }

            /**
             * Decodes a WarehouseWithRel message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof warehouses.WarehouseWithRel
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {warehouses.WarehouseWithRel} WarehouseWithRel
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WarehouseWithRel.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader)) reader = new $Reader(reader)
                return this.decode(reader, reader.uint32())
            }

            /**
             * Verifies a WarehouseWithRel message.
             * @function verify
             * @memberof warehouses.WarehouseWithRel
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            WarehouseWithRel.verify = function verify(message) {
                if (typeof message !== 'object' || message === null) return 'object expected'
                {
                    var error = $root.warehouses.Warehouse.verify(message.warehouse)
                    if (error) return 'warehouse.' + error
                }
                {
                    var error = $root.addresses.Address.verify(message.address)
                    if (error) return 'address.' + error
                }
                {
                    var error = $root.contacts.Contact.verify(message.contact)
                    if (error) return 'contact.' + error
                }
                return null
            }

            /**
             * Creates a WarehouseWithRel message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof warehouses.WarehouseWithRel
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {warehouses.WarehouseWithRel} WarehouseWithRel
             */
            WarehouseWithRel.fromObject = function fromObject(object) {
                if (object instanceof $root.warehouses.WarehouseWithRel) return object
                var message = new $root.warehouses.WarehouseWithRel()
                if (object.warehouse != null) {
                    if (typeof object.warehouse !== 'object')
                        throw TypeError('.warehouses.WarehouseWithRel.warehouse: object expected')
                    message.warehouse = $root.warehouses.Warehouse.fromObject(object.warehouse)
                }
                if (object.address != null) {
                    if (typeof object.address !== 'object')
                        throw TypeError('.warehouses.WarehouseWithRel.address: object expected')
                    message.address = $root.addresses.Address.fromObject(object.address)
                }
                if (object.contact != null) {
                    if (typeof object.contact !== 'object')
                        throw TypeError('.warehouses.WarehouseWithRel.contact: object expected')
                    message.contact = $root.contacts.Contact.fromObject(object.contact)
                }
                return message
            }

            /**
             * Creates a plain object from a WarehouseWithRel message. Also converts values to other types if specified.
             * @function toObject
             * @memberof warehouses.WarehouseWithRel
             * @static
             * @param {warehouses.WarehouseWithRel} message WarehouseWithRel
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            WarehouseWithRel.toObject = function toObject(message, options) {
                if (!options) options = {}
                var object = {}
                if (options.defaults) {
                    object.warehouse = null
                    object.address = null
                    object.contact = null
                }
                if (message.warehouse != null && message.hasOwnProperty('warehouse'))
                    object.warehouse = $root.warehouses.Warehouse.toObject(message.warehouse, options)
                if (message.address != null && message.hasOwnProperty('address'))
                    object.address = $root.addresses.Address.toObject(message.address, options)
                if (message.contact != null && message.hasOwnProperty('contact'))
                    object.contact = $root.contacts.Contact.toObject(message.contact, options)
                return object
            }

            /**
             * Converts this WarehouseWithRel to JSON.
             * @function toJSON
             * @memberof warehouses.WarehouseWithRel
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            WarehouseWithRel.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
            }

            /**
             * Gets the default type url for WarehouseWithRel
             * @function getTypeUrl
             * @memberof warehouses.WarehouseWithRel
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            WarehouseWithRel.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = 'type.googleapis.com'
                }
                return typeUrlPrefix + '/warehouses.WarehouseWithRel'
            }

            return WarehouseWithRel
        })()

        return warehouses
    })()

    $root.addresses = (function () {
        /**
         * Namespace addresses.
         * @exports addresses
         * @namespace
         */
        var addresses = {}

        addresses.Address = (function () {
            /**
             * Properties of an Address.
             * @memberof addresses
             * @interface IAddress
             * @property {string} id Address id
             * @property {string} name Address name
             * @property {string|null} [externalId] Address externalId
             * @property {number} latitude Address latitude
             * @property {number} longitude Address longitude
             * @property {number} createdAt Address createdAt
             * @property {number|null} [updatedAt] Address updatedAt
             * @property {number|null} [deletedAt] Address deletedAt
             */

            /**
             * Constructs a new Address.
             * @memberof addresses
             * @classdesc Represents an Address.
             * @implements IAddress
             * @constructor
             * @param {addresses.IAddress=} [properties] Properties to set
             */
            function Address(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]]
            }

            /**
             * Address id.
             * @member {string} id
             * @memberof addresses.Address
             * @instance
             */
            Address.prototype.id = ''

            /**
             * Address name.
             * @member {string} name
             * @memberof addresses.Address
             * @instance
             */
            Address.prototype.name = ''

            /**
             * Address externalId.
             * @member {string} externalId
             * @memberof addresses.Address
             * @instance
             */
            Address.prototype.externalId = ''

            /**
             * Address latitude.
             * @member {number} latitude
             * @memberof addresses.Address
             * @instance
             */
            Address.prototype.latitude = 0

            /**
             * Address longitude.
             * @member {number} longitude
             * @memberof addresses.Address
             * @instance
             */
            Address.prototype.longitude = 0

            /**
             * Address createdAt.
             * @member {number} createdAt
             * @memberof addresses.Address
             * @instance
             */
            Address.prototype.createdAt = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Address updatedAt.
             * @member {number} updatedAt
             * @memberof addresses.Address
             * @instance
             */
            Address.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Address deletedAt.
             * @member {number} deletedAt
             * @memberof addresses.Address
             * @instance
             */
            Address.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0, 0, false) : 0

            /**
             * Creates a new Address instance using the specified properties.
             * @function create
             * @memberof addresses.Address
             * @static
             * @param {addresses.IAddress=} [properties] Properties to set
             * @returns {addresses.Address} Address instance
             */
            Address.create = function create(properties) {
                return new Address(properties)
            }

            /**
             * Encodes the specified Address message. Does not implicitly {@link addresses.Address.verify|verify} messages.
             * @function encode
             * @memberof addresses.Address
             * @static
             * @param {addresses.Address} message Address message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Address.encode = function encode(message, writer) {
                if (!writer) writer = $Writer.create()
                writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.id)
                writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.name)
                if (message.externalId != null && Object.hasOwnProperty.call(message, 'externalId'))
                    writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.externalId)
                writer.uint32(/* id 4, wireType 1 =*/ 33).double(message.latitude)
                writer.uint32(/* id 5, wireType 1 =*/ 41).double(message.longitude)
                writer.uint32(/* id 6, wireType 0 =*/ 48).int64(message.createdAt)
                if (message.updatedAt != null && Object.hasOwnProperty.call(message, 'updatedAt'))
                    writer.uint32(/* id 7, wireType 0 =*/ 56).int64(message.updatedAt)
                if (message.deletedAt != null && Object.hasOwnProperty.call(message, 'deletedAt'))
                    writer.uint32(/* id 8, wireType 0 =*/ 64).int64(message.deletedAt)
                return writer
            }

            /**
             * Encodes the specified Address message, length delimited. Does not implicitly {@link addresses.Address.verify|verify} messages.
             * @function encodeDelimited
             * @memberof addresses.Address
             * @static
             * @param {addresses.Address} message Address message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Address.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim()
            }

            /**
             * Decodes an Address message from the specified reader or buffer.
             * @function decode
             * @memberof addresses.Address
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {addresses.Address} Address
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Address.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader)) reader = $Reader.create(reader)
                var end = length === undefined ? reader.len : reader.pos + length,
                    message = new $root.addresses.Address()
                while (reader.pos < end) {
                    var tag = reader.uint32()
                    switch (tag >>> 3) {
                        case 1: {
                            message.id = reader.string()
                            break
                        }
                        case 2: {
                            message.name = reader.string()
                            break
                        }
                        case 3: {
                            message.externalId = reader.string()
                            break
                        }
                        case 4: {
                            message.latitude = reader.double()
                            break
                        }
                        case 5: {
                            message.longitude = reader.double()
                            break
                        }
                        case 6: {
                            message.createdAt = reader.int64()
                            break
                        }
                        case 7: {
                            message.updatedAt = reader.int64()
                            break
                        }
                        case 8: {
                            message.deletedAt = reader.int64()
                            break
                        }
                        default:
                            reader.skipType(tag & 7)
                            break
                    }
                }
                if (!message.hasOwnProperty('id'))
                    throw $util.ProtocolError("missing required 'id'", { instance: message })
                if (!message.hasOwnProperty('name'))
                    throw $util.ProtocolError("missing required 'name'", { instance: message })
                if (!message.hasOwnProperty('latitude'))
                    throw $util.ProtocolError("missing required 'latitude'", { instance: message })
                if (!message.hasOwnProperty('longitude'))
                    throw $util.ProtocolError("missing required 'longitude'", { instance: message })
                if (!message.hasOwnProperty('createdAt'))
                    throw $util.ProtocolError("missing required 'createdAt'", { instance: message })
                return message
            }

            /**
             * Decodes an Address message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof addresses.Address
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {addresses.Address} Address
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Address.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader)) reader = new $Reader(reader)
                return this.decode(reader, reader.uint32())
            }

            /**
             * Verifies an Address message.
             * @function verify
             * @memberof addresses.Address
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Address.verify = function verify(message) {
                if (typeof message !== 'object' || message === null) return 'object expected'
                if (!$util.isString(message.id)) return 'id: string expected'
                if (!$util.isString(message.name)) return 'name: string expected'
                if (message.externalId != null && message.hasOwnProperty('externalId'))
                    if (!$util.isString(message.externalId)) return 'externalId: string expected'
                if (typeof message.latitude !== 'number') return 'latitude: number expected'
                if (typeof message.longitude !== 'number') return 'longitude: number expected'
                if (
                    !$util.isInteger(message.createdAt) &&
                    !(
                        message.createdAt &&
                        $util.isInteger(message.createdAt.low) &&
                        $util.isInteger(message.createdAt.high)
                    )
                )
                    return 'createdAt: integer|Long expected'
                if (message.updatedAt != null && message.hasOwnProperty('updatedAt'))
                    if (
                        !$util.isInteger(message.updatedAt) &&
                        !(
                            message.updatedAt &&
                            $util.isInteger(message.updatedAt.low) &&
                            $util.isInteger(message.updatedAt.high)
                        )
                    )
                        return 'updatedAt: integer|Long expected'
                if (message.deletedAt != null && message.hasOwnProperty('deletedAt'))
                    if (
                        !$util.isInteger(message.deletedAt) &&
                        !(
                            message.deletedAt &&
                            $util.isInteger(message.deletedAt.low) &&
                            $util.isInteger(message.deletedAt.high)
                        )
                    )
                        return 'deletedAt: integer|Long expected'
                return null
            }

            /**
             * Creates an Address message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof addresses.Address
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {addresses.Address} Address
             */
            Address.fromObject = function fromObject(object) {
                if (object instanceof $root.addresses.Address) return object
                var message = new $root.addresses.Address()
                if (object.id != null) message.id = String(object.id)
                if (object.name != null) message.name = String(object.name)
                if (object.externalId != null) message.externalId = String(object.externalId)
                if (object.latitude != null) message.latitude = Number(object.latitude)
                if (object.longitude != null) message.longitude = Number(object.longitude)
                if (object.createdAt != null)
                    if ($util.Long) (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false
                    else if (typeof object.createdAt === 'string') message.createdAt = parseInt(object.createdAt, 10)
                    else if (typeof object.createdAt === 'number') message.createdAt = object.createdAt
                    else if (typeof object.createdAt === 'object')
                        message.createdAt = new $util.LongBits(
                            object.createdAt.low >>> 0,
                            object.createdAt.high >>> 0
                        ).toNumber()
                if (object.updatedAt != null)
                    if ($util.Long) (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false
                    else if (typeof object.updatedAt === 'string') message.updatedAt = parseInt(object.updatedAt, 10)
                    else if (typeof object.updatedAt === 'number') message.updatedAt = object.updatedAt
                    else if (typeof object.updatedAt === 'object')
                        message.updatedAt = new $util.LongBits(
                            object.updatedAt.low >>> 0,
                            object.updatedAt.high >>> 0
                        ).toNumber()
                if (object.deletedAt != null)
                    if ($util.Long) (message.deletedAt = $util.Long.fromValue(object.deletedAt)).unsigned = false
                    else if (typeof object.deletedAt === 'string') message.deletedAt = parseInt(object.deletedAt, 10)
                    else if (typeof object.deletedAt === 'number') message.deletedAt = object.deletedAt
                    else if (typeof object.deletedAt === 'object')
                        message.deletedAt = new $util.LongBits(
                            object.deletedAt.low >>> 0,
                            object.deletedAt.high >>> 0
                        ).toNumber()
                return message
            }

            /**
             * Creates a plain object from an Address message. Also converts values to other types if specified.
             * @function toObject
             * @memberof addresses.Address
             * @static
             * @param {addresses.Address} message Address
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Address.toObject = function toObject(message, options) {
                if (!options) options = {}
                var object = {}
                if (options.defaults) {
                    object.id = ''
                    object.name = ''
                    object.externalId = ''
                    object.latitude = 0
                    object.longitude = 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.createdAt =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.createdAt = options.longs === String ? '0' : 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.updatedAt =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.updatedAt = options.longs === String ? '0' : 0
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false)
                        object.deletedAt =
                            options.longs === String
                                ? long.toString()
                                : options.longs === Number
                                ? long.toNumber()
                                : long
                    } else object.deletedAt = options.longs === String ? '0' : 0
                }
                if (message.id != null && message.hasOwnProperty('id')) object.id = message.id
                if (message.name != null && message.hasOwnProperty('name')) object.name = message.name
                if (message.externalId != null && message.hasOwnProperty('externalId'))
                    object.externalId = message.externalId
                if (message.latitude != null && message.hasOwnProperty('latitude'))
                    object.latitude =
                        options.json && !isFinite(message.latitude) ? String(message.latitude) : message.latitude
                if (message.longitude != null && message.hasOwnProperty('longitude'))
                    object.longitude =
                        options.json && !isFinite(message.longitude) ? String(message.longitude) : message.longitude
                if (message.createdAt != null && message.hasOwnProperty('createdAt'))
                    if (typeof message.createdAt === 'number')
                        object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt
                    else
                        object.createdAt =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.createdAt)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.createdAt.low >>> 0,
                                      message.createdAt.high >>> 0
                                  ).toNumber()
                                : message.createdAt
                if (message.updatedAt != null && message.hasOwnProperty('updatedAt'))
                    if (typeof message.updatedAt === 'number')
                        object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt
                    else
                        object.updatedAt =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.updatedAt)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.updatedAt.low >>> 0,
                                      message.updatedAt.high >>> 0
                                  ).toNumber()
                                : message.updatedAt
                if (message.deletedAt != null && message.hasOwnProperty('deletedAt'))
                    if (typeof message.deletedAt === 'number')
                        object.deletedAt = options.longs === String ? String(message.deletedAt) : message.deletedAt
                    else
                        object.deletedAt =
                            options.longs === String
                                ? $util.Long.prototype.toString.call(message.deletedAt)
                                : options.longs === Number
                                ? new $util.LongBits(
                                      message.deletedAt.low >>> 0,
                                      message.deletedAt.high >>> 0
                                  ).toNumber()
                                : message.deletedAt
                return object
            }

            /**
             * Converts this Address to JSON.
             * @function toJSON
             * @memberof addresses.Address
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Address.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions)
            }

            /**
             * Gets the default type url for Address
             * @function getTypeUrl
             * @memberof addresses.Address
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Address.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = 'type.googleapis.com'
                }
                return typeUrlPrefix + '/addresses.Address'
            }

            return Address
        })()

        return addresses
    })()

    return $root
})

import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import htmlClasses from 'html-classes';
import { useMediaQuery } from 'react-responsive';
import MobileFooter from './MobileFooter';
import Map from './Map';
import Header from './Header';
import Summary from './Summary';
import DynamicFrameControls from './DynamicFrameControls';
import { MOBILE_WIDTH } from 'config';
import { mainStore } from 'stores/MainStore';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { FRAMES_WIDTH } from 'constants/frames';

export default observer(() => {
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_WIDTH}px)` });
  const { width } = useWindowDimensions();
  const frameWidth = useMemo(() => {
    switch (mainStore.activeDynamicFrame) {
      case 'teamless':
      case 'unassigned':
        return FRAMES_WIDTH.teamless;
      case 'courier':
        return FRAMES_WIDTH.courier;
      case 'detail':
        return FRAMES_WIDTH.detail;
      default:
        return 0;
    }
    //eslint-disable-next-line
  }, [mainStore.activeDynamicFrame]);

  return (
    <div
      className={htmlClasses('frame-map')}
      style={{
        width: isMobile ? width : width - (frameWidth + FRAMES_WIDTH.teams),
      }}
    >
      <Map />
      {!isMobile ? (
        <>
          <Header />
          <Summary />
          <DynamicFrameControls />
        </>
      ) : (
        <MobileFooter />
      )}
    </div>
  );
});

import { observer } from 'mobx-react-lite';
import React from 'react';
import htmlClasses from 'html-classes';
import { mainStore, DynamicFrameName } from 'stores/MainStore';
import { dataStore } from 'stores/DataStore';

export default observer(() => {
  const handleToggle = (frame: DynamicFrameName) => mainStore.setActiveDynamicFrame(frame);

  return (
    <>
      <div
        className={htmlClasses('button-circle _unassigned icon icon-pin', {
          _active: dataStore.unassignedOrdersCount,
        })}
        onClick={() => handleToggle('unassigned')}
      >
        {dataStore.unassignedOrdersCount > 0 && (
          <div className="button-circle__label">{dataStore.unassignedOrdersCount}</div>
        )}
      </div>
      <div
        className={htmlClasses('button-circle _teamless icon icon-team', {
          _active: dataStore.teamlessCouriersCount,
        })}
        onClick={() => handleToggle('teamless')}
      >
        {dataStore.teamlessCouriersCount > 0 && (
          <div className="button-circle__label">{dataStore.teamlessCouriersCount}</div>
        )}
      </div>
    </>
  );
});

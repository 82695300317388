import { Address } from 'api/types/Address';
import { Contact } from 'api/types/Contact';
import { observer } from 'mobx-react-lite';
import {
  addresses,
  contacts,
} from 'proto/build/js/last-mile/dispatcher/public/admin/v2/jobs_get_list';
import { mainStore } from 'stores/MainStore';
import MultilineEllipsis from 'components/MultilineEllipsis';
import { OrderPaymentMethod } from '../../../../api/types/Order';
import { useTranslation } from 'react-i18next';

interface CustomerProps {
  contact: contacts.Contact | Contact;
  address: addresses.Address | Address;
  commentary: string | undefined;
  paymentMethod?: OrderPaymentMethod;
}



export default observer(({ contact, address, commentary, paymentMethod }: CustomerProps) => {
  const { t } = useTranslation();

  const paymentMethodsVerbose = {
    [OrderPaymentMethod.CARD]: t('paymentMethod:card'),
    [OrderPaymentMethod.CASH]: t('paymentMethod:cash'),
  };
  
  const getPaymentMethodVerbose = (paymentMethod: OrderPaymentMethod) => {
    const paymentMethodVerbose = paymentMethodsVerbose[paymentMethod];
  
    return paymentMethodVerbose ?? 'Not specified';
  };

  const paymentMethodVerbose = paymentMethod ? getPaymentMethodVerbose(paymentMethod) : null;
  const handlePhoneCopy = () => copyPhoneNumber(contact.phone);
  

  const copyPhoneNumber = async (phone: string) => {
    try {
      await navigator.clipboard.writeText(phone);
    } catch (error) {
      error && console.error(error);
      mainStore.pushAlert('error', t('errorPhrases:failedCopyPhone'));
    }
  };

  return (
    <div className="order-details__item item _customer">
      <div className="item__title title">
        <span className="title__sub">{t('common:customer')}</span>
        <span className="title__main">{contact.name}</span>
      </div>
      <div className="item__content content">
        <div className="content__header">
          <div className="phone">
            <span className="phone__value">{contact.phone}</span>
            <div className="phone__copy icon icon-copy" onClick={handlePhoneCopy} />
          </div>
          <div className="address">{address.name}</div>
        </div>
        {commentary && commentary?.length > 0 && (
          <div className="content__body">
            <MultilineEllipsis text={commentary} seeAllButton />
          </div>
        )}
        {paymentMethodVerbose && (
          <div className="payment-method">
            <span className="payment-method__label">{t('common:paymentMethod')}</span>
            <span className="payment-method__value">{paymentMethodVerbose}</span>
          </div>
        )}
      </div>
    </div>
  );
});

import { observer } from 'mobx-react-lite';
import React from 'react';
import { mainStore } from 'stores/MainStore';
import Popover from './Popover';
import { useTranslation } from 'react-i18next';

export default observer(() => {
  const { t } = useTranslation();
  // const handleTogglePopoverAddCourier = (e: React.MouseEvent<HTMLDivElement>) => {
  //   e.stopPropagation();
  //   mainStore.setIsShowPopoverAddCourier(!mainStore.isShowPopoverAddCourier);
  // };

  const handleTogglePopoverAddTeam = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    mainStore.setIsShowPopoverAddTeam(!mainStore.isShowPopoverAddTeam);
  };

  return (
    <Popover
      className="popover-menu popover-dropdown _in-top"
      isShow={mainStore.isShowPopoverDropdown}
    >
      <div className="popover-dropdown-options">
        {/*<div className="popover-menu__item" onClick={handleTogglePopoverAddCourier}>*/}
        {/*  <span className="icon icon-bicycle" />*/}
        {/*  Courier*/}
        {/*</div>*/}
        <div className="popover-menu__item" onClick={handleTogglePopoverAddTeam}>
          <span className="icon icon-team" />
          {t('common:team')}
        </div>
      </div>
    </Popover>
  );
});

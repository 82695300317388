import { CourierWithRel, TaskWithRel } from 'api/response/GetJobListResponse';
import { Order, OrderType } from 'api/types/Order';
import Parcels from 'components/FrameDetail/OrderDetails/Items/Parcels';
import Products from 'components/FrameDetail/OrderDetails/Items/Products';
import MobileEmptyBlock from 'components/MobileEmptyBlock';
import htmlClasses from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import { dataStore } from 'stores/DataStore';
import { mainStore } from 'stores/MainStore';
import { frameDetailStore } from '../store';
import Courier from './Items/Courier';
import Customer from './Items/Customer';

interface OrderDetailsProps {
  active: boolean;
}

export default observer(({ active }: OrderDetailsProps) => {
  const [tasks, setTasks] = useState<TaskWithRel[] | null>(null);
  const [order, setOrder] = useState<Order | null>(null);
  const [courier, setCourier] = useState<CourierWithRel | null>(null);
  const orderInfo = useMemo(() => {
    return (
      dataStore.getServerOrderById(mainStore.selectedOrder.id, mainStore.selectedOrder.jobId) ||
      dataStore.getLocalOrderById(mainStore.selectedOrder.id, mainStore.selectedOrder.jobId)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainStore.selectedOrder.id, dataStore.activeTeam]);

  useEffect(() => {
    if (orderInfo?.tasks) {
      setTasks(orderInfo.tasks);
    }

    if (orderInfo?.order) {
      setOrder(orderInfo.order);
    }

    if (orderInfo?.courier) {
      setCourier(orderInfo.courier);
    } else {
      setCourier(null);
    }
    //eslint-disable-next-line
  }, [orderInfo]);

  const getParcelsOrProducts = (): JSX.Element | null => {
    const parcels = frameDetailStore.tasks?.DROP_OFF?.parcels;
    if (!parcels || !parcels.length || !order?.orderType) return null;
    if (order.orderType === OrderType.FASHION) return <Products parcels={parcels} />;
    if (order.orderType === OrderType.GROCERY) return <Parcels parcels={parcels} />;
    return null;
  };

  return (
    <div className={htmlClasses('order-details', { _active: active })}>
      {frameDetailStore.tasks && frameDetailStore.tasks.DROP_OFF && (
        <Customer
          contact={frameDetailStore.tasks.DROP_OFF.contact}
          address={frameDetailStore.tasks.DROP_OFF.address}
          commentary={order?.note}
          paymentMethod={order?.payment?.paymentMethod}
        />
      )}
      {courier && <Courier courier={courier} tasks={tasks} />}
      {getParcelsOrProducts()}
      <MobileEmptyBlock />
    </div>
  );
});

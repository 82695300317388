import { CourierAssignTeamDto } from 'api/dto/CourierAssignTeamDto';
import { CreateCourierDto } from 'api/dto/CreateCourierDto';
import { ForceOfflineCourierDto } from 'api/dto/ForceOfflineCourierDto';
import { GetCourierByExternalIdDto } from 'api/dto/GetCourierByExternalIdDto';
import { UpdateCourierDto } from 'api/dto/UpdateCourierDto';
import { CourierAssignTeamResponse } from 'api/response/CourierAssignTeamResponse';
import { ForceOfflineCourierResponse } from 'api/response/ForceOfflineCourierResponse';
import { Courier } from 'api/types/Courier';
import { RequestAPI, JiffyMessageType, APIResponse } from './Requests';
import { ADMIN_API_PREFIX, ADMIN_V1_API_PREFIX, API_URL_2 } from 'config';
import { JiffyMessage, JiffyMessageBody } from 'proto/build/js/message/message';

interface CourierRequestsInterface {
  getList(): Promise<APIResponse<Courier[]>>;

  forceOffline(data: ForceOfflineCourierDto): Promise<ForceOfflineCourierResponse>;

  // New method instead of 'newCourier'
  create(data: CreateCourierDto): Promise<APIResponse<Courier>>;

  // Will be replaced by 'create'
  newCourier(data: JiffyMessageType<JiffyMessageBody.Type.DISPATCHER__REQUEST__ADMIN__COURIER_CREATE_V2>): Promise<JiffyMessage>;

  assignTeam(data: CourierAssignTeamDto): Promise<CourierAssignTeamResponse>;

  unassignTeam(data: CourierAssignTeamDto): Promise<void>;

  update(courierId: string, data: UpdateCourierDto): Promise<APIResponse<Courier>>;

  getByExternalId(data: GetCourierByExternalIdDto): Promise<APIResponse<{ courier: Courier}>>;
}

export const CourierRequests: CourierRequestsInterface = {
  getList: () => RequestAPI.get(API_URL_2 + ADMIN_V1_API_PREFIX + 'couriers'),

  forceOffline: (data) => RequestAPI.post(API_URL_2 + ADMIN_V1_API_PREFIX + 'couriers/force-offline', data),

  create: (data) => RequestAPI.post(ADMIN_V1_API_PREFIX + 'couriers', data),

  newCourier: (data) => RequestAPI.post(ADMIN_API_PREFIX + 'v3/courier/create', data),

  assignTeam: (data) => RequestAPI.post(API_URL_2 + ADMIN_V1_API_PREFIX + 'couriers/assign-team', data),

  unassignTeam: (data) => RequestAPI.post(API_URL_2 + ADMIN_V1_API_PREFIX + 'couriers/unassign-team', data),

  update: (courierId, data) => RequestAPI.patch(API_URL_2 + ADMIN_V1_API_PREFIX + `couriers/${courierId}`, data),

  getByExternalId: (data) => RequestAPI.post(API_URL_2 + ADMIN_V1_API_PREFIX + 'couriers/get-by-ext-id', data),
};

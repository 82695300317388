import OrderInfoBar from 'components/Map/OrderInfoBar';
import htmlClasses from 'html-classes';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { TaskPoint } from 'stores/DataStore';
import { mainStore } from 'stores/MainStore';

type TaskClusterPinProps = {
  clusterId: number;
  orders: TaskPoint[];
};

export const TaskClusterPin = observer(({ clusterId, orders }: TaskClusterPinProps) => {
  const [isActive, setIsActive] = useState<boolean>(mainStore.openedTaskCluster === clusterId);

  const handlePinClick = () => mainStore.setOpenedTaskCluster(clusterId);

  const handlePinMouseLeave = () => mainStore.setOpenedTaskCluster(null);

  useEffect(() => {
    const taskIds = orders.map((order) => order.properties.taskId);
    setIsActive(taskIds.includes(mainStore.selectedOrder.taskId));
    //eslint-disable-next-line
  }, [mainStore.selectedOrder.taskId]);

  useEffect(() => {
    setIsActive(mainStore.openedTaskCluster === clusterId);
    //eslint-disable-next-line
  }, [mainStore.openedTaskCluster]);

  return (
    <div
      className={htmlClasses('map-pin _task-cluster', { _active: isActive })}
      onClick={handlePinClick}
      onMouseLeave={handlePinMouseLeave}
      onMouseEnter={handlePinClick}
    >
      <div className="count">{orders.length}</div>
      {orders && (
        <div className="orders">
          {orders.map((order) => {
            return (
              <OrderInfoBar
                externalId={order.properties.orderExternalId}
                key={order.properties.taskId}
                jobId={order.properties.jobId}
                orderId={order.properties.orderId}
                task={order.properties.task}
              />
            );
          })}
        </div>
      )}
    </div>
  );
});

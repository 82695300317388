import MobileEmptyBlock from 'components/MobileEmptyBlock';
import { MOBILE_WIDTH } from 'config';
import { observer } from 'mobx-react-lite';
import { useMediaQuery } from 'react-responsive';
import { mainStore } from 'stores/MainStore';
import { Scrollbars } from 'react-custom-scrollbars';
import { Droppable } from 'react-beautiful-dnd';
import CourierTeamless from './CourierTeamless';
import htmlClasses from 'html-classes';
import { dataStore } from 'stores/DataStore';
import React, { useState, useEffect } from 'react';
import { authStore } from 'stores/AuthStore';
import { useTranslation } from 'react-i18next';

const CouriersDuty = observer(() => {
  const { t } = useTranslation();
  const isDropDisabled = (): boolean => {
    return (mainStore.dragItem.group === 'teamless' || mainStore.dragItem.group === 'teamlessOffline') && mainStore.dragItem.type === 'courier';
  };

  // hack for force update component
  useEffect(() => {
    void 0;
    //eslint-disable-next-line
  }, [dataStore.teamlessCouriersDuty]);

  return (
    <>
      {dataStore.teamlessCouriersDuty && dataStore.teamlessCouriersDuty.length > 0 ? (
        <Droppable droppableId="teamless" type="courier" isDropDisabled={isDropDisabled()}>
          {(provided) => (
            <div className="frame-dynamic-list" ref={provided.innerRef}>
              {dataStore.teamlessCouriersDuty && dataStore.teamlessCouriersDuty.map(
                ({ courier, user }, index) => (
                  <CourierTeamless
                    userId={user.user.id}
                    id={courier.id}
                    status={courier.status}
                    vehicleType={courier.vehicleType}
                    userName={user.user.name}
                    index={index}
                    key={courier.id}
                  />
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      ) : (
        <Droppable droppableId="teamless" type="courier" isDropDisabled={isDropDisabled()}>
          {(provided, snapshot) => (
            <div
              className={htmlClasses('drop-here', {
                '_hover': snapshot.isDraggingOver,
              })}
              data-text={t('phrases:dropCourierHere')}
              ref={provided.innerRef}
            >
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      )}
    </>
  );
});

const CouriersOffline = observer(() => {
  const { t } = useTranslation();
  const [isExpandedOffline, setIsExpandedOffline] = useState(authStore.isExpandedOffline);
  const handleToggleExpanded = () => setIsExpandedOffline(!isExpandedOffline);

  // hack for force update component
  useEffect(() => {
    void 0;
    //eslint-disable-next-line
  }, [dataStore.teamlessCouriersOffline]);

  return (
    <Droppable droppableId="teamlessOffline" type="courier" isDropDisabled={true}>
      {(provided) => (
        <div className="frame-dynamic-list" ref={provided.innerRef}>
          <div
            className={htmlClasses('team-offline', {
              '_expanded': isExpandedOffline,
            })}
            onClick={handleToggleExpanded}
          >
            {t('common:offline')}
          </div>
          {isExpandedOffline && dataStore.teamlessCouriersOffline && dataStore.teamlessCouriersOffline.map(
            ({ courier, user }, index) => (
              <CourierTeamless
                userId={user.user.id}
                id={courier.id}
                status={courier.status}
                vehicleType={courier.vehicleType}
                userName={user.user.name}
                index={index}
                key={courier.id}
              />
            ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
});

export default observer(() => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_WIDTH}px)` });

  const handleHideFrame = () => {
    mainStore.setActiveDynamicFrame(null);
    if (isMobile) mainStore.setActiveMobileScreen('map');
  };

  return (
    <div className={htmlClasses('frame-dynamic-teamless', {
      '_disabled': mainStore.activeDynamicFrame !== 'teamless',
    })}>
      <div className="frame-dynamic-header">
        <div className="frame-dynamic-title">
          <div className="frame-dynamic-title__name">{t('common:teamLess')}</div>
          <div className="frame-dynamic-title__close icon icon-close" onClick={handleHideFrame} />
        </div>
        <div className="frame-dynamic-info">
          <div className="icon icon-hand-drag" />
          {t('phrases:dragTaskToCourier')}
        </div>
      </div>
      <div className="frame-dynamic-body">
        <Scrollbars>
          <CouriersDuty />
          {dataStore.teamlessCouriersOffline && dataStore.teamlessCouriersOffline.length > 0 && (
            <CouriersOffline />
          )}
          <MobileEmptyBlock/>
        </Scrollbars>
      </div>
    </div>
  );
});

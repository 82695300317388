import { MOBILE_WIDTH } from 'config';
import htmlClasses from 'html-classes';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { DayTime } from 'stores/DateTimeStore';
import { TimeType } from './index';

interface CellProps {
  value?: number;
  type: TimeType;
  selectedTime: DayTime | null;
  columnRef: React.RefObject<HTMLDivElement>;
  onChange?: (value: number, type: TimeType) => void;
}

export default observer(({ value, type, selectedTime, onChange, columnRef }: CellProps) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_WIDTH}px)` });

  const [isSelected, setIsSelected] = useState<boolean>(false);
  const cellRef = useRef<HTMLDivElement>(null);

  const handleCellClick = () => {
    if (value === undefined || !cellRef.current) return;
    if (isMobile) {
      if (columnRef.current) columnRef.current.scrollTop = cellRef.current.offsetTop - 48;
    } else {
      cellRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    onChange?.call(null, value, type);
  };

  useEffect(() => {
    if (!selectedTime) {
      setIsSelected(false);
      return;
    }

    switch (type) {
    case TimeType.HOUR: {
      setIsSelected(selectedTime.hours === value);
      break;
    }
    case TimeType.MINUTE: {
      setIsSelected(selectedTime.minutes === value);
      break;
    }
    case TimeType.SECOND: {
      setIsSelected(selectedTime.seconds === value);
      break;
    }
    }
    //eslint-disable-next-line
  }, [selectedTime]);

  return (
    <div className={htmlClasses('react-timepicker__cell', { _selected: isSelected, _empty: value === undefined })}
         onClick={handleCellClick}
         ref={cellRef}>
      {value !== undefined ? <>{value < 10 ? '0' : ''}{value}</> : ''}
    </div>
  );
});

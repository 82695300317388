import { Address } from './Address';
import { Contact } from './Contact';

export enum TaskState {
  UNASSIGNED = 'UNASSIGNED',
  ASSIGNED_TO_JOB = 'ASSIGNED_TO_JOB',
  ON_THE_WAY = 'ON_THE_WAY',
  ON_POINT = 'ON_POINT',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  CANCELED = 'CANCELED'
}

export enum TaskKind {
  PICK_UP = 'PICK_UP',
  DROP_OFF = 'DROP_OFF',
  RETURN = 'RETURN'
}

export interface Task {
  id: string;
  jobId: string;
  warehouseId: string;
  teamId: string;
  orderId: string;
  addressId: string;
  contactId: string;
  state: TaskState;
  kind: TaskKind;
  startBy: number;
  completeByMin: number;
  completeByMax: number;
  startedAt: number;
  completedAt: number;
  createdAt: number;
  updatedAt: number;
  deletedAt: number;
  commentary: string;
}

export interface ITaskWithRels {
  task: Task;
  address: Address;
  contact: Contact;
}

import htmlClasses from 'html-classes';
import { observer } from 'mobx-react-lite';
import { dataStore } from 'stores/DataStore';
import { mainStore } from 'stores/MainStore';
import { IUserWithRoles } from '../../../api/dto/GetUserByTeamDto';
import { useTranslation } from 'react-i18next';

interface UserCardProps {
  user: IUserWithRoles;
  onUserDeleteClick: (userId: string) => void;
}

export default observer(({ user, onUserDeleteClick }: UserCardProps) => {
  const { t } = useTranslation();
  const handleUserDelete = () => {
    onUserDeleteClick(user.id);
    mainStore.setIsShowPopupDeleteMember(true);
  };

  const handlePhoneCopy = () => copyPhoneNumber(user.phone);

  const copyPhoneNumber = async (phone: string) => {
    try {
      await navigator.clipboard.writeText(phone);
    }
    catch (error) {
      error && console.error(error);
      mainStore.pushAlert('error', t('errorPhrases:failedCopyPhone'));
    }
  };

  return (
    <div className='user-cards__user-card card'>
      <div className='user-card__top top'>
        <div className='top__left left'>
          <span className='left__name'>{user.name}</span>
          {/* TODO: replace string with enum */}
          <span className={htmlClasses('left__status', { _accent: user.status !== 'OFFLINE' })}>
            {user.status && user.status.charAt(0).toUpperCase() + user.status.toLowerCase().slice(1)}
          </span>
        </div>
        <div className='top__right right'>
          {/*<div className='right__icon icon icon-pen' />*/}
          <div className='right__icon icon icon-bin' onClick={handleUserDelete} />
        </div>
      </div>
      <div className='user-card__bottom bottom'>
        <div className='bottom__phone phone'>
          <div className='phone__value'>{user.phone}</div>
          <div className='phone__copy icon icon-copy' onClick={handlePhoneCopy} />
        </div>
        <div className='bottom__roles roles'>
          {user.roles.map(role => (
            <span className='roles__role'>{dataStore.getRoleName(role)}</span>
          ))}
        </div>
      </div>
    </div>
  );
});

import htmlClasses from 'html-classes';
import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, ReactNode, InputHTMLAttributes, FormEvent } from 'react';

interface InputTextProps extends InputHTMLAttributes<HTMLInputElement> {
  value?: string;
  placeholder?: string;
  maxLength?: number;
  disabled?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: ChangeEvent<HTMLInputElement>) => void;
  onSubmit?: (e: FormEvent<HTMLInputElement> | null) => void;
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  children?: ReactNode;
  className?: string;
  containerClassName?: string;
  type?: string;
  errorText?: string;
  isError?: boolean;
  noClearButton?: boolean;
  customStyle?: 'base' | 'flat';
  label?: string;
  iconButtonName?: string;
  onIconButtonClick?: () => void;
}

export default observer(
  ({
    value = '',
    placeholder,
    maxLength,
    disabled,
    onChange,
    onSubmit,
    onFocus,
    onBlur,
    errorText,
    children,
    className,
    containerClassName,
    type,
    isError,
    noClearButton,
    customStyle,
    label,
    iconButtonName,
    onIconButtonClick,
    ...props
  }: InputTextProps) => {
    const handleResetValue = (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      if (!onChange) return;
      onChange({ currentTarget: { value: '' } } as ChangeEvent<HTMLInputElement>);
    };

    const handleIconButtonClick = () => {
      onIconButtonClick?.call(null);
      onSubmit?.call(null, null);
    };

    const handleInputKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') onSubmit?.call(null, null);
    };

    return (
      <div
        className={htmlClasses(
          'input-wrapper',
          {
            '_with-icon-btn': !!iconButtonName,
            '_with-clear-btn': !noClearButton,
          },
          containerClassName,
        )}
      >
        {label && <div className="input-wrapper__label">{label}</div>}
        <div
          className={htmlClasses('input-text__wrap', className, {
            _active: value,
            _error: isError,
            _base: customStyle === 'base',
            _flat: customStyle === 'flat',
          })}
        >
          {children ? (
            children
          ) : (
            <>
              {!!iconButtonName && (
                <button
                  className={htmlClasses('input-text__button icon', `icon-${iconButtonName}`)}
                  onClick={handleIconButtonClick}
                  type="submit"
                />
              )}
              <input
                className="input-text"
                type={type || 'text'}
                value={value}
                disabled={disabled}
                onChange={onChange}
                onSubmit={onSubmit}
                onKeyPress={handleInputKeyPress}
                onFocus={onFocus}
                onBlur={onBlur}
                placeholder={placeholder}
                maxLength={maxLength}
                {...props}
              />
              {value?.length > 0 && !noClearButton && (
                <div className="input-text__clear icon icon-close" onClick={handleResetValue} />
              )}
            </>
          )}
        </div>
        {errorText && <div className="input-text__errorText">{errorText}</div>}
      </div>
    );
  },
);

import React, { FC } from 'react';
import htmlClasses from 'html-classes';

type ExpandableHeaderProps = {
  containerClass?: string;
  titleClass?: string;
  isExpanded: boolean;
  title: string;
  onClick: () => void;
};

export const ExpandableHeader: FC<ExpandableHeaderProps> = ({
  containerClass,
  titleClass,
  isExpanded,
  title,
  onClick,
}) => {
  return (
    <div onClick={onClick} className={htmlClasses('expandableHeader', containerClass)}>
      <span className={htmlClasses('expandableHeader-title', titleClass)}>{title}</span>
      <div
        className={htmlClasses('expandableHeader-title-icon icon icon-chevron-up', {
          __expanded: !isExpanded,
        })}
      />
    </div>
  );
};

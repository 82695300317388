import React, { useEffect, useState, useRef, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import htmlClasses from 'html-classes';
import { searchStore } from 'components/Search/store';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import Job from './Job';
import { mainStore } from 'stores/MainStore';
import { dataStore } from 'stores/DataStore';
import { authStore } from 'stores/AuthStore';
import { CourierStatus, VehicleType } from '../api/types/Courier';
import { useTranslation } from 'react-i18next';

interface CourierProps {
  id: string;
  status: CourierStatus;
  vehicleType: VehicleType;
  userName: string;
  index: number;
}

export default observer(({ id, status, vehicleType, userName, index }: CourierProps) => {
  const { t } = useTranslation();
  const [statusName, setStatusName] = useState('');
  const [vehicle, setVehicle] = useState('');
  const [isExpanded, setIsExpanded] = useState(authStore.expandedCourierIds.includes(id));
  const [isHover, setIsHover] = useState(false);
  const courierRef = useRef<HTMLDivElement | null>(null);

  const handleCourierClick = () => {
    mainStore.setActiveDynamicFrame(null);
    mainStore.setIsMapDragged(false);
    searchStore.resetSearch();
    mainStore.setSelectedCourier({ id, source: 'sidebar' });
    if (!courierJobsList.length) {
      setIsExpanded(false);
      return;
    }
    setIsExpanded(!isExpanded);
  };
  const handleShowCourierDetail = (e: React.MouseEvent) => {
    e.stopPropagation();
    mainStore.setSelectedCourier({ id, source: 'sidebar' });
    mainStore.setIsMapDragged(false);
    mainStore.setActiveDynamicFrame('courier');
  };

  const isDragDisabled = useCallback((): boolean => {
    return ![
      CourierStatus.OFFLINE,
      CourierStatus.ONLINE,
      CourierStatus.IDLE,
      CourierStatus.HEADING_TO_BASE,
    ].includes(status);
  }, [status]);

  const isDropDisabled = (): boolean => {
    if (status !== CourierStatus.IDLE && status !== CourierStatus.ASSIGNED_TO_JOB) return true;
    return (
      mainStore.dragItem.group.split('_')[0] === 'courierJobs' && mainStore.dragItem.type === 'job'
    );
  };
  const courierJobsList = dataStore.getCourierJobs(id);

  useEffect(() => {
    setStatusName(status ? CourierStatus[status]?.toLowerCase() || '' : '');
  }, [status]);

  useEffect(() => {
    setVehicle(VehicleType[vehicleType]?.toLowerCase() || '');
  }, [vehicleType]);

  useEffect(() => {
    if (
      !mainStore.selectedCourier.id ||
      mainStore.selectedCourier.id !== id ||
      mainStore.selectedCourier.source === 'sidebar' ||
      !courierRef ||
      !courierRef.current
    ) {
      return;
    }
    courierRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    if (courierJobsList.length) {
      const timeout = setTimeout(() => {
        setIsExpanded(true);
        clearTimeout(timeout);
      }, 200);
    }
    //eslint-disable-next-line
  }, [mainStore.selectedCourier.id, authStore.activeTeamId]);

  useEffect(() => {
    if (isExpanded) {
      authStore.addExpandedCourierId(id);
    } else {
      authStore.removeExpandedCourierId(id);
    }
  }, [isExpanded, id]);

  useEffect(() => {
    setIsExpanded(authStore.expandedCourierIds.includes(id));
    //eslint-disable-next-line
  }, [authStore.expandedCourierIds, id]);

  useEffect(() => {
    const job = dataStore.getJobListResponse?.find(
      (j) => j.job.id === mainStore.selectedOrder.jobId,
    );
    if (job?.courier?.courier.id === id) {
      setIsExpanded(true);
    }
    //eslint-disable-next-line
  }, [mainStore.selectedOrder]);

  useEffect(() => {
    if (!courierJobsList.length) setIsExpanded(false);
    setIsExpanded(false);
  }, [courierJobsList.length]);

  const handleMouseDown = useCallback(
    (event: React.MouseEvent) => {
      if (!isDragDisabled()) {
        if (mainStore.activeDynamicFrame !== 'teamless') {
          mainStore.setActiveDynamicFrame(null);
          mainStore.setActiveDynamicFrame('teamless');
        }
      }
      event.preventDefault();
    },
    [isDragDisabled],
  );

  return (
    <Draggable draggableId={id} key={id} index={index} isDragDisabled={isDragDisabled()}>
      {(provided) => {
        const handlers = {
          ...provided.dragHandleProps,
          ...provided.draggableProps,
          onMouseDown: isDragDisabled() ? undefined : handleMouseDown,
        };

        return (
          <div
            className={htmlClasses('courier', {
              _expanded: isExpanded,
              _open: isHover,
              _blink:
                mainStore.selectedCourier.id === id &&
                mainStore.selectedCourier.source !== 'sidebar',
            })}
            ref={provided.innerRef}
            {...handlers}
          >
            <Droppable
              key={`courier_${id}`}
              droppableId={`courierJobs_${id}`}
              type="job"
              isDropDisabled={isDropDisabled()}
            >
              {(provided, snapshot) => {
                if (isHover !== snapshot.isDraggingOver) setIsHover(snapshot.isDraggingOver);

                return (
                  <div ref={provided.innerRef}>
                    <div className="courier-title" onClick={handleCourierClick} ref={courierRef}>
                      <div className={`icon icon-${vehicle} courier-icon _${statusName}`} />
                      <div className="courier-title__name">{userName}</div>
                      {statusName.length > 0 && (
                        <div className="courier-title__status">{t(`courierStatus:${statusName}`)}</div>
                      )}
                      <div className="courier-title__info" onClick={handleShowCourierDetail}>
                        <div />
                      </div>
                      {courierJobsList && courierJobsList.length > 0 && (
                        <div className="courier-title__arrow icon icon-chevron-up" />
                      )}
                    </div>
                    <div className="courier__body">
                      {!isExpanded && provided.placeholder}
                      {courierJobsList.map(({ job, orders }, index) => (
                        <Job
                          jobId={job.id}
                          isAssigned
                          orders={orders || []}
                          handleToggleExpanding={setIsExpanded}
                          isExpanded={isExpanded}
                          index={index}
                          key={job.id}
                        />
                      ))}
                      {isExpanded && provided.placeholder}
                    </div>
                  </div>
                );
              }}
            </Droppable>
          </div>
        );
      }}
    </Draggable>
  );
});

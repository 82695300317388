export enum CompanyName {
  CityDrinks = 'citydrinks',
  Localee = 'localee',
  CircleK = 'circlek',
  Jiffy = 'jiffy',
  Baqal = 'baqal',
  Swifft = 'swifft',
}

export type GtmConfig = { auth: string; preview: string };

export type CompanyConfig = {
  id: string;
  lang: string;
  langs: string[];
  name: CompanyName;
};

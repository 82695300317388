import { observer } from 'mobx-react-lite';
import htmlClasses from 'html-classes';
import { Draggable } from 'react-beautiful-dnd';
import React, { useEffect, useState } from 'react';
import { mainStore } from 'stores/MainStore';
import { CourierStatus, VehicleType } from '../api/types/Courier';
import { searchStore } from './Search/store';
import { useTranslation } from 'react-i18next';

type CourierTeamlessProps = {
  id: string;
  userId: string;
  status: CourierStatus;
  vehicleType: VehicleType;
  userName: string;
  index: number;
}

export default observer(({ id, userId, status, vehicleType, userName, index }: CourierTeamlessProps) => {
  const { t } = useTranslation();
  const [statusName, setStatusName] = useState('');
  const [vehicle, setVehicle] = useState('');
  const handleCourierClick = () => mainStore.setSelectedCourier({ id, source: 'sidebar' });

  const handleShowCourierDetail = (e: React.MouseEvent) => {
    mainStore.setActiveDynamicFrame(null);
    searchStore.resetSearch();
    mainStore.setSelectedCourier({ id, source: 'sidebar' });
    mainStore.setActiveDynamicFrame('courier');
  };

  useEffect(() => {
    setStatusName(status ? CourierStatus[status]?.toLowerCase() || '' : '');
  }, [status]);

  useEffect(() => {
    setVehicle(VehicleType[vehicleType]?.toLowerCase() || '');
  }, [vehicleType]);

  return (
    <Draggable draggableId={userId} index={index}>
      {(provided) => (
        <div
          className={htmlClasses('courier')}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div className="courier-title" onClick={handleCourierClick}>
            <div className={`icon icon-${vehicle} courier-icon _${statusName}`} />
            <div className="courier-title__name">{userName}</div>
            {statusName.length > 0 && (
              <div className="courier-title__status">
                {t(`courierStatus:${statusName}`)}
              </div>
            )}
            <div className="courier-title__info" onClick={handleShowCourierDetail}>
              <div />
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
});

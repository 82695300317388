import { VehicleType } from 'api/types/Courier';
import htmlClasses from 'html-classes';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import Checkbox from 'components/Checkbox';
import { dataStore } from 'stores/DataStore';
import { getAllEnumKeys } from 'enum-for';
import { useTranslation } from 'react-i18next';

interface EditCourierFormProps {
  courierId: string;
  currentVehicleType: VehicleType;
  currentCapacity: number;
  currentAgency: boolean;
}

export default observer(
  ({ courierId, currentCapacity, currentVehicleType, currentAgency }: EditCourierFormProps) => {
    const { t } = useTranslation();
    if (!currentVehicleType.length) {
      currentVehicleType =
        VehicleType[getAllEnumKeys(VehicleType)[currentVehicleType as unknown as number]];
    }
    const [newVehicleType, setNewVehicleType] = useState<VehicleType>(currentVehicleType);
    const [newCapacity, setNewCapacity] = useState<number>(currentCapacity);
    const [vehicleList, setVehicleList] = useState<VehicleType[]>([]);
    const [newAgency, setNewAgency] = useState<boolean>(currentAgency);

    useEffect(() => {
      const orderedList: VehicleType[] = [
        VehicleType.BICYCLE,
        VehicleType.MOTORBIKE,
        VehicleType.CAR,
        VehicleType.FOOTER,
      ];
      setVehicleList(orderedList);
      //eslint-disable-next-line
    }, []);

    useEffect(() => {
      setNewVehicleType(currentVehicleType);
      setNewCapacity(currentCapacity);
      setNewAgency(currentAgency);
    }, [currentVehicleType, currentCapacity, currentAgency]);

    // const handleCapacityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //   setNewCapacity(parseInt(e.currentTarget.value || '0'));
    // };
    const handleVehicleTypeChange = (value: VehicleType) => {
      setNewVehicleType(value);
    };
    const handleAgencyToggle = () => setNewAgency((prev) => !prev);

    const handleSaveClick = async () => {
      if (!isDataValid) return;
      await dataStore.updateCourier(courierId, {
        capacity: newCapacity,
        agency: newAgency,
        vehicle: newVehicleType,
      });
      await dataStore.requestDashboardInfo();
    };

    const handleResetClick = () => {
      setNewCapacity(currentCapacity);
      setNewVehicleType(currentVehicleType);
      setNewAgency(currentAgency);
    };

    const isDataChanged =
      newCapacity !== currentCapacity ||
      newVehicleType !== currentVehicleType ||
      newAgency !== currentAgency;
    const isDataValid = newCapacity.toString().length > 0;

    // const bagLabel = newCapacity === 1 ? 'bag' : 'bags';

    return (
      <div className="edit-courier-form">
        <div className="edit-courier-form__input input">
          <div className="input__text">{t('forms:editCourier:transport')}</div>
          <div className="input__content transport">
            {vehicleList.map((vehicle, i) => (
              <div
                className={htmlClasses(
                  'transport__btn button icon',
                  `icon-${vehicle.toLowerCase()}`,
                  vehicle === newVehicleType ? '_primary' : '_secondary',
                )}
                onClick={() => handleVehicleTypeChange(vehicle)}
                key={i}
              />
            ))}
          </div>
        </div>
        {/*<div className="edit-courier-form__input input">*/}
        {/*  <div className="input__text">Capacity:</div>*/}
        {/*  <div className="input__content capacity">*/}
        {/*    <InputText*/}
        {/*      className="capacity__input"*/}
        {/*      type="number"*/}
        {/*      value={newCapacity.toString()}*/}
        {/*      onChange={handleCapacityChange}*/}
        {/*      customStyle="flat"*/}
        {/*      noClearButton*/}
        {/*    />*/}
        {/*    <span className="capacity__bag-label">{bagLabel}</span>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className="edit-courier-form__input input _checkbox" onClick={handleAgencyToggle}>
          <div className="input__text">{t('forms:editCourier:agency')}</div>
          <Checkbox className="input__content agency" checked={newAgency} />
        </div>
        <div className="edit-courier-form__buttons">
          <button
            className="button _primary"
            onClick={handleSaveClick}
            disabled={!isDataValid || !isDataChanged}
          >
            {t('forms:editCourier:save')}
          </button>
          <button
            className="button _secondary"
            onClick={handleResetClick}
            disabled={!isDataChanged}
          >
            {t('forms:editCourier:reset')}
          </button>
        </div>
      </div>
    );
  },
);

import { observer } from 'mobx-react-lite';
import React from 'react';
import { mainStore } from 'stores/MainStore';
import FrameDetail from './FrameDetail/index';
import FrameTeamless from './FrameTeamless';
import FrameUnassigned from './FrameUnasigned/FrameUnassigned';
import htmlClasses from 'html-classes';
import FrameCourier from './FrameCourier';

export default observer(() => {
  return (
    <div
      className={htmlClasses('frame-dynamic _in-right', `_${mainStore.activeDynamicFrame}`, {
        '_hide': !mainStore.activeDynamicFrame,
      })}
    >
      <FrameUnassigned />
      <FrameTeamless />
      {mainStore.activeDynamicFrame === 'detail' && mainStore.selectedOrder.id &&
       <FrameDetail />}
      {mainStore.activeDynamicFrame === 'courier' && mainStore.selectedCourier.id &&
       <FrameCourier />}
    </div>
  );
});

import MobileEmptyBlock from 'components/MobileEmptyBlock';
import ResultList from 'components/Search/PopupAdvanced/ResultList';
import htmlClasses from 'html-classes';
import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, useState, useEffect } from 'react';
import { HOUR_IN_DAY, MIN_IN_HOUR, MOBILE_WIDTH, SEC_IN_MIN } from 'config';
import { useMediaQuery } from 'react-responsive';
import { dateTimeStore, DayTime } from 'stores/DateTimeStore';
import TimePicker from '../../../components/TimePicker';
import ResultTable from './ResultTable';
import { getOrderStatusesSelectItems } from 'common/order-status';
import { dataStore } from 'stores/DataStore';
import Select, { DEFAULT_SELECT_ITEM, SelectItem } from '../../../components/Select';
import InputText from '../../../components/InputText';
import { searchStore } from 'components/Search/store';
import { mainStore } from 'stores/MainStore';
import Popup from '../../../components/Popup';
import DatePicker, { DateRange, DEFAULT_DATEPICKER_VALUE } from '../../DatePicker';
import { useTranslation } from 'react-i18next';

export default observer(() => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_WIDTH}px)` });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [selectedWarehouseItem, setSelectedWarehouseItem] =
    useState<SelectItem>(DEFAULT_SELECT_ITEM);
  const [selectedCourierItem, setSelectedCourierItem] = useState<SelectItem>(DEFAULT_SELECT_ITEM);
  const [selectedOrderStatus, setSelectedOrderStatus] = useState<SelectItem>(DEFAULT_SELECT_ITEM);

  const [creationDateRange, setCreationDateRange] = useState<DateRange>(DEFAULT_DATEPICKER_VALUE);
  const [creationStartTime, setCreationStartTime] = useState<DayTime | null>(null);
  const [creationEndTime, setCreationEndTime] = useState<DayTime | null>(null);

  const [deliveryDateRange, setDeliveryDateRange] = useState<DateRange>(DEFAULT_DATEPICKER_VALUE);
  const [deliveryStartTime, setDeliveryStartTime] = useState<DayTime | null>(null);
  const [deliveryEndTime, setDeliveryEndTime] = useState<DayTime | null>(null);

  const handleCreationRangeChange = (range: DateRange) => setCreationDateRange(range);
  const handleDeliveryRangeChange = (range: DateRange) => setDeliveryDateRange(range);

  const handleCreationStartTimeChange = (time: DayTime | null) => setCreationStartTime(time);
  const handleCreationEndTimeChange = (time: DayTime | null) => setCreationEndTime(time);

  const handleDeliveryStartTimeChange = (time: DayTime | null) => setDeliveryStartTime(time);
  const handleDeliveryEndTimeChange = (time: DayTime | null) => setDeliveryEndTime(time);

  const handlePopupDismiss = () => {
    mainStore.setIsShowPopupAdvancedSearch(false);
    resetFields();
  };

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value.trim().toUpperCase();
    searchStore.setAdvancedSearchValue(value);
  };

  const handleWarehouseChange = (item: SelectItem) => {
    setSelectedWarehouseItem(item);
  };

  const handleCourierChange = (item: SelectItem) => {
    setSelectedCourierItem(item);
  };

  const handleOrderStatusChange = (item: SelectItem) => {
    setSelectedOrderStatus(item);
  };

  const resetFields = () => {
    setSelectedWarehouseItem(DEFAULT_SELECT_ITEM);
    setSelectedCourierItem(DEFAULT_SELECT_ITEM);
    setSelectedOrderStatus(DEFAULT_SELECT_ITEM);
    setCreationDateRange(DEFAULT_DATEPICKER_VALUE);
    setDeliveryDateRange(DEFAULT_DATEPICKER_VALUE);
    setCreationStartTime(null);
    setCreationEndTime(null);
    setDeliveryStartTime(null);
    setDeliveryEndTime(null);
  };

  const handleSearch = async () => {
    if (creationStartTime?.hours && creationStartTime?.minutes && creationStartTime?.seconds) {
      creationDateRange.start?.setUTCHours(
        creationStartTime.hours,
        creationStartTime.minutes,
        creationStartTime.seconds,
      );
    } else {
      creationDateRange.start?.setUTCHours(0, 0, 0, 1);
    }

    if (creationEndTime?.hours && creationEndTime?.minutes && creationEndTime?.seconds) {
      creationDateRange.end?.setUTCHours(
        creationEndTime.hours,
        creationEndTime.minutes,
        creationEndTime.seconds,
      );
    } else {
      creationDateRange.end?.setUTCHours(HOUR_IN_DAY - 1, MIN_IN_HOUR - 1, SEC_IN_MIN - 1, 999);
    }

    if (deliveryStartTime?.hours && deliveryStartTime?.minutes && deliveryStartTime?.seconds) {
      deliveryDateRange.start?.setUTCHours(
        deliveryStartTime.hours,
        deliveryStartTime.minutes,
        deliveryStartTime.seconds,
      );
    } else {
      deliveryDateRange.start?.setUTCHours(0, 0, 0, 1);
    }

    if (deliveryEndTime?.hours && deliveryEndTime?.minutes && deliveryEndTime?.seconds) {
      deliveryDateRange.end?.setUTCHours(
        deliveryEndTime.hours,
        deliveryEndTime.minutes,
        deliveryEndTime.seconds,
      );
    } else {
      deliveryDateRange.end?.setUTCHours(HOUR_IN_DAY - 1, MIN_IN_HOUR - 1, SEC_IN_MIN - 1, 999);
    }

    const createdAt =
      creationDateRange.start && creationDateRange.end
        ? [
            dateTimeStore.getTimeSeconds(creationDateRange.start),
            dateTimeStore.getTimeSeconds(creationDateRange.end),
          ]
        : undefined;

    const completedAt =
      deliveryDateRange.start && deliveryDateRange.end
        ? [
            dateTimeStore.getTimeSeconds(deliveryDateRange.start),
            dateTimeStore.getTimeSeconds(deliveryDateRange.end),
          ]
        : undefined;

    setIsLoading(true);
    searchStore
      .advancedSearchOrders({
        externalId: searchStore.advancedSearchValue.length
          ? searchStore.advancedSearchValue
          : undefined,
        warehouse: selectedWarehouseItem.value.length ? [selectedWarehouseItem.value] : undefined,
        status: selectedOrderStatus.value.length ? [selectedOrderStatus.value as any] : undefined,
        courier: selectedCourierItem.value.length ? [selectedCourierItem.value] : undefined,
        createdAt,
        completedAt,
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (!mainStore.isShowPopupAdvancedSearch) return;
    dataStore.requestCourierList().catch(() => void 0);
    //eslint-disable-next-line
  }, [mainStore.isShowPopupAdvancedSearch]);

  return (
    <Popup
      className="popup-advanced-search"
      isShow={mainStore.isShowPopupAdvancedSearch}
      onBackdropDismiss={handlePopupDismiss}
      noCloseIcon
      mobileFullScreen
      mobileHeaderProps={{
        title: 'Advanced search',
        backButtonHidden: true,
        closeButtonVisible: true,
      }}
    >
      <div className="popup__header header">
        <div className="header__title">{t('phrases:advancedSearch')}</div>
        <div className="header__close icon icon-close" onClick={handlePopupDismiss} />
      </div>
      <div className="popup__contents contents">
        <div className="popup__content content _light">
          <div className="input-blocks">
            <div className="input-blocks__input-block input-block">
              <InputText
                className="input-block__item _search"
                placeholder="Order ID..."
                value={searchStore.advancedSearchValue}
                onChange={handleChangeSearch}
                iconButtonName="magnifier"
                onIconButtonClick={handleSearch}
                onSubmit={handleSearch}
              />
            </div>
            <div className="input-blocks__input-block input-block">
              <DatePicker
                label={t('inputs:creationDate')}
                className="input-block__item _creation-date"
                onChange={handleCreationRangeChange}
              />
              <TimePicker
                label={t('inputs:creationTime')}
                className="input-block__item _creation-time"
                range
                onStartTimeChange={handleCreationStartTimeChange}
                onEndTimeChange={handleCreationEndTimeChange}
              />
            </div>
            <div className="input-blocks__input-block input-block">
              <DatePicker
                label={t('inputs:deliveryDate')}
                className="input-block__item _delivery-date"
                onChange={handleDeliveryRangeChange}
              />
              <TimePicker
                label={t('inputs:deliveryTime')}
                className="input-block__item _delivery-time"
                range
                onStartTimeChange={handleDeliveryStartTimeChange}
                onEndTimeChange={handleDeliveryEndTimeChange}
              />
            </div>
            <div className="input-blocks__input-block input-block">
              <Select
                label={t('inputs:warehouseLabel')}
                initialValue={t('inputs:selectWarehouse')}
                className="input-block__item _warehouse"
                data={dataStore.warehouseList}
                value={selectedWarehouseItem}
                onChange={handleWarehouseChange}
                searchPlaceholder={t('inputs:warehousePlaceholder')}
                withSearch
                noSearchIcon
              />
              <Select
                label={t('inputs:courierLabel')}
                initialValue={t('inputs:selectCourier')}
                className="input-block__item _courier"
                data={dataStore.courierList}
                value={selectedCourierItem}
                onChange={handleCourierChange}
                searchPlaceholder={t('inputs:courierPlaceholder')}
                withSearch
                noSearchIcon
              />
              <Select
                label={t('inputs:statusLabel')}
                initialValue={t('inputs:selectStatus')}
                className="input-block__item _status"
                data={getOrderStatusesSelectItems()}
                value={selectedOrderStatus}
                onChange={handleOrderStatusChange}
                searchPlaceholder={t('inputs:statusPlaceholder')}
                withSearch
                noSearchIcon
              />
            </div>
          </div>
        </div>
        {!isMobile && (
          <div className="popup__content content _light search-button-wrapper">
            <button className="button _block _flat" onClick={handleSearch} disabled={isLoading}>
              {isLoading ? <span className="spinner" /> : <span>{t('inputs:search2')}</span>}
            </button>
          </div>
        )}
        <div
          className={htmlClasses('popup__content content _dark result-table-wrapper', {
            _hidden: !searchStore.advancedSearchResponse?.length || isLoading,
          })}
        >
          {isMobile ? <ResultList /> : <ResultTable />}
        </div>
        {isMobile && <MobileEmptyBlock />}
      </div>
    </Popup>
  );
});

import { CompanyConfig, CompanyName } from './interface';

export const defaultCompanyId = '1eb53a13-5f9e-4deb-92d7-090a4b53fd21';

export const COMPANY: Record<string, CompanyConfig> = {
  'bb111efb-a9d8-465d-b9ca-8d255f1d8e9d': {
    id: 'bb111efb-a9d8-465d-b9ca-8d255f1d8e9d',
    lang: 'en',
    langs: ['en'],
    name: CompanyName.Jiffy,
  },
  '0e715914-1b33-4387-92db-fe3103f865bb': {
    id: '0e715914-1b33-4387-92db-fe3103f865bb',
    lang: 'en',
    langs: ['en'],
    name: CompanyName.Baqal,
  },
  '49589d9f-753a-421f-bd36-cea6202c4ba7': {
    id: '49589d9f-753a-421f-bd36-cea6202c4ba7',
    lang: 'en',
    langs: ['en'],
    name: CompanyName.Swifft,
  },
  'd05f30ba-ceb5-4633-a3b6-aa0f9463954f': {
    id: 'd05f30ba-ceb5-4633-a3b6-aa0f9463954f',
    lang: 'en',
    langs: ['en'],
    name: CompanyName.Localee,
  },
  [defaultCompanyId]: {
    id: defaultCompanyId,
    lang: 'en',
    langs: ['en'],
    name: CompanyName.CityDrinks,
  },
  '844a9e71-ed42-4ea9-a2c2-5a58b1db0a13': {
    id: '1eb53a13-5f9e-4deb-92d7-090a4b53fd21',
    lang: 'ar',
    langs: ['en', 'ar'],
    name: CompanyName.CircleK,
  },
};
